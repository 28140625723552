import React, {useState, useRef} from 'react';
import axios from 'axios';
import DropSelect from '../../../common/DropSelect/DropSelect';
import DropSelectMulti from '../../../common/DropSelectMulti/DropSelectMulti';
import IMG_Print from '../../../../images/icons/print_white.png';
import IMG_Save from '../../../../images/icons/save_white.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import ReactToPrint
from "react-to-print";
import {CSVLink} from "react-csv";
import ResidentFilter from '../../../SelectFilters/ResidentFilter';
import './CareGraphs.css';
import {Pie} from 'react-chartjs-2';
import { Chart } from "react-google-charts";
import {getValFromJSON} from '../../../../tools/tools';
import Report, {ReportsContainerFour} from '../../../reports/report';

const CareGraphs = () => {

  const [zoneSelections, setZoneSelections] = useState({});

  const [filterOptions, setFilterOptions] = useState({
    firstRun: true,
    data: Array.isArray(localStorage.getItem("reportData")) ? localStorage.getItem("reportData") : [],
    homeOptions: JSON.parse(localStorage.getItem('sites')),
    homeSelection: JSON.parse(localStorage.getItem('sites'))[0].label,
    zoneOptions: JSON.parse(localStorage.getItem('zones')),
    zoneSelections: [],
    callOptions: [{label:'Arquella Demo', value:'Arquella Demo'}],
    callSelections: [],
    carerOptions: JSON.parse(localStorage.getItem('carers')),
    carerSelections: JSON.parse(localStorage.getItem('carers')),
    callPointOptions:JSON.parse(localStorage.getItem('callpoints')),
    callPointSelections:JSON.parse(localStorage.getItem('callpoints')),
    residentOptions:JSON.parse(localStorage.getItem('careResidents')),
    residentSelection:  getValFromJSON(localStorage.getItem('careResidents')),

    // window.carers ? window.carers[0].label : ""
    start: moment().subtract(29, 'days'),
    end: moment(),  
  });
 


  const handleEvent = (event, picker)=> {}


  const handleCallback = (start, end) => {
    
    setFilterOptions({ 
    
      firstRun: true,
      data: Array.isArray(localStorage.getItem("reportData")) ? localStorage.getItem("reportData") : [],
      homeOptions: filterOptions.homeOptions,
      homeSelection: filterOptions.homeSelection,
      zoneOptions: filterOptions.zoneOptions,
      zoneSelections: filterOptions.zoneSelections,
      callOptions: filterOptions.callOptions,
      callSelections: filterOptions.callSelections,
      carerOptions: filterOptions.carerOptions,
      carerSelections: filterOptions.carerSelections,
      callPointOptions:filterOptions.callPointOptions,
      callPointSelections:filterOptions.callPointSelections,
      residentOptions: filterOptions.residentOptions,
      residentSelection: filterOptions.residentSelection,
      start: start,
      end: end,  
    });
  };

  const label = filterOptions.start.format('DD/MM/YYYY') + ' - ' + filterOptions.end.format('DD/MM/YYYY');

  const getDBData = () => {

    const homes = filterOptions.homeSelection;
    const zones = filterOptions.zoneSelections.map((v) => v.value);
    const calltypes = filterOptions.callSelections.map((v) => v.value);
    const carers = filterOptions.carerSelections ? filterOptions.carerSelections.map((v) => v.value) : [""];
    const callPoints = filterOptions.callPointSelections.map((v) => v.value);

    let sData = {
      "homes": homes,
      "zones" : zones,
      "calltypes" : calltypes,
      "carers" : carers,
      "callpoints" : callPoints,
      "startDate" : moment(filterOptions.start).format('YYYY/MM/DD'),
      "endDate" : moment(filterOptions.end).format('YYYY/MM/DD'),
      "resident" : filterOptions.residentSelection,
    };
  
    
    axios.post("/reports/careread.php", sData)
    .then(res => {
      console.log("careRead");
      console.log(res);
    localStorage.setItem("reportData", res.data.report);

    setFilterOptions({ 
    
      data: res.data.report,
      homeOptions: filterOptions.homeOptions,
      homeSelection: filterOptions.homeSelection,
      zoneOptions: filterOptions.zoneOptions,
      zoneSelections: filterOptions.zoneSelections,
      callOptions: filterOptions.callOptions,
      callSelections: filterOptions.callSelections,
      carerOptions: filterOptions.carerOptions,
      carerSelections: filterOptions.carerSelections, 
      callPointOptions:filterOptions.callPointOptions,
      callPointSelections:filterOptions.callPointSelections,
      residentOptions: filterOptions.residentOptions,
      residentSelection: filterOptions.residentSelection,

      start: filterOptions.start,
      end: filterOptions.end,      
    });
    });
  
}


const changeSite = (site) => {
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: site,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,
    residentOptions: filterOptions.residentOptions,
    residentSelection: filterOptions.residentSelection,

    start: filterOptions.start,
    end: filterOptions.end,

  });
}

const changeZone = (zones) => {
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: zones,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,
    residentOptions: filterOptions.residentOptions,
    residentSelection: filterOptions.residentSelection,

    start: filterOptions.start,
    end: filterOptions.end,
  });

}

const ChangeCarer = (carer) => {
  
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,
    residentOptions: filterOptions.residentOptions,
    residentSelection: filterOptions.residentSelection,

    carerSelections: carer,
    start: filterOptions.start,
    end: filterOptions.end,

  });

}


const ChangeCallPoint = (points) => {
  
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:points,
    carerSelections: filterOptions.carerSelections,
    residentOptions: filterOptions.residentOptions,
    residentSelection: filterOptions.residentSelection,

    start: filterOptions.start,
    end: filterOptions.end,

  });

}


const ZoneSelection = (props) => {
  const options = filterOptions.zoneOptions;
  const selections = filterOptions.zoneSelections;
  if (filterOptions.zoneSelections.length == 0) {changeZone(options);}
  return <DropSelectMulti options={options} selections={selections} filterCall={changeZone} />     
}

const ChangeCallTypes = (calltypes) =>{
  
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: calltypes,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    
    callPointOptions: filterOptions.callPointOptions,
    callPointSelections: filterOptions.callPointSelections,

    residentOptions: filterOptions.residentOptions,
    residentSelection: filterOptions.residentSelection,
   
    start: filterOptions.start,
    end: filterOptions.end,

  });
}

const CallTypeSelection = (props) => {   
  const options = window.repCallTypes;
  const selections = filterOptions.callSelections;
  if (filterOptions.callSelections.length == 0) {ChangeCallTypes(options);}
  return <DropSelectMulti options={options} selections={selections} filterCall={ChangeCallTypes} /> 
}

const CarerSelection = (props) => {
  return <DropSelectMulti options={filterOptions.carerOptions} selections={filterOptions.carerSelections} filterCall={ChangeCarer} />
}



const HomeSelection = (props) =>{   
  return <DropSelect options={filterOptions.homeOptions} selectedVal={filterOptions.homeSelection} changeHandler={changeSite} />
}

  const componentRef = useRef();

  const strDate = Date().toString();
  const ids = [1];

  function residentCallBack(val){

    setFilterOptions({
      firstRun: false,
      data: filterOptions.data,
      homeOptions: filterOptions.homeOptions,
      homeSelection: filterOptions.homeSelection,
      zoneOptions: filterOptions.zoneOptions,
      zoneSelections: filterOptions.zoneSelections,
      callOptions: filterOptions.callOptions,
      callSelections: filterOptions.callSelections,
      carerOptions: filterOptions.carerOptions,
      carerSelections: filterOptions.carerSelections,
      callPointOptions: filterOptions.callPointOptions,
      callPointSelections: filterOptions.callPointSelections,
      residentOptions: filterOptions.residentOptions,
      residentSelection: val,
      start: filterOptions.start,
      end: filterOptions.end,  
    });

  }


  return (
    <div id="AIDA_NAV_MAIN">
      <div id="AIDA_NAV" className="AIDA_NAV">
      
        <p>Home</p>
        <HomeSelection />
        
        <p>Zone</p>
        <ZoneSelection />
        <br />
        <p>Resident</p>
        <ResidentFilter callback={residentCallBack} />
        <br />
  

        <br />
        <DateRangePicker
        onEvent={handleEvent} 
        onCallback={handleCallback}
        initialSettings={{
          timePicker: true,
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Year To Date': [
              moment().subtract(1, 'year').toDate(),
              moment().toDate(),
            ],
          },
        }}
>

        <div>
          <p>Dates</p>
          <div className="inputTimeDisplay">{label}</div>
        </div>

        </DateRangePicker>


        <div className="navbuttons">
        <br />
        <button className="submit" onClick={(e)=>getDBData()}>Submit</button>
        
        <div className="save_print">

          <CSVLink data={filterOptions.data}><button className="save"><img alt="Card image" className="save" src={IMG_Save} /></button></CSVLink>

                            <ReactToPrint
                                trigger={() => <button onClick={(e) => window.print()} className="print"><img alt="Card image" className="print" src={IMG_Print} /></button>}
                                content={() => componentRef.current}
                            />    
                            

                        </div>
        </div>

      </div>  
    
        <div className="AIDAMAINTCONTENT">

          <ReportsContainerFour>

            <ActIncRes />    
            <TimeGroup />
            <IncLoc />
            <IncTime />

          </ReportsContainerFour>

        </div>
    </div>
  );
}



class IncTime extends React.Component {
  render(){
    return(
        <Report 
            data={[
                    ['Time Period', 'Actions/Incidents Raised'],
                    ['Morning 4am-12pm', 23],
                    ['Afternoon 12pm-8pm', 10],
                    ['Evening 8pm-4am', 18],
                  ]}
            type=""
            name=""
            title="Incidents VS Time"
            noDataCaption=""
            options={{}}
            xTitle="Time Period"
            yTitle="No of Actions/Incidents this week."
        />
    );
  }
}


class TimeGroup extends React.Component {
  render(){
    return(
      <Report
        type="pie"
        noDataCaption="No data"
        name=""
        title="Time group"
        data={this.data}
        options={this.pieOptions}
      />
    );
  }
}



class IncLoc extends React.Component {
  render(){
    return(
      <Report 
          data={[
                  ['Residents', 'Actions/Incidents Raised'],
                  ['Bedrooms', 23],
                  ['Corridors', 11],
                  ['Dinning Room', 25],
                  ['Living Room', 32],
                ]}
          type=""
          name=""
          title="Incidents VS Locations"
          noDataCaption=""
          options={{}}
          xTitle="Location"
          yTitle="No of Actions/Incidents this week."
      />
    );
  }
}

class ActIncRes extends React.Component {

  render(){
    return(
      <Report 
          data={[
                  ['Residents', 'Actions/Incidents Raised'],
                  ['Joe Blogs', 23],
                  ['David Chapman', 15],
                  ['Constance Chapman', 12],
                  ['Thomas Hand', 2],
                  ['Peggy Sentence', 7],
                  ['Cephas Chapman', 18],
                ]}
          type=""
          name=""
          title="Actions/Incidents VS Residents"
          noDataCaption=""
          options={{}}
          xTitle="Residents"
          yTitle="No of Actions/Incidents this week."
      />
    );
  }
}



const chartColors = [
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775"
];


function BarChart(props) {
  return(
      
      <div>    
      <div style={{ display: 'flex', maxWidth: 900 }}>
<Chart
  width={500}
  height={300}
  chartType="ColumnChart"
  loader={<div>Loading Chart</div>}
  data={props.data}
  options={{
    title: props.title,
    colors: ['#4cc1bd', '#93C967'],
    chartArea: { width: '30%' },
    backgroundColor: '#dcf5f3',
    chartArea: { 
      width: '60%', 
      height: '60%'
    },
    hAxis: {
      title: props.xTitle,
      minValue: 0,
      titleTextStyle: { color: 'darkgrey' },
    },
    legend: 'none',
    vAxis: {
      title: props.yTitle,
      titleTextStyle: { color: 'darkgrey' },
    },
  }}
/>
</div>
</div>
  );
}


export default CareGraphs;

