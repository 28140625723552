import React, { useState, useEffect } from "react";
import './DropSelectMulti.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { normalizeUnits } from "moment";


export default function DropSelectMulti (props) {
    
    const options = props.options ? props.options : [];
    const selections = props.selections ? props.selections : [];

    useEffect(() => {
      
      //setSelectedOptions(props.selections);
      
        if (options.length === (selections.length)) {

          let hasAll = false;

          selections.map(s=>{
            if (s.label == "All") hasAll = true;
          });

          if (hasAll==false) setSelectedOptions([{ label: "All", value: "*" }, ...selections]);
        
        }
        else {
          setSelectedOptions(props.selections);
        }
  
  
    }, []);

    let [selectedOptions, setSelectedOptions] = useState([]);
    
      function getDropdownButtonLabel({ placeholderButtonLabel, value }) {

        if (value && value.some((o) => o.value === "*")) {
          return `${placeholderButtonLabel} All`;
        } else {
          return `${placeholderButtonLabel} ${value.length} selected`;
        }

      }


      function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
          this.setState(this.options);
        } else if (
          event.action === "deselect-option" &&
          event.option.value === "*"
        ) {
          this.setState([]);
        } else if (event.action === "deselect-option") {
          this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
          this.setState(this.options);
        } else {
          this.setState(value);
        }
      }
  
      return(
          <div>
              <ReactMultiSelectCheckboxes 
                options={[{ label: "All", value: "*" }, ...options]}
                placeholderButtonLabel=""
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedOptions}
                onChange={onChange}
                setState={setSelectedOptions}
                styles={customStyles}
                onMenuClose={e=>{
                  console.log("TYING TO CLOSE");
                  if (props.filterCall) {
                    let t = selectedOptions.filter((o) => o.value !== "*");
                    props.filterCall(t);
                  }
                }}
              />
          </div>
      );

}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: '1px dotted pink',
    color: state.isSelected ? '#4cc1bd' : '#4cc1bd',
    backgroundColor: state.isSelected ? 'white' : 'white',
    fontSize: 12,
    fontWeight: normalizeUnits,
    //fontFamily: 'Muli',
    padding: 5,
    //onHover: 
  }),
  Control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 100,
  }),
  Group: () => ({
    // none of react-select's styles are passed to <Control />
    width:100,
    
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

