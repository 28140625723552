import React, {Component} from "react";

const CallReportContainer = (props) => {
    
    let style = {
        backgroundColor: props.color,
        margin: "10px",
        borderRadius: "10px",
        padding: "0px",
        backgroundColor: props.color,
        color: props.textColor,
        border: "1px solid",
        borderColor: props.color,
    }

    return (
    <div style={style}>
      <div className="reports_callreport_container_header rowFlexNoWrap">
        {
            props.img
            ? <img alt="Card image" heigh="30" width="30" className="dashboard_icons" src={props.img} />
            : null
        }
        <div>{props.title}</div>
      </div>
      <div className="reports_callreport_container_main">
        {props.children}
      </div>
    </div>
    );
  }
  
  export default CallReportContainer;