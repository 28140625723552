import React, {useContext, Component, useState, useRef} from 'react';
import DropSelectMulti from '../common/DropSelectMulti/DropSelectMulti';


const CallPointFilter = (props) => {

    const [filterOptions, setFilterOptions] = useState({
        callPointOptions: props.options,
        callPointSelections: props.selectedVal,
    });

    const hasFilterOptions = () =>{
      try {
        if (filterOptions.callPointSelections.length > 0) {return true;}
      }
      catch(e){}
      return false;
    }

    const changeCallPoint = (callPoints) => {
      setFilterOptions({
        callPointOptions: filterOptions.callPointOptions,
        callPointSelections: callPoints,
      });
      props.callback(callPoints);
    }

    const options = filterOptions.callPointOptions;
    const selections = filterOptions.callPointSelections;

    if (hasFilterOptions == false) {changeCallPoint(options);}
    
    return <DropSelectMulti options={options} selections={selections} filterCall={changeCallPoint} />     

}



export default CallPointFilter;
