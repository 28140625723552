import React, {useState, useRef, useEffect, componentRef} from 'react';
import firebase from 'firebase'
import { map } from 'jquery';

const CareCallController = (props) => {

    const [configuredCalls, setConfiguredCalls] = useState([]);
    const [firstTimeRun, setFirstTimeRun] = useState(true);

    const listCareCalls=(ref, dateVal)=> {

        let rtnCalls = [];
        setFirstTimeRun(false);

        firebase.database().ref("testCareCalls").on("value", snapshot => {
            console.log("Connected");
            snapshot.forEach(snap => {
                console.log("Snapsots");
                rtnCalls.push(snap.val());                    
            });
        
            setConfiguredCalls(rtnCalls);
        });
        
    }
    
    const createCareCall=(ref, dateVal)=> {

        let rtnCalls = [];
        setFirstTimeRun(false);

        let dbref = firebase.database().ref("testCareCalls/101");
        
        dbref.push({
            Duration: "1",
            Repeat: "4",
            time: "2021-06-22T14:53:06.153958"
        });
        
    }

    useEffect(()=>{
        if (firstTimeRun) {listCareCalls()};
    
    }, [firstTimeRun]);

    let cls = configuredCalls;
    
    return (
        
        <div>
            Scheduler {cls.length}
            {
            cls.map((c,i)=>{
                
                console.log(c);
                
                return(<div key={i} style={{display:"flex", flexDirection:"column", width:"40%", backgroundColor:"lightblue", margin:"5px", padding:"5px"}}>
                        Duration: <input value={c.Duration} />
                        Repeat: <input value={c.Repeat} />
                        Time: <input value={c.time} />
                    </div>
                );
            })
            }


            <button onClick={()=>{
                createCareCall();
            }}>Add New</button>


        </div>

    );

}



export default CareCallController;
