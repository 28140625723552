import React, {useContext} from 'react';
//import './TeamMemberForm.css';
import axios from 'axios';
import { isThisISOWeek } from 'date-fns/esm';
import PasswordController from '../PasswordController/PasswordController';
import InfoButton from '../../../components/InfoButton/InfoButton';


export default class NewUserBasicController extends React.Component {

    state = {
        id: this.props.user_details ? this.props.user_details.User_ID : -15,
        firstname: this.props.user_details ? this.props.user_details.User_First_Name : "",
        surname: this.props.user_details ? this.props.user_details.User_Surname : "",
        email: this.props.user_details ? this.props.user_details.User_Email : "",
        password: this.props.user_details ? "Has password" : "",
        firstnameError: "",
        surnameError: "",
        emailError: "",
        passwordError: "",
        userLevel: this.props.user_details ? this.props.user_details.User_Level : "",
        userLevelError: "",
        staffID: this.props.user_details ? this.props.user_details.User_Internal : "",
        staffIdError: "",
        newUser: this.props.newUser
    };


    handleChange = event => {
 
        const isCheckBox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]:isCheckBox
                ? event.target.checked
                : event.target.value
        });
    
    }




    validate = () => {
        
        ////////email validation/////////////
        let emailError = "";
        let userLevelError = "";
        let firstnameError = "";
        let surnameError  = "";

        (this.state.firstname=="") ? firstnameError = "Invalid First Name" : firstnameError = "";
        if (this.state.firstnameError != firstnameError) { this.setState({ firstnameError }); }

        (this.state.surname=="") ? surnameError = "Invalid Surname" : surnameError = "";
        if (this.state.surnameError != surnameError) { this.setState({ surnameError }); }

        (!this.state.email.includes("@")) ? emailError = "Invalid email" : emailError = "";
        if (this.state.emailError != emailError) { this.setState({ emailError }); }

        (this.state.userLevel=="") ? userLevelError = "Please select a User Level for the team member." : userLevelError = "";
        if (this.state.userLevelError != userLevelError) { this.setState({ userLevelError }); }

        if ((emailError) || (userLevelError)){   return false;   }
        /////////////////////////////////////
        
        return true;

    }

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.newUserSubmit();
        }
    }


    newUserSubmit() {
  
        let sData = {
          "first_name": this.state.firstname,
          "surname": this.state.surname,
          "site_name": "1",
          "user_level": this.state.userLevel,
          "uemail" : this.state.email,
          "userID": this.state.id,
          "pass": this.state.password,
          "staffID" : this.state.staffID
        };
      
        axios.post("/team/newuser", sData)
        .then(res => {
            console.log(res);
            
            if (res.data =="") {
                this.setState({newUser:false});
                this.props.cancelHandler();
            }
            else {
                this.setState({emailError:res.data.status});
            }

        }).catch(err =>{
            console.log("ERRRRR:"+err);
            console.log(err);
        });

      }

    GetPasswordInput=()=> {
        return (
            <div>
                <input 
                    name="password"
                    //placeholder="email"
                    value={this.state.password}
                    onChange={this.handleChange}
                    maxlength="30"
                    size="30"
                    />
                {
                this.state.passwordError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.passwordError}</div>)
                : null
                }
            </div>
        );
    }


    ResetPassword=()=> {
        if (this.state.password == "Has password") {
            
            return (
            <button
                style={{
                    cursor:"pointer",

                }}
                onClick={()=>{

                    let sData = {
                        "uemail" : this.state.email,
                        "userID": this.state.id,
                      };
                    
                      axios.post("/team/resetpassword", sData)
                      .then(res => {
                          console.log(res);
                          let tmp = "An email has been sent to this email address with instructions to reset the password.";
                          if ((res.data.status!="") && (res.data.status!="error is not defined")) {
                            
                            tmp = res.data.status;
                          }  
                          this.setState({password: tmp});
              
                      }).catch(err =>{
                          console.log("ERRRRR:"+err);
                          console.log(err);
                      });



                }}
            >Reset Password</button>
            );
        }
        else {
            return (
                <div>{this.state.password}</div>
            );
        }
        
    }


    render() {

        let levelOptions = JSON.parse(localStorage.getItem("userlevels"));

        return(
            <div style={{

                marginTop: "27px",
                marginLeft: "37px",
                marginRight: "20px"


            }}>
                <form onSubmit={this.handleSubmit}>

                    <table style={{borderSpacing:"8px", borderCollapse:"separate" }}>
                        <tbody>
                            <tr>
                                <td>First Name:</td>
                                <td>

                                    <input 
                                        name="firstname"
                                        value={this.state.firstname}
                                        onChange={this.handleChange}
                                        maxlength="50"
                                        size="20"
                                        
                                    />

                                    {
                                    this.state.firstnameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.firstnameError}</div>)
                                    : null
                                    }
                                    
                                </td>
                            </tr>
                            <tr>
                                <td>Surname:</td>
                                <td>

                                    <input 
                                        name="surname"
                                        value={this.state.surname}
                                        onChange={this.handleChange}
                                        maxlength="50"
                                        size="20"
                                    />
                                    {
                                    this.state.surnameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.surnameError}</div>)
                                    : null
                                    }

                                </td>
                            </tr>
             
                                <tr>
                                    <td>Email</td>
                                    <td>

                                        <input 
                                            name="email"
                                            //placeholder="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            maxlength="90"
                                            size="30"
                                        />
                                        {
                                        this.state.emailError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.emailError}</div>)
                                        : null
                                        }
                                                    
                            
                                    </td>
                                </tr>

                                <tr>
                                    <td>Password</td>
                                    <td>
                                    {
                                    (this.state.password == "Has password") 
                                    ? <this.ResetPassword /> 
                                    : <this.GetPasswordInput />
                                    }
                                    </td>
                                </tr>

  

                                <tr>
                                    <td>User level</td>
                                    <td>

                                        <select name="userLevel" onChange={this.handleChange} >
                                        <option value=""></option>
                                        {
                                        levelOptions.map(o=>{
                                            return <option 
                                            selected={(this.state.userLevel == o.Level) ? true : false} 
                                            value={o.Level}>{o.Level_Name}</option> 
                                        })
                                        }
                                        
                                        </select>                                                    
                                        {
                                        this.state.userLevelError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.userLevelError}</div>)
                                        : null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>Staff ID </td>
                                    <td>

                                        <input 
                                            name="staffID"
                                            //placeholder="email"
                                            value={this.state.staffID}
                                            onChange={this.handleChange}
                                            maxlength="90"
                                            size="30"
                                        />
                                        {
                                        this.state.staffIDError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.staffIDError}</div>)
                                        : null
                                        }
                                         &nbsp;&nbsp;   <InfoButton description="This will appear as your ID on all reporting." />
                                    </td>
                                </tr>


                            </tbody>
                        </table>


                        {//(this.state.newUser==true) ? this.props.children : null}
                            this.props.children
                        }


                     <div className="modal_buttons">
                            
                            <button type="button"
                            onClick={()=>{this.props.cancelHandler()}}
                            >Cancel</button>

                            <button
                                type="submit"
                            >{(this.state.newUser==true) ? "Submit" : "Update"}</button>
                    </div>

                </form>




            </div>

        );

    }

}
