import React, {useState, useRef, useEffect, componentRef} from 'react';
import IMG_Print from '../../images/icons/print_white.png';
import DropSelect from '../../components/common/DropSelect/DropSelect';
import DropSelectMulti from '../../components/common/DropSelectMulti/DropSelectMulti';
import Moment from 'moment';
import './Dashboard.css'; 
import ReactToPrint, {ComponentToPrint, useReactToPrint } from "react-to-print";
import '../../DemoData/Dashboard.json';
import ShowDash from './ShowDash/showdash';
import setDashData from './DashData';
import getConfigData from './ConfigData';
import setCareCallsData from './CareData';
import axios from 'axios';

const getZoneJSON = () => {
    let rtn = [];
    try {
        rtn =JSON.parse(localStorage.getItem('zones'));
    } catch (e) {console.log(localStorage.getItem('zones'));}
    return rtn;
}

const NavPane = (props) => {
    return (
        <div id="AIDA_NAV" className="AIDA_NAV">
            {props.children}
        </div>
    );
}

function getSiteValFromName(name) {
    let rtn = "";
    let s = JSON.parse(localStorage.getItem('sites'));
    s.map(s =>{
        if (s.label == name){rtn = s.value}
    });
    return rtn;
}

const hideZoneDropOption = () => {
    let v = false;
    if (localStorage.getItem("hideDashboardZones")) {v = true;}
    return v;  
}

const ArqDashboard = (props) => {

    const componentRef = useRef();
    let keyRef = new Date().toString();

    const [homeOptions, setHomeOptions] = useState(JSON.parse(localStorage.getItem('sites')));
    const [homeSelection, setHomeSelection] = useState((localStorage.getItem('sites')!=null) ? JSON.parse(localStorage.getItem('sites'))[0].label : "");
    const [dateSelection, setDateSelection] = useState("Today");
    const [zoneOptions, setZoneOptions] = useState(getZoneJSON);
    const [zoneSelections, setZoneSelections] = useState(getZoneJSON);
    
    const HomeSelection = (props) => {   
        return <DropSelect options={homeOptions} selectedVal={homeSelection} changeHandler={siteChanged} />
    }

    const ZoneSelection = (props) => {
        return <DropSelectMulti options={zoneOptions} selections={zoneSelections} filterCall={zoneChanged} />
    }
    
    function siteChanged(siteName) {
        setHomeSelection(siteName);
    }
    function dateChanged(dateVal) {
        setDateSelection(dateVal);
    }
    function zoneChanged(selections) {
        setZoneSelections(selections);
    }
    
    const getDateSelectionList = (props) => {   
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const options = [];
        
        let addDate = new Date();
        let dayBack1 = new Date();
        let dayBack2 = new Date();
        let dayBack3 = new Date();
        
        dayBack1.setDate(dayBack1.getDate() - 1);
        dayBack2.setDate(dayBack2.getDate() - 2);
        dayBack3.setDate(dayBack3.getDate() - 3);
        
        options.push({label:'Today', value: addDate});
        options.push({label:'Yesterday', value: dayBack1});
        options.push({label:days[dayBack2.getDay()], value: dayBack2});
        options.push({label:days[dayBack3.getDay()], value: dayBack3});
        
        return options;
    }


    const getDateRef = (ref) => {
        let options = getDateSelectionList();
        let n = "";
        options.map((o)=>{
            if (o.label == ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;

    }


    
    const DateSelection = (props) => {   

        const options = getDateSelectionList();
        window.dashDateOptions = options;
        localStorage.setItem("dashboardDateOptions", JSON.stringify(options));

        if ((dateSelection == "") && (options.length>0)) {dateChanged(options[0].label)}
        
        return <DropSelect options={options} selectedVal={dateSelection} changeHandler={dateChanged} />
    }

    
    const getUserMailData = (sName) => {

        let sData = {
            "user": localStorage.getItem("userEmail"),
            "userKey": localStorage.getItem("userEmail"),
            "sName": sName
        };

        axios.post("/system/usermail", sData)
        .then(res => {

            localStorage.setItem("uMails", JSON.stringify(res.data));
    
        });
    
    }



    let hideZoneDrop = hideZoneDropOption();

    useEffect(()=>{
        getConfigData(getSiteValFromName(homeSelection));
        
        getUserMailData(homeSelection);
        
        //setDashData(getSiteValFromName(homeSelection));
        
        setCareCallsData(getSiteValFromName(homeSelection));
    
    },[]);

    let ref = homeSelection + "/" + getDateRef(dateSelection);

    return (
                
        <div id="AIDA_NAV_MAIN">

        <NavPane>
        <div>

        <div><p>Home</p><HomeSelection /><br /></div>

        {hideZoneDrop ? null : <div><p>Zone</p><ZoneSelection /><br /></div>}

        <div><br /><p>Set Time Period</p><DateSelection /></div>

        </div>

        </NavPane>

        
        <ShowDash 
                //ref={componentRef}
                key={keyRef}
                filterSite={homeSelection}
                filterDate={dateSelection}
                filterZones={zoneSelections}
                data={[]}
            />
        
    </div>

    );

}



export default ArqDashboard;


              
