import React, {useContext, useState} from 'react';
import './wellbeing.css';
import WellBeing from './wellbeing';
//import Popup from '../../../../../common/Popup/Popup';



const WellBeingList = (props) => {
    const entries = props.list;
    
    let contextList = [];
    entries.map(entry => {
        
        if (contextList.indexOf(entry.Entry_Context)==-1) {
            contextList.push(entry.Entry_Context);
        }    

    });

    let data = contextList.map((e,i)=> <WellBeing 
                                            //value={e}
                                            key={i}
                                            value="1" 
                                            entry={entries} />);

    const [popup, setShowPopup] = useState({show:false});
    
    const cancelHandler=()=>{
        setShowPopup({show:false});
    }

    return (
        <div>
            <Modal show={popup.show} modalClosed={cancelHandler}>
                <div style={{marginTop: "10px", marginBottom: "10px", marginLeft: "10px", marginRight: "10px"}}>
                    <WellBeing />
                </div>
            </Modal>
            <div>
                <button 
                    className="wellbeing_button_new"
                    onClick={e=>{
                        setShowPopup({show:true});
                    }}
                ><b>+</b> Add New Well-being Check</button>
            </div>

            <div>{data}</div>    
            
        </div>
    );
}


const Modal = (props) => (
    <div>
      <Backdrop show={props.show} clicked={props.modalClosed}/>
    <div 
      className="Modal"
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
      }}
      >
      {props.children}
    </div>
    </div>
  );
  
  
  const Backdrop = (props) => (
  
    props.show ? <div className="Backdrop"
                    onClick={props.clicked}
                  ></div> : null
  
  );
  

export default WellBeingList;