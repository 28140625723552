import React, {Component} from "react";
import './layout.css';
const SummaryHeaderContainer = (props) => {
    return(
        <div className="report_resident_summary_container">
            <div className="report_resident_summary_header">{props.title}</div>
            <div className="report_resident_summary_main">
                {props.children}
            </div>
        </div>
    );
}

export default SummaryHeaderContainer;

/*

      <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_CALL} />
          <div className="summary_widget_title">Total Calls<br /><br /></div>
          <div className="summary_widget_value">{props.total}</div>
        </div>
        <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_CLOCK} />
          <div className="summary_widget_title">Longest Call<br /><br /></div>
          <div className="summary_widget_value">{props.longest}</div>
        </div>
        <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_WAYARROWS} />
          <div className="summary_widget_title">Average Time<br/>Between Calls</div>
          <div className="summary_widget_value">{props.between}</div>
        </div>
        <div className="summary_widget">
          <img alt="Card image" heigh="50" width="50" src={IMG_SUMMARY_USERPLUS} />
          <div className="summary_widget_title">Most Popular Initial<br />Call Type</div>
          <div className="summary_widget_value">{props.popular}</div>
        </div>


*/