import React, {useEffect, useState} from 'react';
import './InfoButton.css';

const InfoButton = (props) => {

    const dashboardRowContainerStyle = {
        display: "flex",
        flexDirection: "row",
        margin: "2em",
        width: "100%",
        justifyContent: "space-around"
    }
            //<div style={dashboardRowContainerStyle}>i</div>
                    
            return (
                
                    <div class="info_tooltip">i<span class="info_tooltiptext">{props.description}</span></div>
                
            );

  
}

export default InfoButton;


