import React from 'react';
import './SetKPIValue.css';
import axios from 'axios';

export default class SetKPIValue extends React.Component {

    state = {
        kpiValue: this.props.kpiValue ? this.props.kpiValue : 0,
        kpi: this.props.value ? this.props.value : "",
        context: this.props.context ? this.props.context : "default_kpi"
    }

    updateKPI (val) {
        this.props.callback(val);
    }

    handleChange = event => {
        let isValid = false;
        let val = event.target.value;

        val = val.replace(":", "");
        if (val == "") { 
            isValid=true;
        }
        else {
    
            if ((parseInt(val)!="NaN")) {   
                isValid=true;
            }

        }

        if (isValid) {
            const isCheckBox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]:isCheckBox
                    ? event.target.checked
                    : event.target.value
            },()=>{this.props.callBack && this.props.callBack(this.state.kpi);});
        }
    }

    fetchKPI () {
        
        const siteList = JSON.parse(localStorage.getItem("sites"));
        const siteVal = (siteList.length > 0) ? siteList[0].label : "";
        
        let sData = {
            "user": localStorage.getItem("userEmail"),
            "userkey": localStorage.getItem("userEmail"),
            "site_id" : siteVal,
            "kpi" : this.state.context
          };
          axios.post("/kpi/get", sData)
          .then(response => {
            console.log("KPI Response");
            let r = response.data;
            this.setState({kpi: r[0].kpi},()=>{this.props.callBack && this.props.callBack(this.state.kpi);});
          }).catch(response=>{
            
          });
    }

    componentDidMount() {
        this.fetchKPI();
        this.props.callBack && this.props.callBack(this.state.kpi);
    }

    render() {
        
        return(
            <div className="setkpi_container">
                <div className="setkpi_text">Target = &nbsp;</div>
                <input name="kpi" className="setkpi_input" value={this.state.kpi} onChange={this.handleChange}  />
                &nbsp;
                <div className="setkpi_text">{this.props.caption}</div>
            </div>
        );
    }
}