import React, {useEffect, useState, useRef} from "react";
import Calls_By_Resident from '../assist/charts/calls_by_resident';
//import Emergency_Response_Times from '../assist/charts/emergency_response_times';
import Calls_Per_Hour from '../assist/charts/calls_per_hour';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Scroller from '../common/scroller/scroller';
import HomeFilter from '../SelectFilters/HomeFilter';
import ZoneFilter from '../SelectFilters/ZoneFilter';
import ArquellaBarChartHoursKPI from '../report_arquella_bar_chart_hours_kpi/ArquellaBarChartHoursKPI';
import DashBoardCallContainer from '../DashBoardCallContainer/DashBoardCallContainer';
import ArquellaAverageResponseTimes from '../report_arquella_average_response_times/ArquellaAverageResponseTimes';
import axios from 'axios';
import ReportArquellaResidentCalls from '../report_arquella_resident_calls/report_arquella_resident_calls';
import BarChartAboveAverageResponse from '../report_arquella_bar_chart_above_avr_response/BarChartAboveAverageResponse';


const GeneralSummaryReport = (props) => {
  
  const handleEvent = (event, picker)=> {}

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [filterData, setFilterData] = useState({
    start: moment().set('hour', "0").set("minute", "0").set("second", "0").startOf("year"),
    end: moment().set('hour', "23").set("minute", "59").set("second", "59").endOf("month"),
    selectedChart: "Total Calls Per Hour",
    house: "Nyton House",
    zones: []
  });



  //get the data on change of the start value = this is a red herring at the momeent as its changed when other values change too needs updating
  useEffect(()=>{
    dbDataRefresh();
  }, [filterData.start]);

  useEffect(()=>{
    dataFiltering();
  }, [data]);

  const handleCallback = (start, end) => {
    setFilterData({
      start: start,
      end: end,
      selectedChart: filterData.selectedChart,
      house: filterData.house,
      zones: filterData.zones
    });
  }

  const label = filterData.start.format('DD/MM/YYYY') + ' - ' + filterData.end.format('DD/MM/YYYY');
  
  const updateSelected = (selectedVal) => {
    setFilterData({ 
      start: filterData.start, 
      end:filterData.end, 
      selectedChart: selectedVal,
      house: filterData.house,
      zones: filterData.zones
    });
  }

  const dataFiltering=()=>{
    let rtnData = [];

    data.map((r)=>{
      let filterPass = false;
      //add in filter options
      filterPass = true;
      if (filterPass) rtnData.push(r);
    });
    
    setFilteredData(rtnData);
  }

  
  const dbDataRefresh=()=>{

    let sData = {
      "userkey": localStorage.getItem("userEmail"),
      "user": localStorage.getItem("userEmail"),
      "homes": filterData.house,
      "startDate" : filterData.start,
      "endDate" : filterData.end
    };

    axios.post("/reports/data", sData)
      .then(response => {
        
        //g("NewProcessData");
        
        setData(processData(response.data));

      });
  
  }

  const repList = [
    //{ name: 'Resident Calls' },
    { name: 'Above Target Response' },
    { name: 'Average Response Times' },
    { name: 'Total Calls Per Hour' },
  ];

  const GetSelectedChart = () => {

    let sel = filterData.selectedChart;

    //if (sel == "Resident Calls") {  return (<ReportArquellaResidentCalls data={filteredData} />); }
    if (sel == "Above Target Response") { return (<BarChartAboveAverageResponse data={filteredData} />); }
    if (sel == "Average Response Times") { return (<ArquellaAverageResponseTimes data={filteredData} />); }
    if (sel == "Total Calls Per Hour") { return (<ArquellaBarChartHoursKPI  data={filteredData} />); }

    return (<div></div>);
    
  }

  const homeCallBack = (home) => {
    setFilterData({ 
      start: filterData.start, 
      end:filterData.end, 
      selectedChart: filterData.selectedChart,
      house: home,
      zones: filterData.zones
    });

  }

  const zoneCallBack = (zones) => {

    setFilterData({
      start: filterData.start, 
      end:filterData.end, 
      selectedChart: filterData.selectedChart,
      house: filterData.house,
      zones: zones
    });

  }

  return (
    <div id="AIDA_NAV_MAIN">
      <div id="AIDA_NAV" className="AIDA_NAV">

      <p>Home</p>
      <HomeFilter callback={homeCallBack} />
{
 //     <br />
 //     <p>Zone</p>
      
 //     <ZoneFilter callback={zoneCallBack} />
}     
      </div>
      <div className="AIDAMAINTCONTENT">
  
        <Scroller 
          selected={filterData.selectedChart}
          updateFunction={updateSelected} 
          list={repList}
        />
        <br />
        <br />

        <div className="reports_container_four">

          <GetSelectedChart sel={filterData.selectedChart} />
          
        </div>
      </div>
    </div>

  );

}

function calculateCallDuration(duration) {

  const durationArr = duration.split(":");
  let rtnVal = 0;

  if (durationArr.length > 0) {

    const secs = parseInt(durationArr[2]);
    if (secs > 0) rtnVal = secs;

    const mins = parseInt(durationArr[1]);
    if (mins > 0) rtnVal = rtnVal + (mins * 60);

    const hours = parseInt(durationArr[0]);
    if (hours > 0) rtnVal = rtnVal + ((hours*60)*60);


  }
  else {return 0;}
  
  
  return rtnVal;
}

function processData(data) {

  let rtnVal = [];

  data.map(d=>{
    const callDate = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY");
    const hr = parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY").hour());

  if (d["Call Type"]!="Attendance") {
    rtnVal.push({
      
      "Call Duration" : d["Call Duration"],
      "Call Point" : d["Call Point"],
      "Call Type" : d["Call Type"],
      "Care Delivered" : d["Care Delivered"],
      "Carer ID" : d["Carer ID"],
      "Date" : d["Date"],
      "End Time" : d["End Time"],
      "Resident" : d["Resident"],
      "Start Time" : d["Start Time"],
      "Zone" : d["Zone"],
      "Call Month" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").month()),
      "Call Weekday Number" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").day()),
      "Call Hour" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").hour()),
      "Call Day" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").day()),
      "Call Duration Mins" : calculateCallDuration(d["Call Duration"]),
      "Call Shift" : parseInt(moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm:ss").hour()),

    });

  }

  });

  console.log(rtnVal);

  return rtnVal;
}


function returDataObj(title, callData) {

  let rtnArr = [];
  
  //rtnArr.push({
//  });

  return rtnArr;
}

export default GeneralSummaryReport;