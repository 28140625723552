import React, {useContext, useState} from 'react';
import './subsection.css';
import GetComments from './Comments/comments';
import Entries from './entries/entries';
import ActionsIncidents from './ActionsIncidents/actionsincidents';
import WellBeingList from './wellbeing/wellbeinglist';
import Liquids from './liquids/liquids';

const SubSection = (props) => {

    const jsonSubSection = props.data;

    let objEntries = [];

    if (jsonSubSection.SubSection_type == "questions") {
        objEntries = <Entries 
                        entryList={jsonSubSection.SubSection_Entries} 
                    />;}
    
    if (jsonSubSection.SubSection_type == "sleep") {
        objEntries = <Entries 
                        entryList={jsonSubSection.SubSection_Entries} 
                    />;}

    if (jsonSubSection.SubSection_type == "well-being") {
        objEntries = <WellBeingList 
                        list={jsonSubSection.SubSection_Entries} 
    />;}
    

    if (jsonSubSection.SubSection_type == "liquids") {
        objEntries = <Liquids 
                        entryList={jsonSubSection.SubSection_Entries}
                        list={jsonSubSection.SubSection_Entries} 
    />;}

    if (jsonSubSection.SubSection_type == "actions") {
        objEntries = <ActionsIncidents 
                        cpid={jsonSubSection.SubSection_Actions}
                        //sectid={jsonSubSection.SubSection_Actions}
                        //subsectid={jsonSubSection.SubSection_Actions}
                        actions={jsonSubSection.SubSection_Actions}
                        incidents={jsonSubSection.SubSection_Incidents}
                    />;}
  

    return (
        <div className="cp_subsection_container">
            <div>{props.SubSection_title}</div>
            
            {
            (jsonSubSection.hasComment=="1") 
                ?
                <GetComments 
                    ssid={jsonSubSection.SubSection_id}
                    title={jsonSubSection.Section_name} 
                    comments={jsonSubSection.SubSection_Comments} />
                :
                null
            }

            {objEntries}

        </div>
    );
}

export default SubSection;