import React, {
    //useContext, Component, 
    useState, useRef
} from 'react';
import IMG_Print from '../../../images/icons/print_white.png';
import DropSelect from '../../common/DropSelect/DropSelect';
import DropSelectMulti from '../../common/DropSelectMulti/DropSelectMulti';
import Moment from 'moment';
import ReactToPrint, {ComponentToPrint, useReactToPrint } from "react-to-print";
import CareResidentDash from './careresidentdash';

const getZoneJSON = () => {
    let rtn = [];
    try {
        rtn =JSON.parse(localStorage.getItem('zones'));
    } catch (e) {console.log(localStorage.getItem('zones'));}
    return rtn;
}


const CareDashboard = (props) => {
    const [zoneOptions, setZoneOptions] = useState(getZoneJSON);
    const [zoneSelections, setZoneSelections] = useState(getZoneJSON);
    const [homeSelection, setHomeSelection] = useState((localStorage.getItem('sites')!=null) ? JSON.parse(localStorage.getItem('sites'))[0].label : "");
    const [dateSelection, setDateSelection] = useState([localStorage.getItem("singleSelectedDate")]);
    const [homeOptions, setHomeOptions] = useState(JSON.parse(localStorage.getItem('sites')));
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
         content: () => componentRef.current,
      });
      
    const [allCalls, setAllCalls] = useState([]);
    const getDateRef = (ref) => {
        let n;
        const options = window.dashDateOptions;
        options.forEach((val) => {
            if (val.label == ref) {
                n = val.value;
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    function siteChanged(siteName) {
        setHomeSelection(siteName);
    }

    function zoneChanged(selections) {
        setZoneSelections(selections);
    }


    function getValue(changeVal) {
        let rtnVal = "";
        JSON.parse(localStorage.getItem('sites')).forEach((val) => {
            if (val.label == changeVal) {
                rtnVal = val.value;
            }
        });
        return rtnVal;
    }

    function dateChanged(dateVal) {
        
        setDateSelection(dateVal);
    }

    const HomeSelection = (props) => {   
        return <DropSelect options={homeOptions} selectedVal={homeSelection} changeHandler={siteChanged} />
    }

    const ZoneSelection = (props) => {
        return <DropSelectMulti options={zoneOptions} selections={zoneSelections} filterCall={zoneChanged} />
    }

    const DateSelection = (props) => {   
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const options = [];
        
        let addDate = new Date();
        let dayBack1 = new Date();
        let dayBack2 = new Date();
        let dayBack3 = new Date();
        let dayBack4 = new Date();

        dayBack1.setDate(dayBack1.getDate() - 1);
        dayBack2.setDate(dayBack2.getDate() - 2);
        dayBack3.setDate(dayBack3.getDate() - 3);
        
        options.push({label:'Today', value: addDate});
        options.push({label:'Yesterday', value: dayBack1});
        options.push({label:days[dayBack2.getDay()], value: dayBack2});
        options.push({label:days[dayBack3.getDay()], value: dayBack3});
        //options.push({label:days[dayBack4.getDay()], value: dayBack4});
        
        window.dashDateOptions = options;

        if ((dateSelection == "") && (options.length>0)) {dateChanged(options[0].label)}
        return <DropSelect options={options} selectedVal={dateSelection} changeHandler={dateChanged} />
    }

    const callbackCallType = () => {
    }

    const NavPane = (props) => {
        return (
            <div id="AIDA_NAV" className="AIDA_NAV">
                {props.children}
            </div>
        );
    }

    const allCallsCallBack = (calls) => {
        setAllCalls(calls);
    }

    
    let keyRef = new Date().toString();
            return (
                
                <div id="AIDA_NAV_MAIN">
        
                <NavPane>
                <div>
                
                    <p>Home</p> 
                    <HomeSelection />
                    <br />

                    <p>Zone</p>
                    <ZoneSelection />
                    <br />

                    <br />
                    <p>Set Time Period</p>
                    <DateSelection />
                                 
                </div>

                    <div className="navbuttons">
                        <br />
                        <br />
                        
                        <div className="save_print">

                            <button onClick={(e) => window.print()} className="print"><img alt="Card image" className="print" src={IMG_Print} /></button>
                        </div>
                    </div>
                </NavPane>
                
                <CareResidentDash />

            </div>

            );
}



export default CareDashboard;


              
