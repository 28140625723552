import React from 'react';
import './toggle.css';

export default class WeekMonthDayToggle extends React.Component {

    state = {
        selectedValue: this.props.value ? this.props.value : "Today"
    }

    valueSelected=(value)=>{
        this.setState({selectedValue:value});
        this.props.callBack(value);
    }
    
    GetSelection = ({value})=>{
        return ( 
            <div 
                className={(this.state.selectedValue==value) ? "cal_twt_selected" : "cal_twt_option"}
                onClick={()=>this.valueSelected(value)}>{value}</div>
        );
    }

    render() {
        
        return(
            <div className="cal_twt_container">
                {(this.props.today==true) ? <this.GetSelection value="Today" /> : null}
                <this.GetSelection value="Week" />
                <this.GetSelection value="Month" />
            </div>
        );
    }
}