import React, 
{
//    Component, 
    useState
    //, useContext 
} from "react";
import IMG_TOGGLE_NEG from '../../../../../../images/icons/toggle_negative.png';
import IMG_TOGGLE_POS from '../../../../../../images/icons/toggle_positive.png';
//import IMG_CARE from '../../../../../../images/icons/toggle_negative.png';
import axios from 'axios';

const NewActionForm = (props) => {

    const [id, setID] = useState(props.data ? props.data.action_id : -1);
    const [actionTitle, setActionTitle] = useState(props.data.action_title);
    const [actionComments, setActionComments] = useState(props.data ? props.data.action_comments : "");
    const [actionUrgent, setActionUrgent] = useState(props.data ? props.data.action_urgent : "");
    const [actionComplete, setActionComplete] = useState(props.data ? props.data.action_complete : "");
    

    const subtmitAction = () => {
        
        let sData = {
            "userID": localStorage.getItem("userEmail"),
            "action": (id==-1) ? "newaction": "updateaction",
            "formJSON": getForm()
          };


          axios.post(
              "/care/active/update.php", 
              sData
          )
          .then(response => {


            let A = JSON.parse(response.data);
            setID(A["action_id"]);

            setActionTitle(A["action_title"]);
            setActionComments(A["action_comments"]);
            setActionUrgent(A["action_urgent"]);
            setActionComplete(A["action_complete"]);
         
          })
          .catch(response => {
          });
        
    }

    const getForm=()=>{
        let data=[{
            cpid: localStorage.getItem("careplanID"),
            action_id: id,
            action_title: actionTitle,
            action_comments: actionComments,
            action_urgency: actionUrgent,
            action_complete: actionComplete,
        }];

        return data;
    }
    

    const urgentCheckChange=(changeVal)=>{
        setActionUrgent(changeVal);
        subtmitAction();
    
    }
    
    const completeCheckChange=(changeVal)=>{
        setActionComplete(changeVal);
        subtmitAction();
    }

    return (
        <div className="mob_action_form_container">
            <div className="mob_action_form_row">
                <input 
                    className="mob_action_title" 
                    type="text"
                    onChange={e=>{
                        setActionTitle(e.target.value);
                    }}
                    onBlur={e=>{
                        subtmitAction();
                    }}
                    defaultValue={actionTitle} />
                    
                <div className="mob_action_checkboxes_container">
                    <div className="mob_action_checkbox_container">
                        <label className="mob_action_checkbox_label">Urgent</label>
                        <UrgentCheckComponent value={actionUrgent} checkChangeEvent={urgentCheckChange} />
                    </div>
                    <div className="mob_action_checkbox_container">
                        <label className="mob_action_checkbox_label">Complete</label>
                        <CompleteCheckComponent value={actionComplete} checkChangeEvent={completeCheckChange} />
                    </div>
                </div>
            </div>
            <div className="mob_action_form_comments">
                <div className="mob_action_form_comments_title">Comments</div>
                <div>
                    <textarea
                        className="careplan_comments"
                        value={actionComments}
                        onChange={e=>{
                            setActionComments(e.target.value);
                        }}
                        onBlur={e=>{
                            subtmitAction();
                        }}
                    ></textarea>
                </div>
            </div>
            
        {//id == -1 ? <SubmitButton /> : null
        }

        </div>
    );
}







const UrgentCheckComponent = (props) => {

    const [checkVal, setCheckVal] = useState({
        checked: (props.value=="1") ? true : false,
    });

    const checkChange = (changeVal) => {
        setCheckVal({checked:changeVal});
        props.checkChangeEvent(changeVal);
    }
    
    return (
        (checkVal.checked) 
        ? <img 
            onClick={e=>{checkChange(false)}}
            className="cp_actions_neg_toggle"
            src={IMG_TOGGLE_NEG} 
            alt="" 
            />

        : <div 
            onClick={e=>{checkChange(true)}}
            className="cp_actions_deselect_toggle"></div> 
        
    );
}

const CompleteCheckComponent = (props) => {

    const [checkVal, setCheckVal] = useState({
        checked: (props.value=="1") ? true : false,
    });

    const checkChange = (changeVal) => {
        setCheckVal({checked:changeVal});
        props.checkChangeEvent(changeVal);
    }
    
    return (
        
        (checkVal.checked) 
        ? <img 
            onClick={e=>{checkChange(false)}}
            className="cp_actions_pos_toggle"
            src={IMG_TOGGLE_POS} 
            alt="" 
            />

        : <div 
            onClick={e=>{checkChange(true)}}
            className="cp_actions_deselect_toggle"></div> 
        
    );
}


export default NewActionForm;