import React
//, {useContext, useState} 
from 'react';
import './EntryComments.css';
import axios from 'axios';

export default class EntryComment extends React.Component {

    state = {
        comments: this.props.comments 
                    ? this.props.comments.map(r => JSON.parse(r)) 
                    : [],
    }

    
    blankEntry = {
        Entry_ID: -1,
        Entry_Comments: ""
    }; 

    render() {
        let comments = this.state.comments.map(c=> {
            return <EComment commentData={c} entryID={this.props.entryID} />
        });

        return(
            <div>

                {comments}

                <div 
                    className="cp_comment_input"
                    onClick={c=>{
                        
                        let tmpArr = this.state.comments;

                        tmpArr.push(this.blankEntry);

                        this.setState({comments: tmpArr});

                    }}
                >&nbsp;<span>+</span>&nbsp;Add Comment</div>

            </div>
        );

    }

}

class EComment extends React.Component {

    state = {
        id: this.props.commentData.cpec_id ? this.props.commentData.cpec_id : -1,
        comment: this.props.commentData.cpec_comment ? this.props.commentData.cpec_comment : "",
        originalValue: this.props.commentData.cpec_comment ? this.props.commentData.cpec_comment : "",
        entryID: this.props.entryID
    };


    updateDB () {
        
        let formJSON = {
            "id": this.state.id,
            "comment": this.state.comment,
            "entryID": this.state.entryID
        }

        let sData = {       
            "userkey" : localStorage.getItem("userEmail"),
            "formJSON" : formJSON
        };
        
        axios.post("/care/active/entrycomment.php", sData)
        .then(response => {
            
            let DTA = JSON.parse(response.data);
            
            this.setState({
                id: DTA["cpec_id"],
                comment: DTA["cpec_comment"],
                originalValue: DTA["cpec_comment"],         
            });

            //update id and comment with value from db
            //this.setState({id: response.data.[})
        })
        .catch(response => {
            //update id and comment with value from db
            //this.setState({id: response.data.[})
        });

    }

    render() {

        return(

            <input 

                className="cp_comment_input"
                value={this.state.comment}
                onChange={e=>{
                    
                    let val = e.target.value;
                    
                    this.setState({
                        comment: val
                    });

                }}

                onBlur={e=>{
                    
                    let val = e.target.value;
                    
                    this.setState({
                        comment: val
                    });

                    if (this.state.originalValue!=val){
                        this.updateDB();
                    }

                }}


            />

        );

    }
}