import React, {useContext, useState} from 'react';
import './entries.css';
import Toggle from './Toggle/toggle';
import Expander from '../Expander/Expander';

const Entries = (props) => {
    const entries = props.entryList;
    console.log("ENTIES");
    console.log(props);
    return (
            <div>
            {entries.map((e)=> <Entry eData={e} />)}    
            </div>
    );
}


const Entry = (props) => {
    const entry = props.eData;
    console.log("entry");
    console.log(entry);

    let widget;

    if (entry.Entry_Type == "toggle") {widget = <Toggle />;}

    /*
        Entry_Comments: "Only likes hair to be brushed with a comb."
        Entry_Date: null
        Entry_ID: "2"
        Entry_Name: "Hair Brushed"
        Entry_Type: "toggle"
        Entry_User_Assigned: "1"
        Entry_User_Completed: "1"
     /   Entry_Value: "neutral"
    */

//    const header = <HeaderText name={entry.Entry_Name} widget={widget} />;

    return (
        <Expander header={entry.Entry_Name} widget={widget} headerClass="cp_subsection_entry_expander">

            <p>Comments</p>
        
        </Expander>
    );
}

const HeaderText = (props) => {

    return (            
        <div className="cp_question_entry">
            <div>{props.name}</div>
            <div>{props.widget}</div>
        </div>
    );
}

export default Entries;