import React from 'react';
import firebase from 'firebase'
import Moment from 'moment';


const getData=(ref, dateVal)=> {
        
        localStorage.setItem("careCalls", JSON.stringify([]));

        firebase.database().ref(ref).once("value", snapshot => {
            
        let oldCalls = JSON.parse(localStorage.getItem("careCalls"));

        let careCalls = (Array.isArray(oldCalls)) ? oldCalls : [];
        
        snapshot.forEach(snap => {
            
            const r = snap.val();
            
            let k = Object.keys(r)
            
            k.map(n=>{
                careCalls.push(r[n]);
            });
            
        });

        localStorage.setItem("careCalls", JSON.stringify(careCalls));
        
      });
      
}

function getCareRef(dt) {
    
    let d = Moment(dt);

    let ref = "CareCalls/";

        ref = ref + d.format('YYYY') + "/";
        ref = ref + d.format('M') + "/";
        ref = ref + d.format('D') + "/";
    
    return ref;
}

function setCareCallsData(house) {

    let curDate = new Date();
    let dayBack1 = new Date();
    let dayBack2 = new Date();
    let dayBack3 = new Date();
    let dayBack4 = new Date();
    
    dayBack1.setDate(dayBack1.getDate() - 1);
    dayBack2.setDate(dayBack2.getDate() - 2);
    dayBack3.setDate(dayBack3.getDate() - 3);
    dayBack4.setDate(dayBack4.getDate() - 4);

    let ref = ""
    ref = "CareCalls/"  + curDate.getFullYear() + "/" + curDate.getMonth() + "";

    getData(getCareRef(curDate));
    getData(getCareRef(dayBack1));
    getData(getCareRef(dayBack2));
    getData(getCareRef(dayBack3));
    getData(getCareRef(dayBack4));

}

export default setCareCallsData;


              