import React, {useContext, useState} from 'react';
import {Collapse} from 'react-collapse';
import './Expander.css';

//expandedClass
//headerClass
//titleClass
//header

const Expander = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={props.headerClass}>
        <div className={props.titleClass}
            >
            <div className="cp_question_entry">
                <div className="cp_question_entry_title">
                    {props.header} 
                </div>
                <div
                        className="expand_shrink"
                        onClick={(e)=>{setIsOpen(!isOpen)}} 
                    >&nbsp;{isOpen ? ">" : "<"}</div>
                <div>{props.widget}</div>
                
            </div>
    
        </div>
        <Collapse isOpened={isOpen}>
            <div className={props.expandedClass}>
                {props.children}
            </div>
        </Collapse>
        </div>
    );

}

export default Expander;