import React, {useContext, useState} from 'react';
import './toggle.css';

import IMG_TICK from '../../../../../../images/toggle/tick.png';
import IMG_QUESTION from '../../../../../../images/toggle/question.png';
import IMG_NEGATIVE from '../../../../../../images/toggle/neg.png';


const Toggle = (props) => {
    //props.value
    const [toggleValue, setToggleValue] = useState("question");

    const tickClass = toggleValue == "tick" ? "toggle_tick toggle_left" : "toggle_deselect toggle_left" ;
    const questionClass = toggleValue == "question" ? "toggle_question" : "toggle_deselect_middle" ;
    const negClass = toggleValue == "negative" ? "toggle_negative toggle_right" : "toggle_deselect toggle_right" ;

    const updateToggle = (val) => {
        console.log("updateToggle");
        console.log(toggleValue);
        console.log(val);
        setToggleValue(val);

    }

    return (
        <div className="toggle_option_container">

            <div className={tickClass} onClick={()=>{updateToggle("tick")}}><img alt="Toggle Ticked" className="toggle" src={IMG_TICK} /></div>
            <div className={questionClass} onClick={()=>{updateToggle("question")}}><img alt="Toggle Question" className="toggle" src={IMG_QUESTION} /></div>
            <div className={negClass} onClick={()=>{updateToggle("negative")}}><img alt="Toggle Negative" className="toggle" src={IMG_NEGATIVE} /></div>      
            
        </div>
    );
}

export default Toggle;