import React, {useContext, Component} from 'react';
import './ResidentCarePlan.css';
import axios from 'axios';
import { isDate } from 'moment';
import ActionSummary from './summary/actions';
import IMG_ACTION from '../../../images/icons/careplan/action.png';

export default class ResidentCarePlan extends React.Component {

    state = {
      actions: [],
      incidents:[],
      actionSummary: ""
    };

    componentDidMount() {
      this.addResidentHandler();  
    }

    addResidentHandler() {
        
      let sData = {
        "userID": "",
        "action": "",
        //"action": "",
      };
    
      axios.post("/care/active/cpsummary.php", sData)
      .then(res => {
        console.log("CPSummary");
        console.log(res);
        
        this.setState({
          actions:res.data.actions,
          incidents:res.data.incidents,
          actionSummary: <ActionSummary actions={res.data.actions}  incidents={res.data.incidents} />
        });

      })
      .catch(err=> {console.log("Unable to add the resident to the database: " + err);});
    }

    render() {

      return(

        <div className="careplan_summary_container">
          <div style={{display:"flex", flexDirection:"row"}}>
            <img className="cp_summary_icons" src={IMG_ACTION} />
            <div className="cp_summary_actions">Actions</div>
          </div>
          

              {this.state.actionSummary}
            

          <div style={{display:"flex", flexDirection:"row"}}>
            <img className="cp_summary_icons" src={IMG_ACTION} />
            <div className="cp_summary_incidents">Incidents</div>          
          </div>
          
        </div>

        );

    }

}
