import React, {useState, useRef} from 'react';
import { Chart } from "react-google-charts";

export default class BarChart extends React.Component {

  state = {

    backgroundColor: "#dcf5f3",
    data: [
      ['Time Period', 'Actions/Incidents Raised'],
      ['Morning 4am-12pm', 23],
      ['Afternoon 12pm-8pm', 10],
      ['Evening 8pm-4am', 18],
    ],
    xTitle: "Time Period",
    yTitle: "No of Actions/Incidents this week."

  }

  ChartOptions = {
    title: this.state.title,
    colors: ['#4cc1bd', '#93C967'],
    chartArea: { width: '30%' },
    backgroundColor: '#dcf5f3',
    chartArea: { 
      width: '60%', 
      height: '60%'
      },
    hAxis: {
      title: this.state.xTitle,
      minValue: 0,
      titleTextStyle: { color: 'darkgrey' },
      },
    legend: 'none',
    vAxis: {
      title: this.state.yTitle,
      titleTextStyle: { color: 'darkgrey' },
      },
    }

  render(){
    return(
        <div style={{ display: 'flex', maxWidth: 900 }}>
          <Chart
            width={500}
            height={300}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={this.state.data}
            options={this.ChartOptions}
          />
        </div>
    );
  }
}


