//https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html#position
//tooltip example
import React, { Component, useEffect, useRef } from "react";
import * as d3 from "d3";
import demoOne from './demodata1';
import { text } from "d3";
import './graphs.css';


//const dta = [{"Resident 1", 39}]

const dta = [38, 43, 23, 4, 19, 83, 199];

const BarChart = (props) => {

  const gRef = useRef(null);
  let values = demoOne;
  console.log("gRef");

  useEffect(()=>{
    
    var barHeight = props.height ? props.height : 200;
    var barWidth = props.width ? props.width : 400;
    var barPadding = props.padding ? props.padding : 2;


    const svg = d3.select(gRef.current)
        .append('svg')
        .attr("width", barWidth)
        .attr("height", barHeight);


    var y = d3.scaleLinear().domain([15,90]).range([190,0]);
    var x = d3.scaleSqrt().domain([100,300]).range([10,50]);
    var r = d3.scaleLog().domain([50, 5000]).range([0, 600]);

    var colors = ["white", "red", "green", "blue", "black"];
    var scale = d3.scaleBand()
                .domain(d3.range(dta.length))
                .range([0, barWidth]); 

    const dragger = d3.drag().on('drag', function(){
      let e = d3.event;
      console.log(e.dy);
    });
    
        
    var line = svg.append("line")
             .attr("x1", 50)
             .attr("y1", 260)
             .attr("x2", 750)
             .attr('y2', 260)
             .call(dragger);


         
        
        svg.selectAll('rect')
        .data(values)
        .enter()
        .append("rect")
        .attr('x', function(d,i){
          return i*(1000/values.length);
        })
        .attr('y', function(d){
          return barHeight-d*barPadding;
        })
        .attr('width', barWidth/values.length-barPadding)
        .attr('height', function(d){
          return d * barPadding;
        })
        .attr('fill', function(d,i){
          return getColourFromIndex(i);
        });
        
        svg.selectAll("text")
          .data(values)
          .enter()
          .append("text")
          .text(function(d){
            return d;
          })
          .attr('x', function(d,i){
            return (
              (i*(1000/values.length)) +
            ((barWidth/values.length-barPadding)/2)
            );
          })
          .attr('y', function(d) {
            return barHeight-d*barPadding+15;
          })
          .attr('fill','red')
          .attr('font-size', 10)
          .attr('text-anchor', 'middle')
;



/*
var drag = d3.drag()
           .on('dragstart', null)
           .on('drag', function(d){
             // move circle
             var dx = d3.event.dx;
             var dy = d3.event.dy;
             var x1New = parseFloat(d3.select(this).attr('x1'))+ dx;
             var y1New = parseFloat(d3.select(this).attr('y1'))+ dy;
             var x2New = parseFloat(d3.select(this).attr('x2'))+ dx;
             var y2New = parseFloat(d3.select(this).attr('y2'))+ dy;
             line.attr("x1",x1New)
                 .attr("y1",y1New)
                 .attr("x2",x2New)
                 .attr("y2",y2New);
             }).on('dragend', function(){
           }); 

var line = d3.select("svg")
              .append("line")
              .attr("x1",10)
              .attr("y1",10)
              .attr("x2",200)
              .attr("y2",200)
              .attr("stroke-width",10)
              .attr("stroke","black")
              .call(drag);
*/
           



    /*
    const gr = d3.select(gRef.current)
      .selectAll('p')
      .data(values)
      .enter()
      .append('p')
      .text(function(d){
        return 'testersss::' + d;
      })
      .style('color', function(d){
        if (d < 0) {
          return 'red';
        }
        else {
          return 'green';
        }
      });
      */
    //gr.append('p')
    //  .attr('class', 'className')
    //  .text('OK test over');
      
  }, [gRef]);

  /*
  var g = d3.select(gRef)
            .append(gRef)
            .attr('class', 'className')
            .text('OK test over');
    
            console.log("Grh");
            console.log(g);
            console.log("G");
*/
  return (
      <div ref={gRef} className="gBarChart">
        {//<div className="bar"> </div>
        }
      </div>
  );

}



const getColourFromIndex = (index) => {

  if (index > 5) {
    const inNum = Math.round((index/6),0);
    index = index - (inNum*5);
  }
  
  if (index==0) {return 'red';}
  if (index==1) {return 'blue';}
  if (index==2) {return 'green';}
  if (index==3) {return 'white';}
  if (index==4) {return 'orange';}
  if (index==5) {return 'yellow';}
  


  return 'purple';
}

export default BarChart;