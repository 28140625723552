import React, { Component, useEffect, useRef } from "react";
import './cj.css';
import CareJourney from './CareJourney';

export default class CareJourneyGraph extends React.Component {

    state = {
        journeyList: this.props.data ? this.props.data : [],
    }

    render() {

        return(
            <table className="rpt_carejourney_table">
              <tr>
                    <th className="first_last">Call Time</th>
                    <th className="middle">Call Detail</th>
                    <th className="first_last">Total Duration</th>
                </tr>

            {
                this.state.journeyList.map(j=>{
                    return (
                        <tr>
                            <td className="first_last">{j.call_time}</td>
                            <td className="middle"><CareJourney data={this.state.journeyList[0].breakdown} /></td>
                            <td className="first_last">{j.total_duration}</td>
                        </tr>
                    );
                })
            }

            </table>
        );
    }

}