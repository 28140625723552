import React from 'react';
import Timer from 'react-compound-timer';
import moment from 'moment/moment';
import { ta } from 'date-fns/locale';

export default class UpTimer extends React.Component {

    isValidDate(value) {
        var dateWrapper = new Date(value);
        console.log((dateWrapper));
        return !isNaN(dateWrapper.getDate());
    }

    render() {

        let startTime = this.props.startTime;
        let endTime = Date();
        let timerSecs = 0; 
        
        if (this.isValidDate(startTime)) {
        //            timerSecs = Math.abs((e.getTime() - s.getTime()) / 1000);
            //let t = ( - ) / 1000);
            const startDate = moment(startTime, "YYYY/MM/DD HH:mm:ss");
            const timeEnd = moment(Date());
            const diff = timeEnd.diff(startDate);
            let t = moment.duration(diff);

            timerSecs = t.asMilliseconds();
            
        }

        return(
            <div>
                <Timer
                    initialTime={timerSecs}
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                    //direction="backward"
        >
                    {() => (
                        <React.Fragment>
                            <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                        </React.Fragment>
                    )}
                </Timer>
            </div>
        );
    }
}