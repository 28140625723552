import React, {useState} from 'react';
//import BarChart from '../../../components/graphs/bar';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';

export default class ContinenceReport extends React.Component {

    state = {
        data: []
      }  
    
      componentDidMount () {
        let sData = {
          "house": this.props.house,
          "startDate": this.props.startDate,
          "endDate": this.props.endDate,
          "zones": this.props.zones
        };
       
        sData = {
            "house": "Arquella Demo",
            "startDate": "01/01/2020 00:00:00",
            "endDate": "01/01/2021 00:00:00",
            "zones": ""
          };
        
        axios.post("/resident/rpt/calls_attendance_by_resident.php", sData)
        .then(response => {
          let resCount = 0;
          try {
            resCount = response.data.residents.length;
          } catch(err) {}
          if (resCount>0) {
            this.setState({data: response.data.residents})
          }
          else {
            this.setState({data: [{
              backgroundColor: "#26dcdc",
              data: "",
              label: ""
              }]})
          }
        });
      }
    
      
      componentDidUpdate(prevProps) {
    
        if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
          let sData = {
            "house": prevProps.house,
            "startDate": prevProps.startDate,
            "endDate": prevProps.endDate,
            "zones": prevProps.zones
          };

          sData = {
            "house": "Arquella Demo",
            "startDate": "01/01/2020 00:00:00",
            "endDate": "01/01/2021 00:00:00",
            "zones": ""
          };

          axios.post("/resident/rpt/calls_attendance_by_resident.php", sData)
          .then(response => {
            let resCount = 0;
            try {
              resCount = response.data.residents.length;
            } catch(err) {}
            if (resCount>0) {
              this.setState({data: response.data.residents})
            }
            else {
              this.setState({data: [{
                backgroundColor: "#26dcdc",
                data: "",
                label: ""
                }]});
            }
          });
    
        }
      }
    
    
      render() {
    
        let ct = 0;
    
        try {
          ct = this.state.data.length;
        }
        catch(err){}
    
    
        if (ct>0) {
          return (
            <div>
              <ShowBarChart 
                data={this.state.data} /> 
            </div>
          );
        }
        else {
          
          return <div></div>;
        }
    
      }
    
}


function ShowBarChart(props) {


    const data = {
      labels: new Array(props.data.length).fill(""),
      datasets: props.data.length>0 ? props.data : null,
      backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    };
    
    
      return(
    
    
    <Bar
    
      data={data}
      width={450}
      height={250}
      options={{
        maintainAspectRatio: true,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Checks'
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date'
            },
          }]
    
      }
      }}
    
    />
    
    
        );
    }
    
    