import React, {useContext, useState} from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import Actions from './Actions/Actions';
import './careplan.css';
import CareplanSections from './Sections/Sections';

class GetCareplan extends React.Component  {

    state = {
        careplan: []
    };

    componentDidMount () {
        
        let sData = {
            "userkey": localStorage.getItem("userEmail"),
            "careplanID": "1"
        };
        
        axios.post("/care/careplan/read")
        .then(response => {
          this.setState({careplan: response.data})
        });
        
    }

    render() {
        console.log("careplan");
        console.log(this.state.careplan);
        if ((Array.isArray(this.state.careplan.length))|| (this.state.careplan==0)) {
            return (
                <div>Opening CarePlan</div>
            );
        }
        else {
            return <Careplan data={this.state.careplan} />;
        }
    
    }

}


const Careplan = (props) => {
    
    const [careData, setCareState] = useState(props.data);
    
    
    return (
        <div className="CareplanContainer">
            {
            //<Actions data={careData.actions} />
            }
            <CareplanSections data={careData.sections} />
        </div>
    );

}

export default GetCareplan;
