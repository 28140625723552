import React, {Component, useState, useContext } from "react";
import axios from 'axios';
import HomeFilter from '../SelectFilters/HomeFilter';
import ZoneFilter from '../SelectFilters/ZoneFilter';
import CareStatusFilter from '../SelectFilters/CareStatusFilter';
import DatePeriodFilter from '../SelectFilters/DatePeriodFilter';
import ActionIncidentFilter from '../SelectFilters/ActionIncidentFilter';
import GetAvatar from './avatar/Avatar';
import Resident from './Resident/Resident';
import {
  getHomeOptions, 
  getHomeSelection
} from '../datacollector/datacollector';
import CareScreen from './CareScreen/CareScreen';


const Care = () =>  {

  const homeOptions = getHomeOptions();
  const zoneOptions = getHomeOptions();
  const datePeriodOptions = getHomeOptions();
  const careStatusOptions = getHomeOptions();
  const actionsIncOptions = getHomeOptions();
  
  const [filters, setFilters] = useState({
    home: getHomeSelection(),
    zones: [],
    datePeriod : "Today",
    carestatus: [],
    actionsInc: "N/A"
  });
  
  const [careData, setCareState] = useState({
    posts: [],
    dbConnected: false,
    adding: false,
    selectedResident: null,
    fName: "",
    surname: "",
    preferredName: "",
    dob: "",
    age: "",
    room: ""
  });  


    const homeCallBack = (home) => {
      setFilters({
        home: home,
        zones: filters.zones,
        datePeriod : filters.datePeriod,
        carestatus: filters.carestatus,
        actionsInc: filters.actionsInc
      });
    }

    const zoneCallBack = (res) => {

    }

    const careStatusCallBack = (res) => {

    }
    
    const actionIncCallBack = (res) => {

    }


  return (

    <div id="AIDA_NAV_MAIN">

      <div id="AIDA_NAV" className="AIDA_NAV">
      
      <p>Home</p>
        <HomeFilter 
          callback={homeCallBack} 
          homeOptions={homeOptions}
          homeSelection={filters.homeSelection}
        />

        <br />
        <p>Zone</p>
        <ZoneFilter callback={zoneCallBack} />
        <br />
        <br />
                
        <p>Set Date Period</p>
        <DatePeriodFilter callback={homeCallBack} />
        <br />
        <p>Care Status</p>
        <CareStatusFilter callback={careStatusCallBack} />
        <br />
        <br />
        <p>Actions and Incidents</p>
        <ActionIncidentFilter callback={actionIncCallBack} />


      </div>

      <CareScreen filters={filters} />

    </div>
          
  );

}

export default Care;