import React, {useContext, Component, useState, useRef} from 'react';
import DropSelect from '../common/DropSelect/DropSelect';

const ActionIncidentFilter = (props) => {

    
    const o = [
        {"label":"N/A","value":"N/A"},
        {"label":"Urgent","value":"Urgent"},
        {"label":"Complete","value":"Complete"},
        {"label":"Commented","value":"Commented"},
    ];

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: o,
        homeSelection: "N/A",
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }

    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default ActionIncidentFilter;