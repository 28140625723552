import React, 
{
  //Component, 
  useState
  //, useContext 
} from "react";
import axios from 'axios';
import GetCareplan from '../careplan/careplan';
import './mobresidents.css';
import GetAvatar from './Avatar/avatar';
import {
//  BrowserRouter,
//  Switch,
//  Route,
  Link,
//  useLocation,
//  withRouter,  
//  Redirect
}
from "react-router-dom";

const MobCare = () =>  {
  
  const [careData, setCareState] = useState({
    posts: [],
    dbConnected: false,
    adding: false,
    selectedResident: null,
    fName: "",
    surname: "",
    preferredName: "",
    dob: "",
    age: "",
    room: ""
  });
  
  const getData = () => {
    const sites = JSON.parse(localStorage.getItem('sites'));

    let sData = {
      "userkey": localStorage.getItem("userKey"),
      "home" : sites[0].label,
      };
      
    axios.post("/resident/resident_list", sData)
    .then(response => {
      
      setCareState({
        dbConnected: true,
        posts: response.data ? response.data : []
      });

      
    });
  }

  const Post = (props) => (
    
    <Link to="careplan">
    
      <button className="resident_card_mob">

        <div className="avatar_name_mob">
    
          <GetAvatar firstname={props.data.res_first_name} surname={props.data.res_surname} />
          <div className="resident_name">{props.data.res_first_name} {props.data.res_surname}</div>
    
        </div>
        
        <div className="resident_room">{props.data.rm_name}</div>
        
      </button>
    
    </Link>
  
  );
  
  const Posts = careData.posts.map((post, i) =>
    <Post 
      key={i}
      data={post}
      residentID={post.Resident_id} 
      title={post.full_name}
      uin={post.uin}
      dob={post.Resident_DOB}
      room={post.Resident_Room} />  
  );
  
  
  const cancelHandler = () => {
    setCareState({
      adding: false,
      selectedResident: careData.selectedResident,
      dbConnected: careData.dbConnected,
      posts: careData.posts
    });
  }

  const [value, onChange] = useState(new Date());

 

  const [fName, setTitleFName] = useState('');
  const [surname, setSName] = useState('');
  //const [username, setUsername] = useState('');
  //const [email, setEmail] = useState('');

  const EditResident = (props) => {
    console.log("Edit Resident");
    console.log(props);

    const initialState = {

      id:  props.selection ? props.selection.res_id : "",
      name: props.selection ? props.selection.res_first_name : "",
      surname: props.selection ? props.selection.res_surname: "",
      preferredName: props.selection ? props.selection.res_preferred_name : "",
      dob: props.selection ? props.selection.res_dob : "",
      age: props.selection ? props.selection.res_age : "",
      room: props.selection ? props.selection.rm_name : "",
      nameError: "",
      surnameError: "",
      preferredNameError: "",
      dobError: "",
      ageError: "",
      roomError: "",
      onCancelHandler: cancelHandler
  
  }
    
    return(
        
      <div className="resident_summary_container">
        
        <MobCareHeader>Careplan</MobCareHeader>
        
        <GetCareplan />

      </div>
 
    );
          
  }

  const Backdrop = (props) => (

    props.show ? <div className="Backdrop"
                    onClick={props.clicked}
                  ></div> : null
  
  );
  const Modal = (props) => (
    <div>
      <Backdrop show={props.show} clicked={props.modalClosed}/>
    <div 
      className="ModalCare"
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
        
      }}
      >
      {props.children}
    </div>
    </div>
  );


  const AssistNavBar = (props)=> {
    return (
      <div className="dashMenu">
        <div className="navbar">
          {props.children}
        </div>
      </div>
    );
  }
  



if (careData.posts.length <= 0)  {

  if ((careData.dbConnected == false)) {
    getData();
    
    return (
      <div>Collecting data.</div>  
    );

  }
  else {
    return (
      <div>No data has been found.</div>  
    );
  }

}
else {
 
  return (
    <div>
      <MobCareHeader><b>{localStorage.getItem("userKey")}</b></MobCareHeader>
      <div className="mobile_main_container">
      
        <Modal show={careData.adding} modalClosed={cancelHandler}>

          <EditResident selection={careData.selectedResident} />

        </Modal>
        <div className="toplevelcare">
        
          {Posts}
        
        </div>

      <footer className="mob_footer"></footer>

      </div>

    </div>
      
  );

}


}

const MobCareHeader = (props) => {
  return (
    <div className="AIDA_MOB_CARE_HEADER">{props.children}</div>
  );
}
      


export default MobCare;