import React, {useContext} from 'react';
//import '../TeamMemberForm.css';
import axios from 'axios';
import NewUserBasicController from '../NewUserBasicController/NewUserBasicController';
import ConfirmationStep from '../ConfirmationStep/ConfirmationStep';
import TeamMemberHeader from '../TeamMemberHeader/TeamMemberHeader';


export default class NewUserAdd extends React.Component {

    state = {
        step : 1,
        userID: this.props.user_details ? this.props.user_details.User_ID : 0,
    }
    

    nextStep() {
        this.setState({ step : (this.state.step + 1) });
    }

    render() {

        let user_container_style = {

            width: "100%"
            
        };

        return (
            
            <div style={user_container_style}>
                
                <TeamMemberHeader
                 
                    firstName={this.props.user_details ? this.props.user_details.User_First_Name : "New"}
                    surname={this.props.user_details ? this.props.user_details.User_Surname : "User"}
                    cancelHandler={this.props.cancelHandler}
                />
                
                <div className="modal_details">
                
                    <NewUserBasicController 
                        
                        newUser={this.props.newUser}
                        cancelHandler={this.props.cancelHandler} 
                        onNextStep={e=>this.nextStep()}
                        user_details={this.props.user_details ? this.props.user_details : null}

                    >

                    </NewUserBasicController>
      
                </div>

            </div>

        );
        

    }

}

