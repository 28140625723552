import React, {useState} from 'react';
import DropSelect from '../../common/DropSelect/DropSelect';
//import DropSelectMulti from '../common/DropSelectMulti/DropSelectMulti';
//import Moment from 'moment';
//import ReactToPrint, {ComponentToPrint, useReactToPrint } from "react-to-print";




const HomeSelector = (props) => {

    const [homeSelection, setHomeSelection] = useState((localStorage.getItem('sites')!=null) ? JSON.parse(localStorage.getItem('sites'))[0].label : "");
    const [homeOptions, setHomeOptions] = useState(JSON.parse(localStorage.getItem('sites')));
    function siteChanged(siteName) {
        setHomeSelection(siteName);
    }

    const HomeSelection = (props) => {   
        return <DropSelect options={homeOptions} selectedVal={homeSelection} changeHandler={siteChanged} />
    }

    return (
        <div>
            <p>Home</p> 
            <HomeSelection />
            <br />
        </div>
    );
}

export default HomeSelector;