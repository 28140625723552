import React, { Component } from "react";
import { Router } from "@reach/router";
import { Chart } from "react-google-charts";
import axios from 'axios';
import firebase from 'firebase';


export default class settings extends Component {

  state = {
    residents: []
  }

  componentDidMount () {
    axios.get("http://arquella.co.uk/AIDA/api/care/residents/read.php")
    .then(response => {
      this.setState({residents: response.data.residents})
    });
  }
  

  render() {

    return (

        <div>
          <p>Site configuration</p>
          <DropdownList residents={this.state.residents} />
          <BeaconList />
        </div>

    );
  }
}


function DropdownList(props) {
  return (
    <div>
        {props.residents.map((resident, index)=>(
          <div id={index} key={index}>{resident.full_name}</div>
        ))}
    </div>
  );  
}




class BeaconList extends Component {
    
  state = {beaconConfig: []}
  
  componentDidMount() {
 
   firebase.database().ref("testbeaconConfig").on("value", snapshot => {
      let tcalls = [];
      console.log("before");
      snapshot.forEach(snap => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          tcalls.push(snap.val());
      }); 
      this.setState({ beaconConfig: tcalls });
    });
    
}

  render() {
      
      return (
          <div>
            <p>Beacon configuration</p>
            
            <ConfigSelection config={this.state.beaconConfig} />   
          </div>
      );
      
  }
  
}


function ConfigSelection(props) {
  return (
    <div>
        {props.config.map((config, index)=>(
          <div key={index}>
          <span>{config.beaconId}</span>
          <span>{config.beaconName}</span>
          </div>
        ))}
    </div>
  );
}