import React from "react";
import IMG_TEARDROP from '../../images/icons/aq_teardrop.png'

const TearDrop=(props)=>{

    const tdWidth = (100 - props.width) + "%";

    const containerStyle = {
        display: "flex",
        flexDirection: "row",
        //verticalAlign: "middle"
        alignItems: "center",
        zIndex: "10",
        position:"relative",
        left: tdWidth,
    };
    
    const imageStyle = {width:"2.5em"};
    
    const textContainerStyle = {
        border: "1px solid #4cc1bd",
        height: "80%",
        paddingLeft: "25px",
        paddingRight: "1em",
        left: "-20px",
        position: "relative",
        zIndex: "9",
        borderRadius: "15px"
    };
    
    const textStyle={
        fontSize: "0.7em",
        color: "darkgrey"
    }

    return (

        <div style={containerStyle}>
            <img style={imageStyle} src={IMG_TEARDROP} />
            <div style={textContainerStyle}>
                <div style={textStyle}>{props.content}</div>
            </div>
        </div>

    );
}

export default TearDrop;