import React, {useContext, useState} from 'react';
import './subsection.css';
import GetComments from './Comments/comments';
import Entries from './entries/entries';

const SubSection = (props) => {

    const jsonSubSection = props.data;


    let objEntries = [];

    if (jsonSubSection.SubSection_type == "questions") {objEntries = <Entries entryList={jsonSubSection.SubSection_Entries} />;}
    
    //get comments and loop through
    
    //check what type SubSection_type

    //if questions - loop through entries

    /*
Section_id: "38"
Section_name: "Nutrition and Hydration"
SubSection_Actions: ""
SubSection_Comments: (3) [{…}, {…}, {…}]
jsonSubSection.SubSection_Entries: []
SubSection_Incidents: ""
SubSection_Meals: (2) [{…}, {…}]
SubSection_Measurements: []
SubSection_Sleep: ""
SubSection_id: "50"
SubSection_image: null
SubSection_style: "meals"
SubSection_title: ""
jsonSubSection.SubSection_type: "meals"
comment_title: "Nutrition"
hasComment: "1"
    */

    console.log("SectionType");
    console.log(jsonSubSection.SubSection_type);

    return (
        <div>
            <div>{props.SubSection_title}</div>
            <GetComments 
                title={jsonSubSection.Section_name} 
                comments={jsonSubSection.SubSection_Comments} />
            

            {objEntries}

        </div>
    );
}

export default SubSection;