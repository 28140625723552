import React, { Component } from "react";
//import { Chart } from "react-google-charts";
//import axios from 'axios';
import IMG_CALL from '../../images/resident_summary/calldash.png';
import IMG_EMAIL from '../../images/resident_summary/email.png';

const SupportScreen = () => {

  return (
    <div id="AIDA_NAV_MAIN">
        <div id="AIDA_NAV" className="AIDA_NAV">
        </div>
        <div id="AIDA_NAV_MAIN">
          <div className="centerscreenit">
    <SupportContainer>
      <div className="support_contacts_container">
        <div className="call_support_contact_container">
          <img alt="Call contact" height="80" width="80" id="" src={IMG_CALL} />
          <div>0333 242 7505</div>
        </div>
        <div className="call_support_contact_container">
          <img alt="Call contact" height="80" width="80" id="" src={IMG_EMAIL} />
          <div><a href="mailto:support@arquella.co.uk">support@arquella.co.uk</a></div>
        </div>
      </div> 
    </SupportContainer>
    </div>
    </div>
    </div>
  );

}


const SupportContainer = (props) => {
  return (

  <div className="support_container">
    <div className="support_container_header rowFlexNoWrap">
      <div>For support please get in touch...</div>
    </div>
    <div className="support_container_main">
      {props.children}
    </div>
  </div>
  );
}


export default SupportScreen;