import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import { Label } from "semantic-ui-react";
import BarChart from '../../reports/charts/chartjs/BarChart';

export default class Calls_Per_Hour extends Component {
  
  state = {
    data: []

  }  

  componentDidMount () {
    let sData = {
      "homes": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate,
      "zones": this.props.zones
    };
    
    axios.post("/resident/rpt/calls_per_hour", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data})
      }
      else {
        this.setState({data: [{
          backgroundColor: "#26dcdc",
          data: "",
          label: ""
          }]})
      }
    });
  }

  
  componentDidUpdate(prevProps) {

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
      this.setState({data: []});
      
      let sData = {
        "homes": prevProps.house,
        "startDate": prevProps.startDate,
        "endDate": prevProps.endDate,
        "zones": prevProps.zones
      };
      
      axios.post("/resident/rpt/calls_per_hour", sData)
      .then(response => {
        let resCount = 0;
        try {
          resCount = response.data.length;
        } catch(err) {}
        if (resCount>0) {
          this.setState({data: response.data})
        }
        else {
          this.setState({data: [{
            backgroundColor: "#26dcdc",
            data: "",
            label: ""
            }]});
        }
      });

    }
  }

  getData (){

    return {
            //labels: new Array(this.state.data.length).fill(""),
            labels:["Hour"],
            //labels: this.state.data.map(d=>{return d.label}),
            datasets: this.state.data.length>0 ? this.state.data : null,
            backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
          };

  } 

  render() {

    let ct = 0;

    try {ct = this.state.data.length;}
      catch(err){}


    if (ct>0) {
      return (
        <div>
            <BarChart 
              data={this.getData()} 
              xLabel="Residents"
              yLabel="No. of calls"
              showLegend={false}
              title="Resident Calls"
            />

        </div>
      );
    }
    else {
      
      return (
        <div>
          <div className="report_card">
            <p className="chartTitle">Call Numbers Per Hour</p>
          </div>
        </div>
      );
    }

  }
}
