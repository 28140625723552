import React, {
    //useContext, Component, 
    useState, useRef
} from 'react';
import IMG_Print from '../../images/icons/print_white.png';
//import IMG_Save from '../../images/icons/save_white.png';
//import HomeSelection from '../HomeSelection';
import DropSelect from '../common/DropSelect/DropSelect';
import DropSelectMulti from '../common/DropSelectMulti/DropSelectMulti';
import Moment from 'moment';
//import './Dashboard.css'; 
//import PrintPage from '../common/Export/Print';
import ReactToPrint, {ComponentToPrint, useReactToPrint } from "react-to-print";
//import { CSVLink, CSVDownload } from "react-csv";
//import CallTypeFilter from '../SelectFilters/CallTypeFilter';
//import '../../DemoData/Dashboard.json';
import HomeSelector from './SearchOptions/HomeSelection';



const SearchNav = () => {

    const [homeSelection, setHomeSelection] = useState("");
    

    return(

      <div id="AIDA_NAV_MAIN">
        
                <NavPane>

                <div>
                
                    <HomeSelector callback={setHomeSelection} />


                </div>

                </NavPane>


            </div>
    );


}


const NavPane = (props) => {
    return (
        <div id="AIDA_NAV" className="AIDA_NAV">
            {props.children}
        </div>
    );
}

export default SearchNav;

const getZoneJSON = () => {
    let rtn = [];
    try {
        rtn =JSON.parse(localStorage.getItem('zones'));
    } catch (e) {console.log(localStorage.getItem('zones'));}
    return rtn;
}




const ArqDashboard = (props) => {
    const [zoneOptions, setZoneOptions] = useState(getZoneJSON);
    const [zoneSelections, setZoneSelections] = useState(getZoneJSON);
    const [dateSelection, setDateSelection] = useState([localStorage.getItem("singleSelectedDate")]);
    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
         content: () => componentRef.current,
      });
      
    const [allCalls, setAllCalls] = useState([]);
    const getDateRef = (ref) => {
        let n;
        const options = window.dashDateOptions;
        options.forEach((val) => {
            if (val.label == ref) {
                n = val.value;
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    

    function zoneChanged(selections) {
        setZoneSelections(selections);
    }


    function getValue(changeVal) {
        let rtnVal = "";
        JSON.parse(localStorage.getItem('sites')).forEach((val) => {
            if (val.label == changeVal) {
                rtnVal = val.value;
            }
        });
        return rtnVal;
    }

    function dateChanged(dateVal) {
        
        setDateSelection(dateVal);
    }

    

    const ZoneSelection = (props) => {
        return <DropSelectMulti options={zoneOptions} selections={zoneSelections} filterCall={zoneChanged} />
    }

    const DateSelection = (props) => {   
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const options = [];
        
        let addDate = new Date();
        let dayBack1 = new Date();
        let dayBack2 = new Date();
        let dayBack3 = new Date();
        let dayBack4 = new Date();

        dayBack1.setDate(dayBack1.getDate() - 1);
        dayBack2.setDate(dayBack2.getDate() - 2);
        dayBack3.setDate(dayBack3.getDate() - 3);
        
        options.push({label:'Today', value: addDate});
        options.push({label:'Yesterday', value: dayBack1});
        options.push({label:days[dayBack2.getDay()], value: dayBack2});
        options.push({label:days[dayBack3.getDay()], value: dayBack3});
        //options.push({label:days[dayBack4.getDay()], value: dayBack4});
        
        window.dashDateOptions = options;

        if ((dateSelection == "") && (options.length>0)) {dateChanged(options[0].label)}
        return <DropSelect options={options} selectedVal={dateSelection} changeHandler={dateChanged} />
    }

    const callbackCallType = () => {
    }

    

    const allCallsCallBack = (calls) => {
        setAllCalls(calls);
    }

    
    let keyRef = new Date().toString();
            return (
                
                <div id="AIDA_NAV_MAIN">
        
                <NavPane>
                <div>


{
//                    <p>Call Type</p>
//                    <CallTypeFilter callback={callbackCallType} />
//                    <br />
}
                    <p>Zone</p>
                    <ZoneSelection />
                    <br />

                    <br />
                    <p>Set Time Period</p>
                    <DateSelection />
                                 
                </div>

                    <div className="navbuttons">
                        <br />
                        <br />
                        
                        <div className="save_print">
                            {
                                //<CSVLink data={allCalls}><button className="save"><img alt="Card image" className="save" src={IMG_Save} /></button></CSVLink>
                            }
                            <button onClick={(e) => window.print()} className="print"><img alt="Card image" className="print" src={IMG_Print} /></button>
                        </div>
                    </div>
                </NavPane>


            </div>

            );
}
              
