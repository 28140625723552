import React, {useEffect, useState} from 'react';
import Moment from 'moment';
import DashboardItem from '../../../components/DashboardItem/DashboardItem';
import DashBoardCallContainer from '../../../components/DashBoardCallContainer/DashBoardCallContainer';
import firebase from 'firebase';

const DashSummaryContainer = (props) => {

    const selectedSite = props.filterSite;
    const selectedDate = props.filterDate; //setDate
    const sitesList = JSON.parse(localStorage.getItem("sites"));

    const [displayTitle, setDisplayTitle] = useState("");
    const [care, setCare] = useState([]);
    const [calls, setCalls] = useState([]);
    
    useEffect(()=>{

        let house = getSitevalue(selectedSite);
        getCareData(house);
        getCallData(house, selectedDate);
        setDisplayTitle(selectedDate + "'s Calls");

    },[selectedSite, selectedDate]);
  
    const getCareData = (props) => {

        let careCalls = [];

        careCalls.push("personal");
        careCalls.push("continence");
        careCalls.push("nutandhyd");
        careCalls.push("moving");
        careCalls.push("social");
        careCalls.push("sleep");

        setCare(careCalls);

    }
    
    function getCallData (house, rDate) {

        let dRef = getDateRef(rDate);
        let ref = house + "/" + dRef;
        let tcalls = [];
        let dis = "";
        let panels = JSON.parse(localStorage.getItem("panel_config"));

        const userList = JSON.parse(localStorage.getItem("uMails"));
    
            firebase.database().ref(ref).on("value", snapshot => {
                
                let lastPoint = "";
                let lastType = "";
                let lastZone = "";
                let lastStart = "";
                
                let count = 0;
                    
                snapshot.forEach(snap => {
                
                    console.log("Snapcount: " + count);    
                
                    count = count + 1;
                    
                    let addRecord = true;
                    const r = snap.val();

                    addRecord = isNotDuplicateCall(lastPoint, r.room, lastType, r.callType, lastZone, r.zone, lastStart, r.start);
                    
                    let liveCall = false;
                    
                    if ((r.end!=null) && (r.end!="") && (r.end!="Live")) {liveCall = false;}
                        else {liveCall = true;}
                
                        liveCall = false;
                    if (addRecord) {
                
                            let carer = r.carer;
    
                            if (Array.isArray(userList)) {
    
                                userList.map(u=>{
    
                                    if (u.email == carer) {
                                        carer = u.first_name + " " + u.surname;
                                    }
                                });
                            }
                            
                            let unitID = r.unitId;
                            let rCode = (typeof(unitID)!="undefined") ? unitID.split(".") :  "";
                            let code = "";
                            
                            if (Array.isArray(rCode)) {
                                code = rCode[0];
                            }
                            
                            const fld = {
                                "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                                "Zone" : r.zone,
                                "Resident" : "",
                                "Call Point" : r.room,
                                "Call Type" : r.callType,
                                "Start Time" : r.start,
                                "End Time" : r.end,
                                "Call Duration" : r.duration,
                                "Carer ID" : carer,
                                "Care Delivered" : getCareDelivered(ref, code, rDate, r.start, r.end, care, panels),
                            };
    
                    lastPoint = r.room;
                    lastType = r.callType;
                    lastZone = r.zone;
                    lastStart = r.start;

                    
                    
                    if (calls.length > 0) {
                        
                        let tmpCalls = calls;
                        tmpCalls.push(fld);
                        setCalls(tmpCalls);

                    }
                    else {
                        
                        tcalls.push(fld);

                    }
    

                }
                
                
            });

            setCalls(tcalls);

          });
        
    }

    function getDateRef (ref) {
        
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const options = [];
        
        let addDate = new Date();
        let dayBack1 = new Date();
        let dayBack2 = new Date();
        let dayBack3 = new Date();
        let dayBack4 = new Date();

        dayBack1.setDate(dayBack1.getDate() - 1);
        dayBack2.setDate(dayBack2.getDate() - 2);
        dayBack3.setDate(dayBack3.getDate() - 3);
        
        options.push({label:'Today', value: addDate});
        options.push({label:'Yesterday', value: dayBack1});
        options.push({label:days[dayBack2.getDay()], value: dayBack2});
        options.push({label:days[dayBack3.getDay()], value: dayBack3});
        
        let n = "";
        options.map((o)=>{
            if (o.label == ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;

    }

    function getDateRefDisplay (ref) {
        let n;
        const options = window.dashDateOptions;
        options.forEach((val) => {
            if (val.label == ref) {
                n = val.value;
            }
        });
        let strDate = Date(n);
        return strDate;
    }

    function getSitevalue (siteName) {
        let rtn = "";
        sitesList.map((c)=>{
            console.log(c.label + " :=: " + siteName);
            if (c.label == siteName) {
                console.log("return: " + c.value);
                rtn = c.value;
            }
        });
        return rtn;
    }
    
    function getCalls (ALLCALLS, callType) {
        let RTNCALLS = [];
        
        try {
            RTNCALLS = ALLCALLS.filter(row => row["Call Type"] ? row["Call Type"].includes(callType) : null).map(filteredName => (filteredName));
        } catch (e) {
            console.log("getCalls() - " + e.toString());
        }

        return RTNCALLS; 
    }


    const EmergencyCalls = getCalls(calls, 'Emergency');
    const CallCalls = getCalls(calls, 'Call');
    const AccessoryCalls = getCalls(calls, 'Accessory');
    const AttendanceCalls = getCalls(calls, 'Attendance');
    const AssistanceCalls = getCalls(calls, 'Assistance');

    const dashboardRowContainerStyle = {
        display: "flex",
        flexDirection: "row",
        margin: "2em",
        width: "100%",
        justifyContent: "space-around"
    }

            console.log("RenderMe");
            
            return (

                <DashBoardCallContainer
                                            color="#4cc1bd"
                                            backgroundColor="#dcf5f3"
                                            title={displayTitle}
                                            >
                        
                        <DashboardItem className="DashboarItemButton allCalls" calls={calls} title="All Calls" />
                        <DashboardItem className="DashboarItemButton emg" calls={EmergencyCalls} title="Emergency" />
                        <DashboardItem className="DashboarItemButton accessory" calls={AccessoryCalls}  title="Accessory" />
                        <DashboardItem className="DashboarItemButton assistance" calls={AssistanceCalls} title="Assistance" />
                        <DashboardItem className="DashboarItemButton call" calls={CallCalls} title="Call" />
                        <DashboardItem className="DashboarItemButton attendance" calls={AttendanceCalls} title="Attendance" />

                    </DashBoardCallContainer>

                
            );

  
}

export default DashSummaryContainer;


const isNotDuplicateCall = (lastPoint, curPoint, lastType, curType, lastZone, curZone, lastStart, curStart)=> {
        
    let rtn = true;

    try {
        if ((lastPoint == curPoint) && (lastType == curType) && (lastZone == curZone)) {
            
            const lastStartSplit = lastStart.split(":");
            const startSplit = curStart.split(":");
            
            if ((lastStartSplit[0] == startSplit[0]) && (lastStartSplit[1] == startSplit[1])) {

                const l3 = parseInt(lastStartSplit[2]);
                const s3 = parseInt(startSplit[2]);

                const difference = Math.abs(l3 - s3);

                if ((difference >= -10) && (difference <=10)) {
                    rtn = false;
                }
            }
        }
    } catch (e) {}

    return rtn;
}


const getCareDelivered = (panel, room, dateVal, start, end, careList, panelList) => {
    
    dateVal = Moment(dateVal);
    
    let startTime = buildCareDateTime(dateVal, start);
        startTime = startTime.format("YYYY/MM/DD HH:mm:ss");

    let endTime = buildCareDateTime(dateVal, end);
        endTime = endTime.format("YYYY/MM/DD HH:mm:ss");

    let rtnCare = getCareMatches(careList, panelList, startTime, endTime, room);
    
    if (rtnCare.length > 0) {
    }

    return rtnCare;
}

const buildCareDateTime = (cDate, cTime) => {

    let nTime = Moment(cTime, "HH:mm:ss");

    let nDate = Moment(cDate);
        nDate.set("hour", nTime.format("HH"));
        nDate.set("minute", nTime.format("mm"));
        nDate.set("second", nTime.format("ss"));

    return nDate;
}


function getCareMatches(careList, panelList, startTime, endTime, roomVal) {
    let rtnCare = [];

    if (Array.isArray(careList)) {
        
        careList.map((c,i)=>{

            let careTime = Moment(c.CareDateTime, "YYYY-MM-DD HH:mm:ss.SSSSSS").format("YYYY/MM/DD HH:mm:ss");
                
                if ((careTime>=startTime) && (careTime<=endTime)) {
                   
                    let room = c.Room;

                    if (c.Room == roomVal) {
                        room = c.Room;
                    }
                    else {
                        panelList.map(p=>{

                            if (p.unitId == c.Room) {   
                                room = p.unitName;  
                            }

                        });
                    }    


                        if (room == roomVal) {
                            if (c.CallType == "Comments") {
                                let comment = {
                                    comment: c.CallValue
                                }
                                rtnCare.push(comment);  
                            }
                            else {  
                                rtnCare.push(c.CallType);
                            }
                                
                            if (rtnCare.length > 0) {
                            }
                        }
                 
            }
 
        });
    }
    return rtnCare;

}

