import React, { Component, useEffect, useRef } from "react";
import './journeygraph.css';
import JourneyGraph, {ShowBreakdown} from './JourneyGraph';


export default class Journey extends React.Component {

  state = {
    showBreakdown: false
  }

  data = this.props.data;

  colours = {
    "Call": "orange",
    "Emergency": "red",
    "Attendance": "green",
    "Assistance": "yellow",
    "end":"gray"
  };

  cellStyle = { 
    width:"10%" 
    
  };

  thStyle = {
      backgroundColor: "darkgray",
      color: "white",
      fontSize: "0.75em",
      fontWeight: "normal",
      width:"10%",
      textAlign: "center",
      verticalAlign: "middle"
  }

  
  thStyleChart = {
    backgroundColor: "darkgray",
    color: "white",
    fontSize: "0.75em",
    fontWeight: "normal",
    textAlign: "center"
//    width:"10%"
  }

  tdStyle = {
    backgroundColor: "white",
    color: "darkgray",
    fontSize: "0.8em",
    fontWeight: "normal",
    //paddingTop: "1.6em",
    textAlign: "-webkit-right",
    verticalAlign: "middle",
    textAlign: "center"
  }

  JourneyRow = ({data})=> {

    return (  <tr>
                <td style={this.tdStyle}>{data.date}</td>
                <td style={this.tdStyle}>{data.location}</td>
                <td style={this.tdStyle}>{data.start_time}</td>
                <td style={this.tdStyle}><JourneyGraph calls={data.calls} showBreakdown={this.state.showBreakdown} /></td>
                <td style={this.tdStyle}>{data.end_time}</td>
                <td style={this.tdStyle}>{data.duration}</td>
            {//    <td style={this.tdStyle} onClick={e=>{this.setState({showBreakdown:!this.state.showBreakdown})}}>+</td>
            }
            </tr>
    );

  }


  render () {
   
    return (
      <div>
        <table style={{width:"100%"}}>
        
          <thead>
          <tr>
            <th style={this.thStyle}>Date</th>
            <th style={this.thStyle}>Location</th>
            <th style={this.thStyle}>Start Time</th>
            <th style={this.thStyleChart}>Call Structure</th>
            <th style={this.thStyle}>End Time</th>
            <th style={this.thStyle}>Duration</th>
{//            <th style={this.thStyle}>Details</th>
}
          </tr>
          </thead>
          <tbody>
          {this.data.map(d=><this.JourneyRow data={d} showBreakdown={this.state.showBreakdown} />)}
          
          </tbody>

</table>
      </div>
    );
  }
}
  


  let demodata = [
    {
      title: '12:54',
      date: "12/03/2021",
      start_time: "12:54",
      end_time: "13:14",
      location: "Bedroom 4",
      duration: "00:20:00",
      calls:[
        {
          caption: "",
          type: "Emergency",
          start: "12:54",
          end: "12:56",
          duration: 2,
          carer: {
            name: "Jayne Rowbotham",
            cid: "1234"
          }
        },
        {
          caption: "",
          type: "Assistance",
          start: "12:56",
          end: "12:59",
          duration: 3
        },
        {
          caption: "",
          type: "Attendance",
          start: "12:59",
          end: "13:08",
          duration: 9
        },
        {
          caption: "end",
          type: "",
          start: "",
          end: "",
          duration: 2
        }
      ]
    },
    
    {
      title: '10:50',
      date: "29/06/2021",
      start_time: "10:50",
      end_time: "10:59",
      location: "Bedroom 4",
      duration: "00:09:00",
      calls:[
        {
          caption: "",
          type: "Emergency",
          start: "10:50",
          end: "10:51",
          duration: 2,
          carer: {
            name: "Jayne Rowbotham",
            cid: "1234"
          }
        },
        {
          caption: "",
          type: "Attendance",
          start: "10:51",
          end: "10:59",
          duration: 12
        },
        {
          caption: "end",
          type: "",
          start: "",
          end: "",
          duration: 2
        }
      ]
    },

    {
      title: '12:54',
      date: "12/03/2021",
      start_time: "12:54",
      end_time: "13:14",
      location: "Bedroom 4",
      duration: "00:20:00",
      calls:[
        {
          caption: "",
          type: "Emergency",
          start: "12:54",
          end: "12:56",
          duration: 5,
          carer: {
            name: "Jayne Rowbotham",
            cid: "1234"
          }
        },
        {
          caption: "",
          type: "Assistance",
          start: "12:56",
          end: "12:59",
          duration: 8
        },
        {
          caption: "",
          type: "Attendance",
          start: "12:59",
          end: "13:08",
          duration: 1
        },
        {
          caption: "end",
          type: "",
          start: "",
          end: "",
          duration: 2
        }
      ]
    },

  ];
