import React, {useContext, useState} from 'react';
import './liquids.css';

const Liquids = (props) => {
    const entries = props.entryList;
    
    let contextList = [];
    let fluidTotal = 0;

    entries.map(entry => {

        if (contextList.indexOf(entry.Entry_Context)==-1) {
            contextList.push(entry.Entry_Context);
        }    

        if (isNaN(entry.Entry_Value) == false) {
            fluidTotal = parseInt(parseInt(fluidTotal) + parseInt(entry.Entry_Value));
        }

    });

    let data = contextList.map((e,i)=> <FilterEntries 
                                        key={i}
                                        value={e} 
                                        entries={entries} />);

    return (
        <div>
            {data}
            <div className="liquid_total_field_container">
                <div className="liquid_total_field_label">Total:</div>
                <div className="liquid_total_field">{fluidTotal}</div>
            </div>
     
        </div>
    );
}

const FilterEntries = (props) => {
    return (
        <div>
            {props.entries.map((e)=> 
                (props.value==e.Entry_Context)
                ? <Entry eData={e} />
                : null
            )}
        </div>
    );
}

const GetMealInputVal = (props) =>{
    return (
        <input
            id={props.id}
            className="cp_liquid_title"
            value={props.value}
        />

    );
}

const Entry = (props) => {
    const entry = props.eData;


        return <div
                    className="liquid__field_container"
                ><GetMealInputVal 
                            id={entry.Entry_ID}                                            
                            value={entry.Entry_Name}
                            />
                            <input 
                                className="cp_liquid_value"
                                id={entry.Entry_ID}                                            
                                value={entry.Entry_Value}
                            />
                            </div>;    


}


export default Liquids;