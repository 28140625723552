import React, {
    //useContext, 
    useState} from 'react';
import './actioninc.css';

const ActionSummary = (props) => {

    const [stateData, setStateData] = useState({
        actions: props.actions ? props.actions : [],
        incidents: props.incidents ? props.incidents : []
    });
    
        
    return(
        
        <table>
            <tbody>   
                {
                    stateData.actions.map((a,i)=>{
                        return <Action key={i} data={a}/>
                    })
                }
            </tbody>
        </table>
            
    );

}

const Action = (props) => {
 
    const [actionData, setActionData] = useState({
        action: props.data.action,
        datetime: props.data.datetime,
        source: props.data.source,
        information: props.data.information,
        followUp: props.data.followUp,
        comments: props.data.comments,

        complete: props.data.complete,
        cpid: props.data.cpid,
        id: props.data.id,
        urgent: props.data.urgent
    });
    
    function updateForm (field, value){
 
        setActionData({
            action: (field=="action") ? value.target.value : props.data.action,
            datetime: (field=="datetime") ? value.target.value : props.data.datetime,
            source: (field=="source") ? value.target.value : props.data.source,
            information: (field=="information") ? value.target.value : props.data.information,
            followUp: (field=="followUp") ? value.target.value : props.data.followUp,
            comments: (field=="comments") ? value.target.value : props.data.comments,
            complete: (field=="complete") ? value.target.value : props.data.complete,
            cpid: (field=="cpid") ? value.target.value : props.data.cpid,
            id: (field=="id") ? value.target.value : props.data.id,
            urgent: (field=="urgent") ? value.target.value : props.data.urgent
        });
        
    }
    
    return (
        <tr>
            {
                Object.keys(actionData).map((k,i)=>{
                  
                    if (k=="action"){
                        return <td><label className="actionsummary">{actionData[k]}</label></td>
                    }  
                  
                  if (k=="comments"){

                    return <td><input 
                                key={i}
                                value={actionData[k]==null ? "" : actionData[k]}
                                onChange={e=>{
                                    updateForm(k, e)
                                }}
                                className="actionsummary"
                            /></td>
                  }
                
                  if (k=="datetime"){

                    return <td><input 
                                key={i}
                                value={actionData[k]==null ? "" : actionData[k]}
                                onChange={e=>{
                                    updateForm(k, e)
                                }}
                                className="actionsummary"
                            /></td>
                  }

                  if (k=="source"){

                    return <td><input 
                                key={i}
                                value={actionData[k]==null ? "" : actionData[k]}
                                onChange={e=>{
                                    updateForm(k, e)
                                }}
                                className="actionsummary"
                            /></td>
                  }

                  if (k=="information"){

                    return <td><input 
                                key={i}
                                value={actionData[k]==null ? "" : actionData[k]}
                                onChange={e=>{
                                    updateForm(k, e)
                                }}
                                className="actionsummary"
                            /></td>
                  }
                })
            }
        </tr>
    );
}


export default ActionSummary;