import React, { Component, useEffect, useRef } from "react";
import * as d3 from "d3";
//import {chart as chart1} from "@d3/histogram";
import demoOne from './demodata1';
import { text } from "d3";
import './graphs.css';
import BarChart from './bar';
import PieChart from './pie';
import ScatterChart from './scatter';
import CareJourneyGraph from './CareJourney/CareJourneyGraph';

const testData = [
  {
    call_time: "08:32:51 am",
    total_duration: "18:00 mins",
    breakdown: [
      {
        call_type: "Emergency",
        start: "12:53:00 pm",
        end: "13:04:00 pm",
        duration: "1",
      },
      {
        call_type: "Attendance",
        start: "12:53:00 pm",
        end: "13:04:00 pm",
        duration: "11",
      },
      {
        call_type: "Assistance",
        start: "12:53:00 pm",
        end: "13:04:00 pm",
        duration: "4",
      },
      {
        call_type: "Attendance",
        start: "12:53:00 pm",
        end: "13:04:00 pm",
        duration: "23",
      }
    ]
  }
];


const Graphs = () => {

  return (
    <div>
      
      <ScatterChart />
      
      <BarChart />
      
      <div style={{width:"500px"}}>
      <CareJourneyGraph data={testData} />
      </div>
  
      <PieChart />
      
    </div>
  );

}



export default Graphs;