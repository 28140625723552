import React, {Component, useState, useContext} from 'react';
import axios from 'axios';
import './teams.css'; 
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import NewUserAdd from  './TeamMemberForm/NewUserAdd/NewUserAdd';
import Scroller from '../components/common/scroller/scroller';
import IMG_NEW_USER from '../images/teams/new_team_member.png';
import Avatar from '../components/Avatar/Avatar';

export default class TeamMembers extends Component {

  state = {
    members: [],
    selectedSite : ""
  }

  componentDidMount() {
    this.getTeamData();
  }

  getTeamData() {
    
    let sites;
    let homeVal = "";
    try {
      sites = JSON.parse(localStorage.getItem("sites"));
      
      if (Array.isArray(sites)) {
        homeVal = sites[0].label;
      }
    } catch(e){}

    
    let dta = {
      homes: homeVal
    }
    axios.post("/team/read", dta)
      .then(res => {
  
        this.setState({ 
          members: res.data, 
          selectedSite : this.state.selectedSite
        });
   
      })
  
  }
  
  render() {
    if (this.state.members.length > 0) 
      {return <ShowPage members={this.state.members} refreshHandler={this.getTeamData} />}
    else 
      {return <div>Loading team members</div>}
  }

}


const ShowPage = props => {

  const [membersState, setMemberState] = useState( { 
  
    members: props.members,
    selectedSite : props.members[0] ? props.members[0].Site_Name : '',
    addable: false,
    adding: false,
    editing: null
  
  });

  const addNewHandler = () => {
    
    setMemberState({
    
      members: membersState.members,
      selectedSite : membersState.selectedSite,
      addable: membersState.addable,
      adding: true,
      editing: null

    });

  }
  
  function getTeamData() {
    let dta = {
      homes: "Arquella Demo"
    }
    axios.post("/team/read", dta)
      .then(res => {
  
        setMemberState({ 
          members: props.members,
          selectedSite : membersState.selectedSite,
          addable: membersState.addable,
          adding: false,
          editing: null
        
        });
      })
  }

  const cancelHandler = () => {

    window.location.href = "/team";
    //getTeamData();

    /*
    setMemberState({
      members: membersState.members,
      selectedSite : membersState.selectedSite,
      addable: membersState.addable,
      adding: false,
      editing: null
      });
      */
    }

  const cancelHandlerEdit = () => {
    setMemberState({
      members: membersState.members,
      selectedSite : membersState.selectedSite,
      addable: membersState.addable,
      adding: false,
      editing: null
      });
  }


  const EditUser = (user) => {
    console.log("Edit User:");
     console.log(user);
    setMemberState({
      
        members: membersState.members,
        selectedSite : membersState.selectedSite,
        addable: membersState.addable,
        adding: false,
        editing: user
      
      });
  }

  const changeSite = (site) => {
    setMemberState({
      members: membersState.members,
      addable: membersState.addable,
      adding: membersState.adding,
      selectedSite:site,
      editing: null
    });
  }


  function TeamList(props) {
    const tMembers = props.members.map(post =>{

      if (post.Site_Name == membersState.selectedSite) {
        return <TeamContainer 
                              key={post.Site_ID}  
                              Site_id={post.Site_ID} 
                              Site_Name={post.Site_Name}
                              Site_Code={post.Site_Code}
                              Users={post.Users}
                />  
      }
      
      });

    return (
      <div style={{width:"100%"}}>
        {tMembers}
      </div>    
    );

}

const TeamContainer = (props) => {

  const [filterVal, setFilterVal] = useState('');
 
  const UserLevels = JSON.parse(localStorage.getItem("userlevels"));
  
  const tUsers = props.Users.map((post, i) =>{
    
    let uLevel = 0;
    
    if (filterVal != "All") {
      UserLevels.map(l=>{
        if (l.Level_Name == filterVal) { uLevel = l.Level; } 
      });
    }
    if (uLevel == 0) {

      return <TeamUser 
              key={i}
              Site_ID={post.Site_ID}
              User_Email={post.User_Email}
              User_First_Name={post.User_First_Name}
              User_Internal={post.User_Internal}
              User_Site_ID={post.User_Site_ID}
              User_Surname={post.User_Surname}
              User_ID={post.User_ID}
              user_details={post}
      />

    }
    else {

      if (post.User_Level == uLevel) {

        return <TeamUser 
                  key={i}
                  Site_ID={post.Site_ID}
                  User_Email={post.User_Email}
                  User_First_Name={post.User_First_Name}
                  User_Internal={post.User_Internal}
                  User_Site_ID={post.User_Site_ID}
                  User_Surname={post.User_Surname}
                  User_ID={post.User_ID}
                  user_details={post}
          />
      }
      
    }
    

  });

  const updateSelected = (selectedVal) => {
    setFilterVal(selectedVal);
  }

  const repList = [
    { name: 'All' },
    { name: 'Administrators' },
    { name: 'Senior' },
    { name: 'Carers' },
    { name: 'Agency' },
  ];


    return (
        <div className="teamContainer">
          <div className="teamuserheader">
          <Scroller 
            updateFunction={updateSelected} 
            list={repList}
            selected="All"
          />
          </div>
          <div className="site_users_container">  
            {tUsers}
          </div>
        </div>
    );

}
  


const TeamUser = (props) => {

    return (

        <button 
                className="user_card"
                onClick={()=>EditUser(props.user_details)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  maxWidth: "330px"
                }}
                >
          <Avatar firstName={props.User_First_Name} surname={props.User_Surname} card={true} />
          <div style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowWrap:"break-word",
                  width:"100%",
                  justifyContent:"space-around",
                  height:"100%",
                  paddingTop:"20px",
                  paddingBottom:"20px",
                  alignItems:"start",
                  paddingRight: "10px"

            }} >
            <span style={{alignSelf: "start"}}>{props.User_First_Name} {props.User_Surname}</span>
            <hr style={{
              alignSelf: "start", 
              width:"100%", 
              color: "white", 
              border: "0px", 
              height:"1px",
              backgroundColor: "white"
              }} />
            <span style={{alignSelf: "start", fontSize: "0.8em"}}>Staff ID: {props.User_Internal}</span>
          </div>

        </button>
    
    );

}


const ItemRenderer = ({ options, title, selectedVal }) => (

<React.Fragment>

<Select
  options={options}
  values={[]}
  itemRenderer={({ item, methods }) => (
    
    <StyledItem>
      {item.disabled ? (
        <div aria-disabled>{item.Site_Name}</div>
      ) : (
        <div onClick={() => changeSite(item.Site_Name)}>
          <input onChange={() => methods.addItem(item)} type="checkbox" checked={(item.Site_Name == selectedVal)} /> {item.Site_Name}
        </div>
      )}
    </StyledItem>
  )}
  onChange={(value) => {
    console.log(`%c > onChange ${title} `, 'background: #555; color: tomato', value);

  }
}
/>
</React.Fragment>
);

ItemRenderer.propTypes = {};

const StyledItem = styled.div`
padding: 10px;
color: #555;
border-radius: 3px;
margin: 3px;
cursor: pointer;
> div {
display: flex;
align-items: center;
}
input {
margin-right: 10px;
}
:hover {
background: #f2f2f2;
}
`;

        
  return (
      <div id="AIDA_NAV_MAIN">
      
        <div id="AIDA_NAV" className="AIDA_NAV">
      
          <button 
                onClick={addNewHandler}
                style={{borderRadius:"6px"}}
              >
                <img src={IMG_NEW_USER} width="40px" />
                <span style={{color:"white", fontSize:"10px"}}>Add new team member</span>
          </button>
          
        </div>  

        <div className="AIDAMAINTCONTENT">
            
          <Modal show={(membersState.adding) || (membersState.editing!=null)} modalClosed={cancelHandler}>

            {(membersState.adding) ? <NewUserAdd cancelHandler={cancelHandler} newUser={true} /> : null}
            {(membersState.editing!=null) ? <NewUserAdd 
                                                          cancelHandler={cancelHandler} 
                                                          newUser={false}
                                                          user_details={membersState.editing}
                                                         /> : null}
            
          </Modal>

          <TeamList members={props.members} />

        </div>

      </div>

  );

}


const Modal = (props) => (
  <div>
    <Backdrop show={props.show} clicked={props.modalClosed}/>
  <div 
    className="Modal"
    style={{
      transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
      opacity: props.show ? '1' : '0',
    }}
    >
    {props.children}
  </div>
  </div>
);


const Backdrop = (props) => (

  props.show ? <div className="Backdrop"
                  onClick={props.clicked}
                ></div> : null

);





