import React, {useEffect, useState} from 'react';
import LiveCallController from '../../../components/LiveCall/LiveCallController';
import DashboardSummaryContainer from './DashSummaryContainer';

const ShowDash = (props) => {

    const selectedSite = props.filterSite;
    const selectedDate = props.filterDate; //setDate

    const dashboardRowContainerStyle = {
        display: "flex",
        flexDirection: "row",
        margin: "2em",
        width: "100%",
        justifyContent: "space-around"
    }
            
            return (
                
                    <div style={dashboardRowContainerStyle} >

                        <DashboardSummaryContainer 
                            filterSite={selectedSite}
                            filterDate={selectedDate}
                        />

  
                        <LiveCallController site={selectedSite} />
                        

                    </div>
                
            );

  
}

export default ShowDash;


