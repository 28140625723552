import React
, {
    //useContext, 
    useState} from 'react';
import {Collapse} from 'react-collapse';
import './Expander.css';

//images
import IMG_ACTION from '../../../images/icons/careplan/action.png';
import IMG_CONTINENCE from '../../../images/icons/careplan/continence.png';
import IMG_MOVING from '../../../images/icons/careplan/moving.png';
import IMG_NUTRITION from '../../../images/icons/careplan/nutrition_hydration.png';
import IMG_SLEEP from '../../../images/icons/careplan/sleep_rest.png';
import IMG_SOCIAL from '../../../images/icons/careplan/social.png';
//import IMG_TISSUE from '../../../images/icons/careplan/tissue.png';
import IMG_PERSONAL from '../../../images/icons/personal_care.png';
import IMG_WELL from '../../../images/icons/wellbeing.png';


const Expander = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(props);
    return (
        <div className="cp_container_item">
        <div className="title_holder"
            onClick={(e)=>{setIsOpen(!isOpen)}}
            >
                <div className="imgDiv">
                <img  alt={props.header} className="responsive" src={GetIconImage(props.header)} />
                </div>
                <div className="titleDiv">
                    <h1 className="action_inc">{props.header}</h1>
                </div>
        </div>
        <Collapse isOpened={isOpen}>
            <div className="cp_section_expander">
                    {props.children}
            </div>
        </Collapse>
        </div>
    );

}



export const ExpanderPopup = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(props);

    const cancelClick=()=>{setIsOpen(!isOpen);}


    return (
        <div>
            
            <span class="add_new_button"
                onClick={(e)=>{setIsOpen(!isOpen)}}
            >{props.toggleKey}</span>
            <div className="cp_section_expander">
                <Modal show={isOpen} modalClosed={cancelClick}>
                    {props.children}
                </Modal>
            </div>
        </div>
    );
}

export const ExpanderPopupNoModal = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(props);
    return (
        <div>
            <span class="add_new_button"
                onClick={(e)=>{setIsOpen(!isOpen)}}
            >{props.toggleKey}</span>
            <div className="cp_section_expander">
                <Collapse isOpened={isOpen}>
                    TEst Adding a new action
                </Collapse>
            </div>
        </div>
    );
}



const Backdrop = (props) => (

    props.show ? <div className="Backdrop"
                    onClick={props.clicked}
                  ></div> : null
  
  );
  const Modal = (props) => (
    <div>
      <Backdrop show={props.show} clicked={props.modalClosed}/>
    <div 
      className="ModalCare"
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
        
      }}
      >
      {props.children}
    </div>
    </div>
  );



const GetIconImage=(imgName)=> {
    if (imgName=="Actions and Incidents") return IMG_ACTION;   
    if (imgName=="Continence") return IMG_CONTINENCE;  
    if (imgName=="Moving") return IMG_MOVING;  
    if (imgName=="Nutrition and Hydration") return IMG_NUTRITION;  
    if (imgName=="Social") return IMG_SOCIAL;  
    if (imgName=="Sleep and Rest") return IMG_SLEEP;
    if (imgName=="Sleeping & Rest") return IMG_SLEEP;  
    if (imgName=="Personal Care") return IMG_PERSONAL;  
    if (imgName=="Well-being") return IMG_WELL;  
    if (imgName=="") return IMG_ACTION;  
    if (imgName=="") return IMG_ACTION;
    console.log("GetIconImage::" + imgName);
    return IMG_ACTION;
     
}






export default Expander;