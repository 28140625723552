import React, { Component } from "react";
import axios from 'axios';



export default class ContinenceDetailsSummary extends Component {
  
  state = {
    data: []
  }  

   getData() {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate
    };
    console.log(sData);
    axios.post("/care/report/dashboard/summary/continence", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.residents.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data.residents})
      }
      else {
        this.setState({data: []})
      }
    });
  }

  componentDidMount () {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate
    };
    
    axios.post("/care/report/dashboard/summary/continence", sData)
    .then(response => {
      this.setState({data: response.data.residents})
      
    });
  }

  componentDidUpdate(prevProps) {

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
    
    let sData = {
      "house": prevProps.house,
      "startDate": prevProps.startDate,
      "endDate": prevProps.endDate,
      "zones": prevProps.zones
    };
    
    axios.post("/care/report/dashboard/summary/continence", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.residents.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data.residents})
      }
      else {
        this.setState({data: []})
      }

    });
    }
  }



  

  render() {

    let ct = 0;

    try {ct = this.state.data.length;}
    catch(err){}


    if (ct>0) {



      return (
        <div>
            
            
          
        </div>
      );
      }else {
        return(
        <div>
           <div>
             <table className="care_summary_table">
              <tr>
                <th>Type</th>
                <th>Comments</th>
                <th>Details</th>
              </tr>
              <tr>
                <td>Urine</td>
                <td>This is a test comment to show what the comments look like. This is a test comment to show what the comments look like.</td>
                <td>Check 2 - CarerID<br />21/02/2020 10:40</td>
              </tr>
             </table>              
          </div>
        </div>
        );
      }
  }
}



function Pie_Chart_Call(props) {

  const data = {
    labels: new Array(props.data.length).fill(""),
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets:props.data,
    backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    colors: ["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    /*
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
    */
  };
  
  const legendOpts = {
    display: true,
    position: 'top',
    fullWidth: true,
    reverse: false,
    labels: {
      fontColor: 'rgb(255, 99, 132)'
    }
  };


    return(
  
  <div className="report_card">
      <p className="chartTitle">Call Type Splits</p>
    
    </div>
  );


}

function getColors(data) {
  return data.slice(1).map((ent)=>titleColor(ent[0]));
}


function titleColor(title) {
  if (title=="Assistance") {return "#fadb3d";}
  if (title=="Accessory") {return "#914499";}
  if (title=="Call") {return "#f5814e";}
  if (title=="Emergency") {return "#ed184a";}
  if (title=="Attendance") {return "#95ca66";}
  console.log("NotFoundColour: " + title); 
  return "";
}


