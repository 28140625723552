import React, {Component} from "react";

const WidgetContainer = (props) => {
  
  return(
      <div className="summary_widget">
          {props.img 
          ? <img alt="Card image" heigh="50" width="50" src={props.img} />
          : null
          }
          <div className="summary_widget_title">{props.title}<br /><br /></div>
          <div className="summary_widget_value">{props.content}</div>
      </div>
    );
}

export default WidgetContainer;
