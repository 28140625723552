import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import {Bar} from 'react-chartjs-2';


export default class Residents_Response_Average extends Component {
  
  state = {
    data: []
  }  

  componentDidMount () {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate,
      "zones": this.props.zones
    };
    axios.post("/resident/rpt/response_times", sData)
    .then(response => {
      
      let resCount = 0;
      try {
        resCount = response.data.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data})
      }
      else {
        this.setState({data: []})
      }

    });
  }

  
  componentDidUpdate(prevProps) {
    
      
    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
      let sData = {
        "house": prevProps.house,
        "startDate": prevProps.startDate,
        "endDate": prevProps.endDate,
        "zones": prevProps.zones
      };
      axios.post("/resident/rpt/response_times", sData)
      .then(response => {
        let resCount = 0;
        try {
          resCount = response.data.length;
        } catch(err) {}
        if (resCount>0) {
          this.setState({data: response.data})
        }
        else {
          this.setState({data: []})
        }
      
      })
      .catch(response => {
        console.log("Response Data error :" + response)
      });
    }
  }

  render() {
    if (this.state.data.length>0) {
      return (
        <div>
          <Response_Chart data={this.state.data} /> 
        </div>
      );
    }
    else {
      return(
      <div>
        <div className="report_card">
          <p className="chartTitle">Response Times</p>
          <p>No data returned within this range</p>

        </div>
      </div>
      );
    }
  }
}



function Response_Chart(props) {
//  props.data

const options = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Time (mins)'
      },
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Period'
      },
    }]
  }
}

const getData= (props) =>  {
}

const data = {
  labels: props.data.map((entry,index) =>entry[0]),
  datasets: [
    {
      label: 'Morning',
      data: props.data.map((entry,index) =>entry[1]),
      backgroundColor: '#aad4e8',
    },
    {
      label: 'Day',
      data: props.data.map((entry,index) =>entry[2]),
      backgroundColor: '#f5814e',
    },
    {
      label: 'Night',
      data: props.data.map((entry,index) =>entry[3]),
      backgroundColor: '#556488',
    },
  ],
  
};


  return(

<div className="report_card">
<p className="chartTitle">Response Times</p>
<Bar

  data={data}
  width={450}
  height={250}
  options={options}

/>

    </div>

    );

}




