import React, {useState} from 'react';
import DropSelect from '../common/DropSelect/DropSelect';
import {getValFromJSON} from '../../tools/tools';

const ResidentFilter = (props) => {

    const getOptions = () => {
        
        const sites = JSON.parse(localStorage.getItem('careResidents'));

        /*
        let sData = {
          "userkey": localStorage.getItem("userKey"),
          //"home" : sites[0].label,
          };
          
        axios.post("/care/active/filteroptions/residents.php", sData)
        .then(response => {

            console.log("ResidentFilter");
            console.log(response);          

            setFilterOptions({
                homeOptions: filterOptions.homeOptions,
                homeSelection: filterOptions.homeSelection,
            });

        });
    */
    }

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: JSON.parse(localStorage.getItem('careResidents')),
        homeSelection: getValFromJSON(localStorage.getItem('careResidents')),
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }

    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default ResidentFilter;