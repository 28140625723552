import React, { Component, useEffect, useRef } from "react";
import * as d3 from "d3";
import { text } from "d3";
import './graphs.css';

/*
var slices = [100, 200, 300, 400];

let scale = d3.scaleLinear()
            .domain([d3.min(slices), d3.max(slices)])
            .range([10, 200]);
*/      

const data = [
    [12, 45],
    [240, 90],
    [280, 75],
    [310, 110],
    [250, 87],
    [260, 97],
    [290, 120]    
];

const ScatterChart = (props) => {  

  const gRef = useRef(null);
  let values = data;
  

  useEffect(()=>{
    
    var barHeight = props.height ? props.height : 400;
    var barWidth = props.width ? props.width : 800;
    var barPadding = props.padding ? props.padding : 5;
    
    const svg = d3.select(gRef.current)
        .append('svg')
        .attr("width", barWidth)
        .attr("height", barHeight)

//create scale

var x_scale = d3.scaleLinear()
                .domain([0, d3.max(data, function(d){
                    return d[0];
                })])
                .range([50, barWidth-100]);


var y_scale = d3.scaleLinear()
                .domain([0, d3.max(data, function(d){
                    return d[1];
                })])
                .range([50, barHeight-100]);

//create circles
    
        svg.selectAll('circle')
        .data(values)
        .enter()
        .append("circle")
        .attr('cx', function(d){
            return x_scale(d[0]);
        })
        .attr('cy', function(d){
            return y_scale(d[1]);
        })
        .attr('r', function(d){
            return d[1]/10;
        })
        .attr('fill', 'grey')
        ;


    //create labels

    svg.selectAll('text')
    .data(values)
    .enter()
    .append('text')
    .text(function(d){
        return d.join(',');
    })
    .attr('x', function(d){
        return x_scale(d[0]);
    })
    .attr('y', function(d){
        return y_scale(d[1]);
    })
    

      
  }, [gRef]);

  return (
    <div>
      <div ref={gRef} className="gBarChart">
        {//<div className="bar"> </div>
        }
      </div>
    </div>
  );

}



const getColourFromIndex = (index) => {

  if (index > 5) {
    const inNum = Math.round((index/6),0);
    index = index - (inNum*5);
  }
  
  if (index==0) {return 'red';}
  if (index==1) {return 'blue';}
  if (index==2) {return 'green';}
  if (index==3) {return 'white';}
  if (index==4) {return 'orange';}
  if (index==5) {return 'yellow';}
  


  return 'purple';
}

export default ScatterChart;