import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import { Label } from "semantic-ui-react";
import '../assist.css';
import BarChart from '../../reports/charts/chartjs/BarChart';

export default class Calls_By_Resident extends Component {
  
  state = {
    data: []
  }  

  componentDidMount () {
    
    let sData = {
      "homes": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate,
      "zones": this.props.zones
    };

    axios.post("/resident/rpt/calls_by_resident", sData)
    .then(res => {
      this.setState({data: res.data})
    })
    .catch(err=> {console.log("Unable to add the resident to the database: " + err + "::");});

  }


  componentDidUpdate(prevProps) {
    
    let house = this.props.house;
    if (prevProps.house) {house = prevProps.house;}

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
    
      let sData = {
        "house": house,
        "startDate": prevProps.startDate,
        "endDate": prevProps.endDate,
        "zones": prevProps.zones
      };
      
      axios.post("/resident/rpt/calls_by_resident", sData)
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data});
      })
      .catch(err=> {console.log("Unable to add the resident to the database: " + err + "::");});
    
    }
  }
  
  getData (){
    return {
            //labels: new Array(this.state.data.length).fill(""),
            labels: ["Calls by resident"],
            datasets: this.state.data.length>0 ? this.state.data : null,
            backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
          };
  } 

  
  render() {

    if (this.state.data) {
      return (
        <div>
          <BarChart 
            data={this.getData()} 
            xLabel="Residents"
            yLabel="No. of calls"
            showLegend={true}
            title="Resident Callss"
          />

        </div>
      );
    }
    else {
      
      return (
        <div>
          <div className="report_card">
            <p className="chartTitle">Resident Calls</p>
            <p>No data returned within this range</p>
          </div>
        </div>
      );
    }
    

  }
}


/*function Third_call(props) {

const data = {
  labels: new Array(props.data.length).fill(""),
  datasets: props.data.length>0 ? props.data : null,
  backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
};


  return(

<div key="2" className="report_card">
  <p className="chartTitle">Resident Calls</p>

<Bar

  data={data}
  width={700}
  height={400}
  options={{
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'No. of calls'
        },
      }],
      xAxes: [{
        scaleLabel: {
          barThickness : 8,
          categorySpacing: 1,
          display: true,
          labelString: 'Residents'
        },
      }]

  }
  }}

/>

    </div>

    );
}

*/
