import React, {useContext, useState} from 'react';
import {Collapse} from 'react-collapse';
import Expander, {ExpanderPopup} from '../Expander/Expander';
import './Sections.css';
import IMG_ACTION from '../../../../images/icons/careplan/action.png';

import axios from 'axios';
import Actions from '../Actions/Actions';
import SubSection from './SubSections/subsection';


const CareplanSections = (props) => {
    
    let actionList = [];
    
    console.log("CareplanSections");
    console.log(props);

    if (props.data) {
        actionList = props.data.map(sec => <Section data={sec} />);
    }
    
    const [isOpen, setIsOpen] = useState(false);
    return actionList;
}


const Section = (props) => {

    const [sectionData, setSectionData] = useState(props.data);
    
    return (
        
        <Expander header={sectionData.section_name} headerIMG={IMG_ACTION}>
        <CareplanContent>
        <CareplanSubSections>
            <CareplanSubSectionActionGrey>
                <CareplanEntriesContainer>

                {
                //sectionData.hasComments == 1 ?? <ShowComments data={sectionData.section_comments} />
                //sectionData.section_name == "Actions and Incidents" ? <Actions data={sectionData.section_subsections} /> : null
                }
                
                {
                //sectionData.section_name == "Actions and Incidents" ?? <Actions data={sectionData.section_subsections} />
            }
                

                <SubSections data={sectionData.section_subsections} />


                    {
//                             {actionList}
    
  //                           <AddNewAction />
                     
                        //<ActionEntry />
}
                </CareplanEntriesContainer>
                </CareplanSubSectionActionGrey>
   
            </CareplanSubSections>
        </CareplanContent>
        </Expander>
        
        );
}


const ShowComments = (props) => {
    return <div>Showing Comments</div>;
}


const SubSections = (props) => props.data.map(sc=><SubSection data={sc} />);


const CareplanContent = (props) =><div className="cp_content">{props.children}</div>; 
const CareplanSubSections = (props) =><div className="cp_subsections">{props.children}</div>
const CareplanSubSectionActionGrey = (props) =><div className="grey" sectype="actions">{props.children}</div>
const CareplanEntriesContainer = (props) =><div className="entries">{props.children}</div>

const ActionEntry = () => {
    return (

        <div className="action_entry">
            <div className="action_top">
                <span>Call GP</span>
                    <div className="action_options">
                        <div className="action_checkbox">
                            <span>Urgent</span>
                            <input className="largeCheck" type="checkbox" />
                        </div>
                        <div className="action_checkbox">
                            <span>Complete</span>
                            <input className="largeCheck" type="checkbox" />
                        </div>
                    </div>
            </div>
            <ActionComments />                         
        </div>

    );
}



const ActionComments = () => {


    return (
        <div class="action_bottom">
            <span>Comments</span>
            <textarea id="Action_Comments_1" 
            //onchange="checkValChange('Action_Comments_1')"
            ></textarea>
        </div>

    );
}


export default CareplanSections;
