import React
//, {useContext, Component, useState, useRef} 
from 'react';
import SimplePopup from '../common/SimplePopup/SimplePopup';
import FadeIn from '../FadeIn/FadeIn';
import DashboardIcon from '../DashboardItem/DashboardIcon';
import UpTimer from '../timers/UpTimer';
import moment from "moment";
import './LiveCall.css';

const LiveCall=(props)=>{

    const dashItemRight = {
        display: "flex",
        flexDirection: "column"
    };

    const dashboard_top = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "167px"
    }  

    const hr = {
        backgroundColor: "white",
        width: "100%",
        border: "0px",
        padding: "0px",
        height: "2px",
        color: "white",
    }

    const indexPos=(pos)=>{
        let rtn = 0;
        if (props.index != 1) {
            rtn = (props.index * -30);
        }

        return rtn + "px";
    }

    const buttonStyle = {
        top: (props.index==0) ? "0px" : (props.index*-25) + "px",
        borderRadius: "16px",
        width: "80%",
        height:  (props.index==props.selectedIndex) ? "145px" : "97px",
        border: "none",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        color: "white",
        backgroundColor: colours[props.callType ? props.callType : ""],
        position: "relative",
        paddingTop: "11px",
        boxShadow: "0px -5px 15px rgba(50, 50, 50, 0.15)"
        // rgb(50 50 50 / 15%) 0px -5px 15px //
    }
    
    const dashboardBottomStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    }

    const detailsRow = () => {
        return (
            <tr>
                <td>&nbsp;</td>
                <td style={{width:"80%", textAlign:"left"}}>{props.room}{(props.zone) ? ", " : ""}{props.zone}</td>
                <td>&nbsp;</td>
            </tr>
        );
    }
    
    const dt = props.startDate.substring(0,10);
    const d = moment(dt + " " + props.startTime, "YYYY-MM-DD HH:mm:ss");
    
    const nowVal = moment();

    let dateVal;
    if (d > nowVal) {
        dateVal = nowVal.format("YYYY/MM/DD HH:mm:ss");
    }
    else {
        dateVal = d.format("YYYY/MM/DD HH:mm:ss");
    }

    let callType = (typeof(callType)=="undefined") ? "" : props.callType;


    return (
        <FadeIn duration="500">
            <button 
                    style={buttonStyle}
                    //className="livecall_button"
            >
                <table style={{width:"90%"}}>
                    <tr>
                        <td style={{width:"10%", textAlign:"left"}}>
                            <DashboardIcon callType={callType}/>
                        </td>

                        <td style={{width:"80%", textAlign:"left"}}>{props.callType}</td>

                        <td style={{width:"10%", textAlign:"right"}}>
                            <UpTimer startTime={dateVal} />
                        </td>
                    </tr>

                    {(props.index==props.selectedIndex) ? detailsRow() : null}    
                
                </table>

            </button>
        </FadeIn>
    );
}

const colours = {
    "All Calls": "#4cc1bd",
    "Emergency": "#EC1848",
    "Call": "#F4804D",
    "Accessory": "#914397",
    "Attendance": "#93C967",
    "Assistance": "#F8DA3C",
};

const icons = {
    "All Calls": "#4cc1bd",
    "Emergency": "#EC1848",
    "Call": "#F4804D",
    "Accessory": "#914397",
    "Attendance": "#93C967",
    "Assistance": "#F8DA3C",
};

export default LiveCall;
