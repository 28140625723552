import React, {useState} from "react";
import Resident from '../../care/Resident/Resident';
import IMG_MENU_DOWN from '../../../images/menu/menu_down_arrow.png';

export default function MainNavBar(props) {
  return (
    <div className="dashMenu">
      <div className="navbar">
        {props.children}        
      </div>
    </div>
  );
}


export const AssistNavOptions = ({selection}) => {
    return (
        <MainNavBar>
            <a className={((selection=="Dashboard")||(selection=="")) ? "header_nav_menu_link_bold" : "header_nav_menu_link"} href="/dashboard">Dashboard</a>
            {
                localStorage.getItem("assist_reporting") 
                ? <ReportDropDown selection={selection} />
                : <ReportSingle selection={selection} />
            }
        </MainNavBar>
    );
}

export const CareNavOptions = () => {
    return (
        <MainNavBar>
            <a className="header_nav_menu_link" href="/care/dashboard">Dashboard</a>
            <div style={{display:"flex", flexDirection:"row"}}><CareReportDropDown /><ReportAdminDropDown /></div>
        </MainNavBar>
    );
}


const ReportSingle = () => <a className="header_nav_menu_link" href="/report">Report</a>;


const CareReportDropDown = () => {
  
  return (  
    <div className="ddropdown">
      <button className="ddropbtn">
        <a className="header_nav_menu_link" href="/care/reports/table">Reports</a>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="ddropdown-content">
        <a href="/care/reports/chart">Report Dashboard</a>
        <a href="/care/reports/table">All Call Data</a>
      </div>
    </div>
  );
} 


const ReportDropDown = ({selection}) => {

  return (  
    <div className="ddropdown">
      <button className="ddropbtn">
        <div style={{display:"flex", flexDirection:"row"}}>
        <a className={(selection=="Reports") ? "header_nav_menu_link_bold" : "header_nav_menu_link"} href="/repgraphs">Reports </a>
        <img style={{display:"inline"}} width="12px;" src={IMG_MENU_DOWN} />
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="ddropdown-content">
        <a className={(selection=="Performance Report") ? "menu-bold" : ""} href="/repgraphs">Performance Report</a>

        <a className={(selection=="Resident Call Data") ? "menu-bold" : ""} href="rep_res_call_data">Resident Call Data</a>
        
        {//
        }       
       <a className={(selection=="All Call Data") ? "menu-bold" : ""} href="/report">All Call Data</a>
      </div>
    </div>
    
  );
} 

const ReportAdminDropDown = () => {

  const [adminData, setAdminData] = useState({
    adding: false
  });

  const cancelHandler=()=>{
    setAdminData({adding:false});
  }

  return (  
    <div className="ddropdown">
      <button className="ddropbtn">
        <a className="header_nav_menu_link" href="/assist">Admin Settings</a>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="ddropdown-content">
        <a
          onClick={c=>{
            setAdminData({adding:true});
          }} 
        >New Resident</a>
      </div>

      <Modal show={adminData.adding} modalClosed={cancelHandler}>
        <Resident 
          cancelHandler={cancelHandler} />
      </Modal>

    </div>    
  );
} 


const Backdrop = (props) => (

  props.show ? <div className="Backdrop"
                  onClick={props.clicked}
                ></div> : null  
);

const Modal = (props) => (
  <div>
    <Backdrop show={props.show} clicked={props.modalClosed}/>
  <div 
    className="ModalCare"
    style={{
      transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
      opacity: props.show ? '1' : '0',
      
    }}
    >
    {props.children}
  </div>
  </div>
);