import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import { Label } from "semantic-ui-react";
import BarChart from '../../reports/charts/chartjs/BarChart';

export default class Emergency_Response_Times extends Component {
  
  state = {
    data: []

  }  

  componentDidMount () {
    
    let sData = {
      "homes": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate,
      "zones": this.props.zones,
      "user" : localStorage.getItem("userKey"),
      "userKey" : localStorage.getItem("userKey")
    };
    
    axios.post("/resident/rpt/emergency_response_times", sData)
    .then(response => {
      let resCount = 0;
      
      try {
        resCount = response.data.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data})
      }
      else {
        this.setState({data: [{
          backgroundColor: "#26dcdc",
          data: "",
          label: ""
          }]})
      }
    });
  }

  
  componentDidUpdate(prevProps) {

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
      this.setState({data: []});
      let sData = {
        "house": prevProps.house,
        "startDate": prevProps.startDate,
        "endDate": prevProps.endDate,
        "zones": prevProps.zones
      };
      
      axios.post("/resident/rpt/emergency_response_times", sData)
      .then(response => {
        let resCount = 0;
        try {
          resCount = response.data.length;
        } catch(err) {}
        if (resCount>0) {
          this.setState({data: response.data})
        }
        else {
          this.setState({data: [{
            backgroundColor: "#26dcdc",
            data: "",
            label: ""
            }]});
        }
      
      });

    }
  }

  getData (){

    return {
        labels: ["Hour of emergency"],  
      //labels: new Array(this.state.data.length).fill(""),
        datasets: this.state.data.length>0 ? this.state.data : null,
        backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    };

  } 

  render() {

    let ct = 0;

    try {
      ct = this.state.data.length;
    }
    catch(err){}


    if (ct>0) {
      return (
        <div>
          <BarChart 
            title="Emergency Response Times"
            xLabel="Period"
            yLabel="Time (mins)"
            data={this.getData()}
          />

        </div>
      );
    }
    else {
      
      return (
        <div>
          <div className="report_card">
          <p className="chartTitle">Emergency Response Times</p>
          </div>
          </div>
      );
    }

  }
}

/*

function Third_call(props) {



const data = {
  labels: new Array(props.data.length).fill(""),
  datasets: props.data.length>0 ? props.data : null,
  backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
};


  return(

<div className="report_card">
  <p className="chartTitle">Emergency Response Times</p>

<Bar

  data={data}
  width={700}
  height={400}
  options={{
    legend: {
      display: false,
    },
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Time (mins)'
        },
      }],
      xAxes: [{
        barThickness : 8,
        categorySpacing: 1,
        maxBarThickness: 200,
        scaleLabel: {
          display: true,
          labelString: 'Period'
        },
      }]

  }
  }}

/>

    </div>

    );
}

*/