import React, 
{
//  Component, 
  useState
//  , useContext 
} from "react";
import axios from 'axios';
import GetAvatar from '../avatar/Avatar';
import Resident from '../Resident/Resident';
/*
import {
  getHomeOptions, 
  getHomeSelection
} from '../../datacollector/datacollector';
*/

export default class CareScreen extends React.Component {

    state = {
      adding: false,
      selectedResident: null,
      posts: [],
      selectedResident: null,
      filters: this.props.filters,
    }
  
  
    componentDidMount(){

      const sites = JSON.parse(localStorage.getItem('sites'));
    
        let sData = {
          "userkey": localStorage.getItem("userKey"),
          "home" : sites[0].label,
          };


        axios.post("/resident/resident_list.php", sData)
        .then(response => {
          this.setState({
            dbConnected: true,
            posts: response.data.residents ? response.data.residents : []
          });
        });
    }
  
    cancelHandler() {
      this.setState({adding: false});
    }
  
    render() {
  
      return (
        <div>
  
          <div className="toplevelcare">
            <div className="site_headers"></div>
            <div id="aida_care_resident_card_container" className="CONTENT_MAIN">

              <Posts 
                posts={this.state.posts} 
                filters={this.state.filters}  
              />

            </div>
          </div>
      
      </div>
  
  
      );
    }
  
  }


  const ResidentCard = (props) => {

    const [showModal, setShowModal] = useState({show:false});
  
    const cancelHandler = () => {
      setShowModal({show:false});
    }

  return (
    <div>

    <Modal show={showModal.show} modalClosed={cancelHandler} >
  
      <Resident selection={props.data} cancelHandler={cancelHandler} />

    </Modal>


    <button className="resident_card" onClick={e => {
      setShowModal({show:true});
    }}>

    <GetAvatar firstname={props.data.res_first_name} surname={props.data.res_surname} />

      <div className="resident_card_details">
        <div className="top_card">
          <div className="left_card">
            <span className="resident_name">{props.data.res_first_name} {props.data.res_surname}</span>
            {
//            <span className="resident_uin">UIN: {props.data.res_UIN}</span>
            }
          </div>
        </div>
        <hr className="dashboard_item" />
        <div className="top_card">
          <div className="left_card">
            <span className="resident_room">{props.data.rm_name}</span>
          </div>
        </div>
      </div>
    </button>

    </div>
    
  );
}


  const Posts = (props) => {
    let filters = props.filters;

    return (
        <div>
        {
        
        props.posts.map((post, i) => {
          let filterPass = false;
          
          if (filters.home==post.site_name) {
            filterPass = true;
          }
          
          if (1==1) {
            return (<ResidentCard
              key={i}
              data={post}
              residentID={post.Resident_id} 
              title={post.full_name}
              uin={post.uin}
              dob={post.Resident_DOB}
              room={post.Resident_Room}
              onClick={e=>{console.log("cardclicker");}}
              />);
      
          }
        
        })
        }
        </div>
    );

  }



  
const Backdrop = (props) => (

  props.show ? <div className="Backdrop"
                  onClick={props.clicked}
                ></div> : null  
);

const Modal = (props) => (
  <div>
    <Backdrop show={props.show} clicked={props.modalClosed}/>
  <div 
    className="ModalCare"
    style={{
      transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
      opacity: props.show ? '1' : '0',
      
    }}
    >
    {props.children}
  </div>
  </div>
);