import React, {useEffect, useState} from 'react';
import SimplePopup from '../common/SimplePopup/SimplePopup';
import DataTable from '../common/DataTable/DataTable';
import IMG_Save from '../../images/icons/save_white.png';
import { CSVLink } from "react-csv";
import DashboardIcon from './DashboardIcon';

const DashboardItem = (props) => {

    const [showTable, setShowTable] = useState(false);
  
    const calls = props.calls;
/*
    const [calls, setCalls] = useState([]);
    const load = useEffect(() => {
        setCalls(props.calls);
    });
*/

    const cancelHandler = () => {
        setShowTable(false);
    }

    const showPopup = () => {
        setShowTable(true);
    }

    function getAverage(totalVal, duration) {
        let rtnVal = "";
        let av = parseInt(duration/totalVal);
        if (av.toString != "NaN") {
            try {
                let dt = new Date(null);
                dt.setSeconds(av);
                rtnVal = "00" + dt.toString().substr(18, 6);
            } catch(err){}
        }
        return rtnVal;      
    }

    function addDuration(oldVal, addVal) {
        let rtnVal = parseInt(oldVal);
        try {
            rtnVal = rtnVal + parseInt(addVal);
        } catch(err){console.log("Error adding to duration:(" + addVal + ") - " + err);}
        return rtnVal;
    }
    
    const GetPopup = ({calls, showTable, title, cancelHandler, careCalls}) => {

        if (Array.isArray(calls)) {

            return(

                <SimplePopup show={showTable} cancelHandler={cancelHandler}>

                    <div className="popupStyle">
                    
                        <br />
                        <h1>{title}</h1>
                        <br />
                        
                        <CSVLink data={calls}>
                            <button className="save">
                                <img alt="Card image" className="save" src={IMG_Save} />
                            </button>
                        </CSVLink>
                        <br />
                
                        <DataTable data={calls} careData={careCalls} />    
                
                        <br />
                        <br />
                        <div>
                            <button
                                onClick={cancelHandler}
                                >Cancel</button>
                        </div>
                        
                        <br />
                        <br />
                        <br />
                    
                    </div>

                </SimplePopup>

            ); 
        }
        else {
            return (<div></div>);
        }

    }
   
        const cll = calls;

        console.log("size:" + cll.length);

        if (props.title == "All Calls") {

        console.log("ALLTHECalls");
        console.log(cll);
        
        }
        let intTotal = 0;
        let intAvr = 0;
        let allAvr = 0;
        
        if (cll.length > 0) {
            cll.map(data => {
                let isAttendance = false;

                if (props.title.toString()=="All Calls") {

                    //if current record is false
                }

                if (!isAttendance) {
                    if (data != null) {
                        if (data["Call Duration"] != "Live") {
                            intTotal++;
                            intAvr = addDuration(intAvr, data["Call Duration"]);
                        }
                    }
                }
        
            });
            allAvr = getAverage(intTotal, intAvr);
        }
        



        const buttonStyle = {
            borderRadius: "16px",
            width: "90%",
            height: "85px",
            border: "none",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "white",
            backgroundColor: colours[props.title],
            marginTop: "0.5em",
            marginBottom: "0.5em",
        }
        



        return (
            <div style={{width:"90%", alignSelf:"center" }}>
                <button style={buttonStyle} onClick={()=> {
                    if (Array.isArray(props.calls)) {
                        showPopup();
                    }
                }}>
                    
                    <DashboardIcon callType={props.title.toString()} />
                    <div style={dashItemRight}>
                        <div style={dashboard_top}>
                            <span>{props.title}</span>
                            <div>{intTotal}</div>
                        </div>    
                        <hr style={hr} />
                        <div style={dashboardBottomStyle}>
                            <span>Average Call Length</span>
                            <div id="allCalls_avr" className="" >{allAvr}</div>
                        </div>
                    </div>
            </button>

            <GetPopup 
                calls={props.calls} 
                showTable={showTable} 
                title={props.title}
                cancelHandler={cancelHandler}
                careCalls={props.careCalls}
            ></GetPopup>
            
            </div>       
        );
    
}

const colours = {
    "All Calls": "#4cc1bd",
    "Emergency": "#EC1848",
    "Call": "#F4804D",
    "Accessory": "#914397",
    "Attendance": "#93C967",
    "Assistance": "#F8DA3C",
};


const dashItemRight = {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    justifyContent: "space-around",
    height: "100%"
};

const dashboard_top = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
}  

const hr = {
    backgroundColor: "white",
    width: "100%",
    border: "0px",
    padding: "0px",
    height: "2px",
    color: "white",
    marginBottom:"0px",
    marginTop:"0px"
}

const dashboardBottomStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
}

export default DashboardItem;

