import React, {Component, useState, useContext } from "react";
import axios from 'axios';


export function getHomeOptions() {
    return JSON.parse(localStorage.getItem('sites'));
}

export function getHomeSelection(){
    return JSON.parse(localStorage.getItem('sites'))[0].label;
}

