import React
//, {useContext} 
from 'react';
import './ResidentForm.css';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import { isDate } from 'moment';
//import { useHistory } from "react-router-dom";



const initialStateTemplate = {

    name: "",
    surname: "",
    preferredName: "",
    dob: "",
    age: "",
    room: "",
    nameError: "",
    surnameError: "",
    preferredNameError: "",
    dobError: "",
    ageError: "",
    roomError: ""
    
}


export default class ResidentForm extends React.Component {

    state = this.props.initialState ? this.props.initialState : initialStateTemplate;

    handleChange = event => {
      
      const isCheckBox = event.target.type === "checkbox";
        
      ///calculate the age and update in form
      
      this.setState({
         [event.target.name]:isCheckBox
            ? event.target.checked
            : event.target.value
        });

    }

    addResidentHandler() {

      let sData = {
        "first_name": this.state.name,
        "surname": this.state.surname,
        "preferredName": this.state.preferredName,
        "dob": this.state.dob,
        "siteID": "10",
        //"age": event.target.elements.age.value,
        "room": this.state.room
      };
      axios.post("/resident/add.php", sData)
      .then(res => {
        
        this.state.onCancelHandler();
        
      })
      .catch(err=> {console.log("Unable to add the resident to the database: " + err);});
        
    }

    updateDate = (dt) => {
      
      if (isDate(dt)) {

        var moment = require('moment');
        var startDate = new moment(dt);
        var endDate = new moment();
        var ageCalc = endDate.diff(startDate, "years");
        
          
        this.setState({
          dob: dt,
          age: ageCalc,
        });

  
      }

    }


    validate = () => {

        let nameError = "";
        let emailError = "";
        let passwordError = "";
        
        //if (!this.state.email.includes("@")) {
        //    emailError = "invalid email";
        //}

        //if (emailError) {
        //    this.setState({ emailError });
        //    return false;
        //}

        return true;
/*
        if (!this.state.email.includes("@")) {
            emailError = "invalid email";
        }

        if (!this.state.email.includes("@")) {
            passwordError = "invalid email";
        }
*/  
    }

    

    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            console.log(this.state);
        }
        
    }


    render() {

      var moment = require('moment');
      var dtDOB = new Date(this.state.dob);
      
        return(
            <div>

<div className="resident_detail_content">
      <form id="residentForm" onSubmit={this.handleSubmit}>
        <div className="resident_details_basic_section">
        <table>
          <tbody>
          <tr>
            <td><b>Name:</b></td>
            <td>
            <input 
              className="form"
                            name="name"
                            //placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        {
                        this.state.nameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.nameError}</div>)
                        : null
                        }
                
            </td>
          </tr>
          <tr>
            <td><b>Surname:</b></td>
            <td>

                <input 
                  className="form"
                    name="surname"
                    //placeholder="email"
                    value={this.state.surname}
                    onChange={this.handleChange}
                />
                {
                    this.state.surnameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.surnameError}</div>)
                    : null
                }


            </td>
          </tr>
          <tr>
            <td><b>Preferred Name:</b></td>
            <td>

            <input
                className="form" 
                            name="preferredName"
                            //placeholder="Name"
                            value={this.state.preferredName}
                            onChange={this.handleChange}
                        />
                        {
                        this.state.preferredNameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.preferredNameError}</div>)
                        : null
                        }

            </td>
          </tr>
          <tr>
            <td><b>D.O.B:</b></td>
            <td><DatePicker
                    id="dob" 
                    className="forminput" 
                    name="dob"
                    //placeholder="Name"
                    onChange={this.updateDate}
                    value={isDate(this.state.dob) ? new Date(dtDOB) : null}
                    />
            </td>
          </tr>
          {
/*
  <tr>
            <td><b>Age:</b></td>
            <td>
                <input 
                    className="form"
                    name="age"
                            //placeholder="Name"
                            value={this.state.age}
                            onChange={this.handleChange}
                          //  disabled
                       //     className="agefield"
                        />
                        {
                        this.state.ageError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.ageError}</div>)
                        : null
                        }
            </td>
          </tr>
*/
}
          <tr>
            <td><b>Room:</b></td>
            <td>
                
                <input 
                  className="form"
                            name="room"
                            //placeholder="Name"
                            value={this.state.room}
                            onChange={this.handleChange}
                            
                        />
                        {
                        this.state.roomError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.roomError}</div>)
                        : null
                        }

            </td>
          </tr>
          </tbody>
        </table>
        </div>

        <div className="buttons_spacing">
    
          <button onClick={c=>{
            this.addResidentHandler();
          }}>Save</button>
          <button onClick={this.state.onCancelHandler}>Cancel</button>

        </div>
        </form>
      </div>
  
    </div>

        );

    }

}


