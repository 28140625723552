import React, {useContext, useState} from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import Actions from './Actions/Actions';
import './careplan.css';
import CareplanSections from './Sections/Sections';
import BottomBanner from './BottomBanner/BottomBanner';

class GetCareplan extends React.Component  {
    
    state = {
        careplan: []
    };

    componentDidMount () {
        
        let sData = {
            "userkey": localStorage.getItem("userEmail"),
            "careplanID": "10"
        };
        
        axios.post("/care/careplan/read", sData)
        .then(response => {
          this.setState({careplan: response.data})
        });
        
    }

    render() {

        if ((Array.isArray(this.state.careplan.length))|| (this.state.careplan==0)) {
            return (
                <div>Opening CarePlan</div>
            );
        }
        else {

            localStorage.setItem('resident_details', JSON.stringify(this.state.careplan.resident_details[0]));
            localStorage.setItem('careplanID', JSON.stringify(this.state.careplan.careplan[0].id));

            return (
                <div>
                    <div className="careplan_main_container">
                        <Careplan data={this.state.careplan} />
                    </div>
                    <BottomBanner />
                </div>
            );
        }
    
    }

}


const Careplan = (props) => {
    
    const [careData, setCareState] = useState(props.data);
        
    return (
        <div className="CareplanContainer">
            {
            //<Actions data={careData.actions} />
            }
            <CareplanSections data={careData.sections} />
        </div>
    );

}

export default GetCareplan;
