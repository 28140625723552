import React, {useContext, Component, useState, useRef} from 'react';
import DropSelect from '../common/DropSelect/DropSelect';

const DatePeriodFilter = (props) => {

    const o = [
        {"label":"Today","value":"Today"},
        {"label":"Yesterday","value":"Yesterday"},
        {"label":"This Week","value":"This Week"},
    ];

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: o,
        homeSelection: "Today",
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }

    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default DatePeriodFilter;