import React, {Component, useState, useContext } from "react";
import GetAvatar from '../avatar/Avatar';
import './resident.css';

const ResidentHeader = (props) => {

    if (props.selection) {
      return(
        <div className="resident_detail_header">
  
          <GetAvatar firstname={props.selection ? props.selection.res_first_name : ''} surname={props.selection ? props.selection.res_surname : ''} />
  
          <div>{props.selection ? props.selection.res_first_name : ''} {props.selection ? props.selection.res_surname : ''}</div>
          
          <div></div>
  
        </div>
      );
    }
    else {
  
      return(
        <div className="resident_detail_header">New Resident</div>
        );
  
    }
  }
  


  
export const ResidentHeaderManual = (props) => {

  return(
      <div className="care_resident_detail_header">

        <GetAvatar firstname={props.firstName} surname={props.surname} />

        <div>{props.firstName} {props.surname}</div>
        
        <div></div>

      </div>
    );
  
  
}


  export default ResidentHeader;