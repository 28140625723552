
import React, {useContext, Component, useState, useRef} from 'react';
import Calendar from 'react-calendar';
import './calendar.css';
import moment from 'moment'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import IMG_LOGO from '../../images/whiteLogo.png';

export default class CareCalendarWeb extends React.Component {

  state = {
    
    data : [],
    selectedDate : new Date(),

  }

  changeDate = (newval) => {
    this.setState({selectedDate: newval});
  }

  render() {

    return(

      <div>
        <div id="AIDA_CONTENT">
          <div className="carecalendarframe">
            <div className="rowcalendar">
            <div>
            <Calendar
              onChange={this.changeDate}
              value={this.state.selectedDate}
            />
            </div>
            <div className="PanelMain">
              <ShowEvents date={this.state.selectedDate} data={this.state.data} />
            </div>
            </div>
          </div>
        </div>
      </div>

    );

  }

}



const ShowEvents = (props) => {

  const dta = props.data;

  
  let narr = [];
  console.log(props.date);
  dta.map((e) => {
  
    let strDate = moment(props.date).format('DD');
    
    if (strDate == 1) {
      if (e.duration > 0) {narr.push(e);}
    }
    if (strDate == 2) {
      if (e.duration > 100) {narr.push(e);}
    }
    if (strDate == 3) {
      if (e.duration > 200) {narr.push(e);}
    }
    if (strDate == 4) {
      if (e.duration > 300) {narr.push(e);}
    }
    if (strDate == 5) {
      if (e.duration > 400) {narr.push(e);}
    }
    if (strDate == 6) {
      if (e.duration > 500) {narr.push(e);}
    }
    if (strDate == 7) {
      if (e.duration > 600) {narr.push(e);}
    }
    if (strDate == 8) {
      if (e.duration > 700) {narr.push(e);}
    }
    if (strDate == 9) {
      if (e.duration > 800) {narr.push(e);}
    }
    if (strDate == 10) {
      if (e.duration > 900) {narr.push(e);}
    }
    if (strDate == 11) {
      if (e.duration > 1000) {narr.push(e);}
    }
    if (strDate == 12) {
      if (e.duration > 1100) {narr.push(e);}
    }
    if (strDate == 13) {
      if (e.duration > 1200) {narr.push(e);}
    }
    if (strDate == 14) {
      if (e.duration > 1300) {narr.push(e);}
    }
    if (strDate == 15) {
      if (e.duration > 1400) {narr.push(e);}
    }
    if (strDate == 16) {
      if (e.duration > 1500) {narr.push(e);}
    }
    if (strDate == 17) {
      if (e.duration > 1600) {narr.push(e);}  
    }
    
  });        



  const entries = narr.map((e) => <ShowEntry data={e} />);        

  return (
    <div>
      
      <VerticalTimeline>
      {entries}
      </VerticalTimeline>



    </div>
  );
}

const ShowEntry = (props) => {

  const d = props.data;

/*
duration: 870
end_date: "2021-03-16 23:55:38.000000"
id: 663063
room: "Bedroom 34"
siteid: 6
start_date: "2021-03-16 23:41:08.000000"
type: "Accessory"
unit: null
zone:
<td>{d.start_date}</td>
 
<tr className={d.type}>
      <td>{d.type}</td>
      <td>{d.room}</td>
      <td>{d.duration}</td>
*/

  let bCol = "";

  return (
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#dcf5f3', color: '#4cc1bd' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date={d.start_date}
    iconStyle={{ background: '#4cc1bd', color: '#fff' }}
    icon={<img  alt="Arquella Logo" className="header_logo" src={IMG_LOGO} />}
  >
    <h3 className="vertical-timeline-element-title">{d.type}</h3>
    <h4 className="vertical-timeline-element-subtitle">{d.room} ({d.zone})</h4>
    <p>
      <b>Type:</b>{d.type}<br />
      <b>Room:</b>{d.room}<br />
      <b>Zone:</b>{d.zone}<br />
    </p>
  </VerticalTimelineElement>
 
 
 
  );
}


/*

 <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2011 - present"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
   // icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Creative Director</h3>
    <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
    <p>
      Creative Direction, User Experience, Visual Design, Project Management, Team Leading
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2010 - 2011"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
   // icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Art Director</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      Creative Direction, User Experience, Visual Design, SEO, Online Marketing
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2008 - 2010"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
//    icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2006 - 2008"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  //  icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Web Designer</h3>
    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
    <p>
      User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="April 2013"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
  //  icon={<SchoolIcon />}
  >
    <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
    <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
    <p>
      Strategy, Social Media
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2012"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
   // icon={<SchoolIcon />}
  >
    <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
    <p>
      Creative Direction, User Experience, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2002 - 2006"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
   // icon={<SchoolIcon />}
  >
    <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
    <p>
      Creative Direction, Visual Design
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
   // icon={<StarIcon />}
  />

*/



const tData = [


  {"id": 663063, "room": "Bedroom 34", "type": "Shower", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 870, "end_date": "2021-03-16 23:55:38.000000", "start_date": "2021-03-16 23:41:08.000000"},
{"id": 663062, "room": "Bedroom 25", "type": "Toilet", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 187, "end_date": "2021-03-16 23:36:47.000000", "start_date": "2021-03-16 23:33:40.000000"},
{"id": 663061, "room": "Bedroom 37", "type": "Call", "unit": null, "zone": "First floor", "siteid": 6, "duration": 224, "end_date": "2021-03-16 22:46:41.000000", "start_date": "2021-03-16 22:42:57.000000"},
{"id": 663060, "room": "Bedroom 37", "type": "Call", "unit": null, "zone": "First floor", "siteid": 6, "duration": 238, "end_date": "2021-03-16 22:40:42.000000", "start_date": "2021-03-16 22:36:43.000000"},
{"id": 663059, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 36, "end_date": "2021-03-16 22:16:16.000000", "start_date": "2021-03-16 22:15:39.000000"},
{"id": 663058, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 138, "end_date": "2021-03-16 22:10:33.000000", "start_date": "2021-03-16 22:08:14.000000"},
{"id": 663057, "room": "Bedroom 19", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 123, "end_date": "2021-03-16 22:05:23.000000", "start_date": "2021-03-16 22:03:20.000000"},
{"id": 663056, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 2, "end_date": "2021-03-16 22:02:29.000000", "start_date": "2021-03-16 22:02:27.000000"},
{"id": 663055, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 261, "end_date": "2021-03-16 22:00:02.000000", "start_date": "2021-03-16 21:55:40.000000"},
{"id": 663054, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 26, "end_date": "2021-03-16 21:23:58.000000", "start_date": "2021-03-16 21:23:31.000000"},
{"id": 663053, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 65, "end_date": "2021-03-16 21:18:05.000000", "start_date": "2021-03-16 21:17:00.000000"},
{"id": 663052, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 168, "end_date": "2021-03-16 20:30:23.000000", "start_date": "2021-03-16 20:27:34.000000"},
{"id": 663051, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 116, "end_date": "2021-03-16 20:08:46.000000", "start_date": "2021-03-16 20:06:49.000000"},
{"id": 663050, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 182, "end_date": "2021-03-16 20:05:21.000000", "start_date": "2021-03-16 20:02:19.000000"},
{"id": 663049, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 17, "end_date": "2021-03-16 19:35:10.000000", "start_date": "2021-03-16 19:34:53.000000"},
{"id": 663047, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 62, "end_date": "2021-03-16 18:36:31.000000", "start_date": "2021-03-16 18:35:29.000000"},
{"id": 663046, "room": "Bedroom 12 En suite", "type": "Attendance", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 496, "end_date": "2021-03-16 18:42:23.000000", "start_date": "2021-03-16 18:34:06.000000"},
{"id": 663045, "room": "Bedroom 12 En suite", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 46, "end_date": "2021-03-16 18:34:06.000000", "start_date": "2021-03-16 18:33:19.000000"},
{"id": 663043, "room": "Bedroom 12 En suite", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 22, "end_date": "2021-03-16 18:12:54.000000", "start_date": "2021-03-16 18:12:32.000000"},
{"id": 663042, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 34, "end_date": "2021-03-16 18:10:04.000000", "start_date": "2021-03-16 18:09:29.000000"},
{"id": 663041, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 24, "end_date": "2021-03-16 18:03:59.000000", "start_date": "2021-03-16 18:03:35.000000"},
{"id": 663040, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 7, "end_date": "2021-03-16 17:59:12.000000", "start_date": "2021-03-16 17:59:04.000000"},
{"id": 663039, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 117, "end_date": "2021-03-16 17:54:54.000000", "start_date": "2021-03-16 17:52:57.000000"},
{"id": 663037, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 76, "end_date": "2021-03-16 17:05:44.000000", "start_date": "2021-03-16 17:04:27.000000"},
{"id": 663036, "room": "Bedroom 37", "type": "Call", "unit": null, "zone": "First floor", "siteid": 6, "duration": 225, "end_date": "2021-03-16 16:39:09.000000", "start_date": "2021-03-16 16:35:23.000000"},
{"id": 663035, "room": "Bedroom 18", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 522, "end_date": "2021-03-16 16:03:36.000000", "start_date": "2021-03-16 15:54:54.000000"},
{"id": 663034, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 6, "end_date": "2021-03-16 15:44:45.000000", "start_date": "2021-03-16 15:44:39.000000"},
{"id": 663033, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 102, "end_date": "2021-03-16 15:44:04.000000", "start_date": "2021-03-16 15:42:22.000000"},
{"id": 663032, "room": "Bedroom 4 En suite", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 437, "end_date": "2021-03-16 15:29:33.000000", "start_date": "2021-03-16 15:22:16.000000"},
{"id": 663031, "room": "Room 42", "type": "Accessory", "unit": null, "zone": "Haven Sensor", "siteid": 6, "duration": 343, "end_date": "2021-03-16 14:59:30.000000", "start_date": "2021-03-16 14:53:46.000000"},
{"id": 663027, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 299, "end_date": "2021-03-16 14:34:01.000000", "start_date": "2021-03-16 14:29:01.000000"},
{"id": 663026, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 168, "end_date": "2021-03-16 14:11:09.000000", "start_date": "2021-03-16 14:08:21.000000"},
{"id": 663025, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 277, "end_date": "2021-03-16 14:07:29.000000", "start_date": "2021-03-16 14:02:51.000000"},
{"id": 663024, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 26, "end_date": "2021-03-16 13:56:15.000000", "start_date": "2021-03-16 13:55:48.000000"},
{"id": 663023, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 38, "end_date": "2021-03-16 13:54:14.000000", "start_date": "2021-03-16 13:53:35.000000"},
{"id": 663021, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 4, "end_date": "2021-03-16 13:31:40.000000", "start_date": "2021-03-16 13:31:36.000000"},
{"id": 663020, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 53, "end_date": "2021-03-16 13:16:43.000000", "start_date": "2021-03-16 13:15:49.000000"},
{"id": 663019, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 529, "end_date": "2021-03-16 11:57:16.000000", "start_date": "2021-03-16 11:48:27.000000"},
{"id": 663018, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 4, "end_date": "2021-03-16 11:28:14.000000", "start_date": "2021-03-16 11:28:10.000000"},
{"id": 663017, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 20, "end_date": "2021-03-16 11:20:54.000000", "start_date": "2021-03-16 11:20:33.000000"},
{"id": 663016, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 33, "end_date": "2021-03-16 11:09:41.000000", "start_date": "2021-03-16 11:09:08.000000"},
{"id": 663013, "room": "Bedroom 19", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 446, "end_date": "2021-03-16 10:05:29.000000", "start_date": "2021-03-16 09:58:03.000000"},
{"id": 663012, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 322, "end_date": "2021-03-16 09:58:57.000000", "start_date": "2021-03-16 09:53:35.000000"},
{"id": 663009, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 49, "end_date": "2021-03-16 09:34:20.000000", "start_date": "2021-03-16 09:33:30.000000"},
{"id": 663008, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 16, "end_date": "2021-03-16 09:07:19.000000", "start_date": "2021-03-16 09:07:03.000000"},
{"id": 663007, "room": "Bedroom 19", "type": "Attendance", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 32, "end_date": "2021-03-16 08:41:02.000000", "start_date": "2021-03-16 08:40:30.000000"},
{"id": 663006, "room": "Bedroom 19", "type": "Call", "unit": null, "zone": "Ground floor", "siteid": 6, "duration": 33, "end_date": "2021-03-16 08:40:30.000000", "start_date": "2021-03-16 08:39:56.000000"},
{"id": 663002, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 105, "end_date": "2021-03-16 07:52:42.000000", "start_date": "2021-03-16 07:50:57.000000"},
{"id": 663001, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 61, "end_date": "2021-03-16 07:33:53.000000", "start_date": "2021-03-16 07:32:51.000000"},
{"id": 663000, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 56, "end_date": "2021-03-16 07:21:23.000000", "start_date": "2021-03-16 07:20:27.000000"},
{"id": 662999, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 34, "end_date": "2021-03-16 07:05:21.000000", "start_date": "2021-03-16 07:04:46.000000"},
{"id": 662998, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 1, "end_date": "2021-03-16 06:54:18.000000", "start_date": "2021-03-16 06:54:16.000000"},
{"id": 662997, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 478, "end_date": "2021-03-16 06:24:36.000000", "start_date": "2021-03-16 06:16:37.000000"},
{"id": 662995, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 7, "end_date": "2021-03-16 04:47:06.000000", "start_date": "2021-03-16 04:46:58.000000"},
{"id": 662994, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 682, "end_date": "2021-03-16 04:29:44.000000", "start_date": "2021-03-16 04:18:21.000000"},
{"id": 662993, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 8, "end_date": "2021-03-16 02:42:48.000000", "start_date": "2021-03-16 02:42:39.000000"},
{"id": 662992, "room": "Bedroom 25", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 323, "end_date": "2021-03-16 02:47:53.000000", "start_date": "2021-03-16 02:42:29.000000"},
{"id": 662991, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 17, "end_date": "2021-03-16 02:41:20.000000", "start_date": "2021-03-16 02:41:03.000000"},
{"id": 662990, "room": "Bedroom 34", "type": "Accessory", "unit": null, "zone": "First Floor", "siteid": 6, "duration": 450, "end_date": "2021-03-16 02:39:38.000000", "start_date": "2021-03-16 02:32:07.000000"},
{"id": 662987, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 90, "end_date": "2021-03-16 23:57:18.000000", "start_date": "2021-03-16 23:55:48.000000"},
{"id": 662986, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 100, "end_date": "2021-03-16 23:38:16.000000", "start_date": "2021-03-16 23:36:35.000000"},
{"id": 662985, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 37, "end_date": "2021-03-16 23:29:41.000000", "start_date": "2021-03-16 23:29:03.000000"},
{"id": 662984, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 96, "end_date": "2021-03-16 23:30:24.000000", "start_date": "2021-03-16 23:28:48.000000"},
{"id": 662983, "room": "En suite 3", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 720, "end_date": "2021-03-16 23:31:15.000000", "start_date": "2021-03-16 23:19:15.000000"},
{"id": 662982, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 318, "end_date": "2021-03-16 23:24:04.000000", "start_date": "2021-03-16 23:18:46.000000"},
{"id": 662980, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1535, "end_date": "2021-03-16 23:27:48.000000", "start_date": "2021-03-16 23:02:12.000000"},
{"id": 662979, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 77, "end_date": "2021-03-16 23:03:03.000000", "start_date": "2021-03-16 23:01:45.000000"},
{"id": 662978, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 48, "end_date": "2021-03-16 22:54:50.000000", "start_date": "2021-03-16 22:54:02.000000"},
{"id": 662977, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 224, "end_date": "2021-03-16 22:54:48.000000", "start_date": "2021-03-16 22:51:03.000000"},
{"id": 662976, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 252, "end_date": "2021-03-16 22:54:05.000000", "start_date": "2021-03-16 22:49:53.000000"},
{"id": 662975, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 205, "end_date": "2021-03-16 22:50:49.000000", "start_date": "2021-03-16 22:47:24.000000"},
{"id": 662974, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 297, "end_date": "2021-03-16 22:42:30.000000", "start_date": "2021-03-16 22:37:32.000000"},
{"id": 662973, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 481, "end_date": "2021-03-16 22:18:58.000000", "start_date": "2021-03-16 22:10:56.000000"},
{"id": 662972, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 78, "end_date": "2021-03-16 22:10:49.000000", "start_date": "2021-03-16 22:09:31.000000"},
{"id": 662971, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 22:04:15.000000", "start_date": "2021-03-16 22:04:12.000000"},
{"id": 662970, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 323, "end_date": "2021-03-16 22:04:12.000000", "start_date": "2021-03-16 21:58:48.000000"},
{"id": 662969, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 163, "end_date": "2021-03-16 21:48:54.000000", "start_date": "2021-03-16 21:46:11.000000"},
{"id": 662968, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 387, "end_date": "2021-03-16 21:29:26.000000", "start_date": "2021-03-16 21:22:59.000000"},
{"id": 662967, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 250, "end_date": "2021-03-16 21:17:45.000000", "start_date": "2021-03-16 21:13:35.000000"},
{"id": 662966, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1822, "end_date": "2021-03-16 21:43:32.000000", "start_date": "2021-03-16 21:13:10.000000"},
{"id": 662964, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 121, "end_date": "2021-03-16 21:13:01.000000", "start_date": "2021-03-16 21:11:00.000000"},
{"id": 662963, "room": "Bedroom 7", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 155, "end_date": "2021-03-16 21:13:10.000000", "start_date": "2021-03-16 21:10:34.000000"},
{"id": 662962, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 18, "end_date": "2021-03-16 21:01:51.000000", "start_date": "2021-03-16 21:01:33.000000"},
{"id": 662961, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 18, "end_date": "2021-03-16 21:01:49.000000", "start_date": "2021-03-16 21:01:30.000000"},
{"id": 662960, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 101, "end_date": "2021-03-16 20:56:51.000000", "start_date": "2021-03-16 20:55:09.000000"},
{"id": 662959, "room": "En suite 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 183, "end_date": "2021-03-16 20:39:58.000000", "start_date": "2021-03-16 20:36:54.000000"},
{"id": 662958, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 700, "end_date": "2021-03-16 20:40:02.000000", "start_date": "2021-03-16 20:28:21.000000"},
{"id": 662957, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 236, "end_date": "2021-03-16 20:28:34.000000", "start_date": "2021-03-16 20:24:37.000000"},
{"id": 662956, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 943, "end_date": "2021-03-16 20:38:26.000000", "start_date": "2021-03-16 20:22:42.000000"},
{"id": 662955, "room": "Bedroom 15", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1400, "end_date": "2021-03-16 20:41:35.000000", "start_date": "2021-03-16 20:18:14.000000"},
{"id": 662954, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1306, "end_date": "2021-03-16 20:37:24.000000", "start_date": "2021-03-16 20:15:38.000000"},
{"id": 662953, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 727, "end_date": "2021-03-16 20:23:01.000000", "start_date": "2021-03-16 20:10:54.000000"},
{"id": 662952, "room": "Bedroom 43", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 219, "end_date": "2021-03-16 20:11:24.000000", "start_date": "2021-03-16 20:07:44.000000"},
{"id": 662951, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 44, "end_date": "2021-03-16 20:07:02.000000", "start_date": "2021-03-16 20:06:18.000000"},
{"id": 662950, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 428, "end_date": "2021-03-16 19:52:32.000000", "start_date": "2021-03-16 19:45:23.000000"},
{"id": 662949, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 400, "end_date": "2021-03-16 19:49:15.000000", "start_date": "2021-03-16 19:42:35.000000"},
{"id": 662948, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 636, "end_date": "2021-03-16 19:48:42.000000", "start_date": "2021-03-16 19:38:05.000000"},
{"id": 662947, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 682, "end_date": "2021-03-16 19:48:36.000000", "start_date": "2021-03-16 19:37:13.000000"},
{"id": 662946, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 132, "end_date": "2021-03-16 19:35:48.000000", "start_date": "2021-03-16 19:33:35.000000"},
{"id": 662945, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 5, "end_date": "2021-03-16 19:14:11.000000", "start_date": "2021-03-16 19:14:05.000000"},
{"id": 662944, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 601, "end_date": "2021-03-16 19:14:05.000000", "start_date": "2021-03-16 19:04:03.000000"},
{"id": 662943, "room": "Bedroom 6", "type": "Assistance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 50, "end_date": "2021-03-16 19:00:35.000000", "start_date": "2021-03-16 18:59:45.000000"},
{"id": 662942, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 196, "end_date": "2021-03-16 19:00:37.000000", "start_date": "2021-03-16 18:57:21.000000"},
{"id": 662941, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 601, "end_date": "2021-03-16 18:59:45.000000", "start_date": "2021-03-16 18:49:43.000000"},
{"id": 662940, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 655, "end_date": "2021-03-16 19:00:36.000000", "start_date": "2021-03-16 18:49:40.000000"},
{"id": 662939, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 30, "end_date": "2021-03-16 18:35:15.000000", "start_date": "2021-03-16 18:34:45.000000"},
{"id": 662938, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 8, "end_date": "2021-03-16 18:32:01.000000", "start_date": "2021-03-16 18:31:52.000000"},
{"id": 662937, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 39, "end_date": "2021-03-16 18:26:11.000000", "start_date": "2021-03-16 18:25:32.000000"},
{"id": 662936, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1678, "end_date": "2021-03-16 18:46:58.000000", "start_date": "2021-03-16 18:19:00.000000"},
{"id": 662935, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 147, "end_date": "2021-03-16 18:16:55.000000", "start_date": "2021-03-16 18:14:28.000000"},
{"id": 662933, "room": "Bedroom 1", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 10, "end_date": "2021-03-16 18:11:58.000000", "start_date": "2021-03-16 18:11:47.000000"},
{"id": 662932, "room": "Bedroom 15", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 303, "end_date": "2021-03-16 18:14:23.000000", "start_date": "2021-03-16 18:09:20.000000"},
{"id": 662931, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 581, "end_date": "2021-03-16 18:11:47.000000", "start_date": "2021-03-16 18:02:06.000000"},
{"id": 662930, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 630, "end_date": "2021-03-16 18:09:16.000000", "start_date": "2021-03-16 17:58:46.000000"},
{"id": 662929, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 825, "end_date": "2021-03-16 18:08:24.000000", "start_date": "2021-03-16 17:54:39.000000"},
{"id": 662928, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 29, "end_date": "2021-03-16 17:46:53.000000", "start_date": "2021-03-16 17:46:24.000000"},
{"id": 662927, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 330, "end_date": "2021-03-16 17:46:24.000000", "start_date": "2021-03-16 17:40:53.000000"},
{"id": 662926, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 477, "end_date": "2021-03-16 17:47:57.000000", "start_date": "2021-03-16 17:39:59.000000"},
{"id": 662925, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 71, "end_date": "2021-03-16 17:40:53.000000", "start_date": "2021-03-16 17:39:42.000000"},
{"id": 662924, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 4246, "end_date": "2021-03-16 18:48:13.000000", "start_date": "2021-03-16 17:37:27.000000"},
{"id": 662923, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 464, "end_date": "2021-03-16 17:29:09.000000", "start_date": "2021-03-16 17:21:24.000000"},
{"id": 662922, "room": "Dining room unit 2", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 890, "end_date": "2021-03-16 17:28:23.000000", "start_date": "2021-03-16 17:13:32.000000"},
{"id": 662921, "room": "Bedroom 2", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 82, "end_date": "2021-03-16 17:00:59.000000", "start_date": "2021-03-16 16:59:37.000000"},
{"id": 662920, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 744, "end_date": "2021-03-16 17:03:53.000000", "start_date": "2021-03-16 16:51:28.000000"},
{"id": 662919, "room": "Bedroom 6", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1104, "end_date": "2021-03-16 17:03:37.000000", "start_date": "2021-03-16 16:45:12.000000"},
{"id": 662918, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 143, "end_date": "2021-03-16 16:44:18.000000", "start_date": "2021-03-16 16:41:54.000000"},
{"id": 662917, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 198, "end_date": "2021-03-16 16:45:12.000000", "start_date": "2021-03-16 16:41:53.000000"},
{"id": 662916, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1056, "end_date": "2021-03-16 16:48:51.000000", "start_date": "2021-03-16 16:31:15.000000"},
{"id": 662915, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 26, "end_date": "2021-03-16 16:11:45.000000", "start_date": "2021-03-16 16:11:19.000000"},
{"id": 662914, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 7, "end_date": "2021-03-16 16:10:18.000000", "start_date": "2021-03-16 16:10:11.000000"},
{"id": 662913, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 252, "end_date": "2021-03-16 16:11:05.000000", "start_date": "2021-03-16 16:06:52.000000"},
{"id": 662912, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 379, "end_date": "2021-03-16 16:09:30.000000", "start_date": "2021-03-16 16:03:11.000000"},
{"id": 662911, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 16:02:03.000000", "start_date": "2021-03-16 16:02:01.000000"},
{"id": 662910, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 43, "end_date": "2021-03-16 16:02:27.000000", "start_date": "2021-03-16 16:01:44.000000"},
{"id": 662909, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 279, "end_date": "2021-03-16 16:05:59.000000", "start_date": "2021-03-16 16:01:19.000000"},
{"id": 662908, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 648, "end_date": "2021-03-16 16:08:11.000000", "start_date": "2021-03-16 15:57:22.000000"},
{"id": 662907, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 5, "end_date": "2021-03-16 15:57:16.000000", "start_date": "2021-03-16 15:57:10.000000"},
{"id": 662905, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1299, "end_date": "2021-03-16 16:10:17.000000", "start_date": "2021-03-16 15:48:38.000000"},
{"id": 662904, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 599, "end_date": "2021-03-16 15:55:33.000000", "start_date": "2021-03-16 15:45:34.000000"},
{"id": 662903, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 773, "end_date": "2021-03-16 15:54:58.000000", "start_date": "2021-03-16 15:42:04.000000"},
{"id": 662902, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 183, "end_date": "2021-03-16 15:44:40.000000", "start_date": "2021-03-16 15:41:37.000000"},
{"id": 662901, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1872, "end_date": "2021-03-16 15:53:12.000000", "start_date": "2021-03-16 15:22:00.000000"},
{"id": 662900, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 439, "end_date": "2021-03-16 15:18:36.000000", "start_date": "2021-03-16 15:11:16.000000"},
{"id": 662899, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 3140, "end_date": "2021-03-16 15:42:27.000000", "start_date": "2021-03-16 14:50:07.000000"},
{"id": 662898, "room": "Bedroom 2", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 523, "end_date": "2021-03-16 14:56:06.000000", "start_date": "2021-03-16 14:47:22.000000"},
{"id": 662897, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 151, "end_date": "2021-03-16 14:47:21.000000", "start_date": "2021-03-16 14:44:50.000000"},
{"id": 662896, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 10, "end_date": "2021-03-16 14:43:30.000000", "start_date": "2021-03-16 14:43:19.000000"},
{"id": 662895, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 34, "end_date": "2021-03-16 14:43:14.000000", "start_date": "2021-03-16 14:42:39.000000"},
{"id": 662894, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 0, "end_date": "2021-03-16 14:42:11.000000", "start_date": "2021-03-16 14:42:10.000000"},
{"id": 662893, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 139, "end_date": "2021-03-16 14:43:17.000000", "start_date": "2021-03-16 14:40:57.000000"},
{"id": 662892, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 10, "end_date": "2021-03-16 14:38:58.000000", "start_date": "2021-03-16 14:38:48.000000"},
{"id": 662891, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 5, "end_date": "2021-03-16 14:37:57.000000", "start_date": "2021-03-16 14:37:52.000000"},
{"id": 662890, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 14:37:48.000000", "start_date": "2021-03-16 14:37:44.000000"},
{"id": 662889, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 4, "end_date": "2021-03-16 14:37:44.000000", "start_date": "2021-03-16 14:37:39.000000"},
{"id": 662887, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 7, "end_date": "2021-03-16 14:37:05.000000", "start_date": "2021-03-16 14:36:57.000000"},
{"id": 662886, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 14:34:36.000000", "start_date": "2021-03-16 14:34:34.000000"},
{"id": 662885, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 12, "end_date": "2021-03-16 14:33:31.000000", "start_date": "2021-03-16 14:33:19.000000"},
{"id": 662884, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 196, "end_date": "2021-03-16 14:36:34.000000", "start_date": "2021-03-16 14:33:18.000000"},
{"id": 662883, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 107, "end_date": "2021-03-16 14:33:10.000000", "start_date": "2021-03-16 14:31:22.000000"},
{"id": 662882, "room": "Bedroom 26", "type": "Attendance", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 911, "end_date": "2021-03-16 14:45:03.000000", "start_date": "2021-03-16 14:29:52.000000"},
{"id": 662881, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 318, "end_date": "2021-03-16 14:29:52.000000", "start_date": "2021-03-16 14:24:34.000000"},
{"id": 662880, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 14:20:49.000000", "start_date": "2021-03-16 14:20:47.000000"},
{"id": 662879, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 376, "end_date": "2021-03-16 14:26:39.000000", "start_date": "2021-03-16 14:20:23.000000"},
{"id": 662878, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 55, "end_date": "2021-03-16 14:20:46.000000", "start_date": "2021-03-16 14:19:50.000000"},
{"id": 662877, "room": "Bedroom 57", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1559, "end_date": "2021-03-16 14:44:15.000000", "start_date": "2021-03-16 14:18:16.000000"},
{"id": 662876, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 320, "end_date": "2021-03-16 14:12:42.000000", "start_date": "2021-03-16 14:07:21.000000"},
{"id": 662875, "room": "Bedroom 6", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 439, "end_date": "2021-03-16 14:08:14.000000", "start_date": "2021-03-16 14:00:55.000000"},
{"id": 662874, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 103, "end_date": "2021-03-16 14:02:09.000000", "start_date": "2021-03-16 14:00:26.000000"},
{"id": 662873, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 21, "end_date": "2021-03-16 13:59:18.000000", "start_date": "2021-03-16 13:58:56.000000"},
{"id": 662871, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 13:57:51.000000", "start_date": "2021-03-16 13:57:50.000000"},
{"id": 662870, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 13:56:21.000000", "start_date": "2021-03-16 13:56:18.000000"},
{"id": 662869, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 475, "end_date": "2021-03-16 13:59:34.000000", "start_date": "2021-03-16 13:51:38.000000"},
{"id": 662868, "room": "Bedroom 43", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 111, "end_date": "2021-03-16 13:53:03.000000", "start_date": "2021-03-16 13:51:11.000000"},
{"id": 662867, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 356, "end_date": "2021-03-16 13:55:12.000000", "start_date": "2021-03-16 13:49:15.000000"},
{"id": 662866, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 489, "end_date": "2021-03-16 13:51:25.000000", "start_date": "2021-03-16 13:43:15.000000"},
{"id": 662865, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 48, "end_date": "2021-03-16 13:44:00.000000", "start_date": "2021-03-16 13:43:11.000000"},
{"id": 662864, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 395, "end_date": "2021-03-16 13:43:15.000000", "start_date": "2021-03-16 13:36:40.000000"},
{"id": 662863, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 13:31:01.000000", "start_date": "2021-03-16 13:31:00.000000"},
{"id": 662862, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 432, "end_date": "2021-03-16 13:36:29.000000", "start_date": "2021-03-16 13:29:17.000000"},
{"id": 662861, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 13:27:13.000000", "start_date": "2021-03-16 13:27:10.000000"},
{"id": 662860, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 13:22:53.000000", "start_date": "2021-03-16 13:22:52.000000"},
{"id": 662859, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 340, "end_date": "2021-03-16 13:26:55.000000", "start_date": "2021-03-16 13:21:15.000000"},
{"id": 662858, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 106, "end_date": "2021-03-16 13:21:15.000000", "start_date": "2021-03-16 13:19:29.000000"},
{"id": 662857, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1304, "end_date": "2021-03-16 13:25:25.000000", "start_date": "2021-03-16 13:03:41.000000"},
{"id": 662856, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 2192, "end_date": "2021-03-16 13:33:01.000000", "start_date": "2021-03-16 12:56:29.000000"},
{"id": 662855, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 12, "end_date": "2021-03-16 12:56:34.000000", "start_date": "2021-03-16 12:56:21.000000"},
{"id": 662854, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 476, "end_date": "2021-03-16 13:02:23.000000", "start_date": "2021-03-16 12:54:27.000000"},
{"id": 662853, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 342, "end_date": "2021-03-16 12:50:52.000000", "start_date": "2021-03-16 12:45:09.000000"},
{"id": 662852, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 1186, "end_date": "2021-03-16 12:41:02.000000", "start_date": "2021-03-16 12:21:16.000000"},
{"id": 662851, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 734, "end_date": "2021-03-16 12:25:23.000000", "start_date": "2021-03-16 12:13:08.000000"},
{"id": 662850, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 24, "end_date": "2021-03-16 12:11:46.000000", "start_date": "2021-03-16 12:11:21.000000"},
{"id": 662849, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 540, "end_date": "2021-03-16 12:10:10.000000", "start_date": "2021-03-16 12:01:09.000000"},
{"id": 662848, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 338, "end_date": "2021-03-16 11:51:53.000000", "start_date": "2021-03-16 11:46:15.000000"},
{"id": 662847, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 463, "end_date": "2021-03-16 11:50:56.000000", "start_date": "2021-03-16 11:43:12.000000"},
{"id": 662846, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 11:40:53.000000", "start_date": "2021-03-16 11:40:50.000000"},
{"id": 662845, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 76, "end_date": "2021-03-16 11:40:09.000000", "start_date": "2021-03-16 11:38:52.000000"},
{"id": 662844, "room": "Bedroom 6", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 175, "end_date": "2021-03-16 11:39:27.000000", "start_date": "2021-03-16 11:36:32.000000"},
{"id": 662843, "room": "Bedroom 61", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 182, "end_date": "2021-03-16 11:38:32.000000", "start_date": "2021-03-16 11:35:29.000000"},
{"id": 662842, "room": "Bedroom 15", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 907, "end_date": "2021-03-16 11:45:42.000000", "start_date": "2021-03-16 11:30:35.000000"},
{"id": 662840, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 214, "end_date": "2021-03-16 11:28:47.000000", "start_date": "2021-03-16 11:25:13.000000"},
{"id": 662839, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 311, "end_date": "2021-03-16 11:28:52.000000", "start_date": "2021-03-16 11:23:41.000000"},
{"id": 662838, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 11:04:47.000000", "start_date": "2021-03-16 11:04:45.000000"},
{"id": 662837, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 51, "end_date": "2021-03-16 10:56:31.000000", "start_date": "2021-03-16 10:55:39.000000"},
{"id": 662836, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 88, "end_date": "2021-03-16 10:51:17.000000", "start_date": "2021-03-16 10:49:49.000000"},
{"id": 662835, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 187, "end_date": "2021-03-16 10:52:05.000000", "start_date": "2021-03-16 10:48:58.000000"},
{"id": 662832, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 3184, "end_date": "2021-03-16 11:34:36.000000", "start_date": "2021-03-16 10:41:32.000000"},
{"id": 662831, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 1343, "end_date": "2021-03-16 10:55:47.000000", "start_date": "2021-03-16 10:33:24.000000"},
{"id": 662829, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 6, "end_date": "2021-03-16 10:18:36.000000", "start_date": "2021-03-16 10:18:29.000000"},
{"id": 662827, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 13, "end_date": "2021-03-16 10:13:24.000000", "start_date": "2021-03-16 10:13:11.000000"},
{"id": 662826, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 42, "end_date": "2021-03-16 10:11:03.000000", "start_date": "2021-03-16 10:10:20.000000"},
{"id": 662825, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 492, "end_date": "2021-03-16 10:18:26.000000", "start_date": "2021-03-16 10:10:13.000000"},
{"id": 662824, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 406, "end_date": "2021-03-16 10:06:20.000000", "start_date": "2021-03-16 09:59:33.000000"},
{"id": 662823, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 581, "end_date": "2021-03-16 10:06:34.000000", "start_date": "2021-03-16 09:56:53.000000"},
{"id": 662822, "room": "Bedroom 26", "type": "Attendance", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 1618, "end_date": "2021-03-16 10:21:31.000000", "start_date": "2021-03-16 09:54:33.000000"},
{"id": 662821, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 09:52:54.000000", "start_date": "2021-03-16 09:52:52.000000"},
{"id": 662820, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 87, "end_date": "2021-03-16 09:54:11.000000", "start_date": "2021-03-16 09:52:43.000000"},
{"id": 662819, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 09:48:51.000000", "start_date": "2021-03-16 09:48:48.000000"},
{"id": 662818, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 199, "end_date": "2021-03-16 09:50:24.000000", "start_date": "2021-03-16 09:47:04.000000"},
{"id": 662817, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 8, "end_date": "2021-03-16 09:45:54.000000", "start_date": "2021-03-16 09:45:46.000000"},
{"id": 662816, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 120, "end_date": "2021-03-16 09:45:07.000000", "start_date": "2021-03-16 09:43:06.000000"},
{"id": 662814, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 284, "end_date": "2021-03-16 09:45:46.000000", "start_date": "2021-03-16 09:41:01.000000"},
{"id": 662813, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 122, "end_date": "2021-03-16 09:42:41.000000", "start_date": "2021-03-16 09:40:39.000000"},
{"id": 662812, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 84, "end_date": "2021-03-16 09:41:35.000000", "start_date": "2021-03-16 09:40:10.000000"},
{"id": 662811, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 441, "end_date": "2021-03-16 09:46:58.000000", "start_date": "2021-03-16 09:39:36.000000"},
{"id": 662810, "room": "Bedroom 59", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1433, "end_date": "2021-03-16 10:03:09.000000", "start_date": "2021-03-16 09:39:15.000000"},
{"id": 662809, "room": "Bedroom 2", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 2080, "end_date": "2021-03-16 10:10:40.000000", "start_date": "2021-03-16 09:36:00.000000"},
{"id": 662808, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 139, "end_date": "2021-03-16 09:37:57.000000", "start_date": "2021-03-16 09:35:38.000000"},
{"id": 662807, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 47, "end_date": "2021-03-16 09:34:09.000000", "start_date": "2021-03-16 09:33:21.000000"},
{"id": 662806, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 09:30:32.000000", "start_date": "2021-03-16 09:30:29.000000"},
{"id": 662805, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 219, "end_date": "2021-03-16 09:31:39.000000", "start_date": "2021-03-16 09:28:00.000000"},
{"id": 662804, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 3, "end_date": "2021-03-16 09:23:21.000000", "start_date": "2021-03-16 09:23:17.000000"},
{"id": 662803, "room": "Bedroom 26", "type": "Attendance", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 652, "end_date": "2021-03-16 09:31:01.000000", "start_date": "2021-03-16 09:20:08.000000"},
{"id": 662801, "room": "Bedroom 2", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 34, "end_date": "2021-03-16 09:18:17.000000", "start_date": "2021-03-16 09:17:43.000000"},
{"id": 662800, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 845, "end_date": "2021-03-16 09:29:21.000000", "start_date": "2021-03-16 09:15:16.000000"},
{"id": 662799, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 458, "end_date": "2021-03-16 09:20:08.000000", "start_date": "2021-03-16 09:12:30.000000"},
{"id": 662798, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 16, "end_date": "2021-03-16 09:10:00.000000", "start_date": "2021-03-16 09:09:44.000000"},
{"id": 662797, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 16, "end_date": "2021-03-16 09:06:33.000000", "start_date": "2021-03-16 09:06:17.000000"},
{"id": 662796, "room": "Bedroom 2", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 707, "end_date": "2021-03-16 09:17:43.000000", "start_date": "2021-03-16 09:05:55.000000"},
{"id": 662795, "room": "Dining room unit 2", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1883, "end_date": "2021-03-16 09:36:28.000000", "start_date": "2021-03-16 09:05:05.000000"},
{"id": 662794, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 195, "end_date": "2021-03-16 09:05:32.000000", "start_date": "2021-03-16 09:02:16.000000"},
{"id": 662793, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 26, "end_date": "2021-03-16 09:02:15.000000", "start_date": "2021-03-16 09:01:49.000000"},
{"id": 662792, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 332, "end_date": "2021-03-16 09:07:18.000000", "start_date": "2021-03-16 09:01:45.000000"},
{"id": 662791, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 1284, "end_date": "2021-03-16 09:23:06.000000", "start_date": "2021-03-16 09:01:41.000000"},
{"id": 662790, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 4, "end_date": "2021-03-16 09:01:41.000000", "start_date": "2021-03-16 09:01:36.000000"},
{"id": 662789, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 09:00:36.000000", "start_date": "2021-03-16 09:00:33.000000"},
{"id": 662788, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 27, "end_date": "2021-03-16 09:00:34.000000", "start_date": "2021-03-16 09:00:06.000000"},
{"id": 662787, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 30, "end_date": "2021-03-16 08:54:38.000000", "start_date": "2021-03-16 08:54:08.000000"},
{"id": 662786, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 2466, "end_date": "2021-03-16 09:32:28.000000", "start_date": "2021-03-16 08:51:21.000000"},
{"id": 662785, "room": "Bedroom 59", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 260, "end_date": "2021-03-16 08:53:49.000000", "start_date": "2021-03-16 08:49:29.000000"},
{"id": 662784, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 08:49:17.000000", "start_date": "2021-03-16 08:49:15.000000"},
{"id": 662780, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 382, "end_date": "2021-03-16 08:45:07.000000", "start_date": "2021-03-16 08:38:45.000000"},
{"id": 662779, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 158, "end_date": "2021-03-16 08:39:34.000000", "start_date": "2021-03-16 08:36:55.000000"},
{"id": 662778, "room": "Bedroom 2", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 23, "end_date": "2021-03-16 08:30:12.000000", "start_date": "2021-03-16 08:29:48.000000"},
{"id": 662777, "room": "Bedroom 2", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 218, "end_date": "2021-03-16 08:29:48.000000", "start_date": "2021-03-16 08:26:09.000000"},
{"id": 662776, "room": "Bedroom 6", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1282, "end_date": "2021-03-16 08:42:51.000000", "start_date": "2021-03-16 08:21:29.000000"},
{"id": 662774, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 967, "end_date": "2021-03-16 08:27:26.000000", "start_date": "2021-03-16 08:11:19.000000"},
{"id": 662773, "room": "Bedroom 26", "type": "Attendance", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 101, "end_date": "2021-03-16 08:11:34.000000", "start_date": "2021-03-16 08:09:52.000000"},
{"id": 662770, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 3, "duration": 568, "end_date": "2021-03-16 08:09:52.000000", "start_date": "2021-03-16 08:00:23.000000"},
{"id": 662765, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 120, "end_date": "2021-03-16 07:37:14.000000", "start_date": "2021-03-16 07:35:13.000000"},
{"id": 662763, "room": "Bedroom 4", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 1876, "end_date": "2021-03-16 08:01:11.000000", "start_date": "2021-03-16 07:29:55.000000"},
{"id": 662762, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 11, "end_date": "2021-03-16 07:23:39.000000", "start_date": "2021-03-16 07:23:28.000000"},
{"id": 662761, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 179, "end_date": "2021-03-16 07:22:52.000000", "start_date": "2021-03-16 07:19:53.000000"},
{"id": 662758, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 970, "end_date": "2021-03-16 07:18:38.000000", "start_date": "2021-03-16 07:02:28.000000"},
{"id": 662757, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 213, "end_date": "2021-03-16 06:33:14.000000", "start_date": "2021-03-16 06:29:41.000000"},
{"id": 662756, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 480, "end_date": "2021-03-16 06:29:41.000000", "start_date": "2021-03-16 06:21:41.000000"},
{"id": 662755, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 84, "end_date": "2021-03-16 06:21:41.000000", "start_date": "2021-03-16 06:20:16.000000"},
{"id": 662753, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 24, "end_date": "2021-03-16 06:06:57.000000", "start_date": "2021-03-16 06:06:33.000000"},
{"id": 662752, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 225, "end_date": "2021-03-16 06:05:21.000000", "start_date": "2021-03-16 06:01:36.000000"},
{"id": 662749, "room": "Bedroom 1", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 580, "end_date": "2021-03-16 06:01:12.000000", "start_date": "2021-03-16 05:51:32.000000"},
{"id": 662748, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 191, "end_date": "2021-03-16 05:53:35.000000", "start_date": "2021-03-16 05:50:24.000000"},
{"id": 662747, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 18, "end_date": "2021-03-16 05:32:10.000000", "start_date": "2021-03-16 05:31:52.000000"},
{"id": 662745, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 8, "end_date": "2021-03-16 05:31:19.000000", "start_date": "2021-03-16 05:31:10.000000"},
{"id": 662744, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 8, "end_date": "2021-03-16 05:09:41.000000", "start_date": "2021-03-16 05:09:32.000000"},
{"id": 662743, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 260, "end_date": "2021-03-16 05:03:11.000000", "start_date": "2021-03-16 04:58:50.000000"},
{"id": 662740, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 26, "end_date": "2021-03-16 04:46:26.000000", "start_date": "2021-03-16 04:45:59.000000"},
{"id": 662739, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 1153, "end_date": "2021-03-16 04:55:23.000000", "start_date": "2021-03-16 04:36:09.000000"},
{"id": 662738, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 66, "end_date": "2021-03-16 04:35:02.000000", "start_date": "2021-03-16 04:33:56.000000"},
{"id": 662737, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 99, "end_date": "2021-03-16 04:17:52.000000", "start_date": "2021-03-16 04:16:13.000000"},
{"id": 662736, "room": "room 59 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 8, "end_date": "2021-03-16 04:13:33.000000", "start_date": "2021-03-16 04:13:25.000000"},
{"id": 662732, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 437, "end_date": "2021-03-16 03:59:39.000000", "start_date": "2021-03-16 03:52:21.000000"},
{"id": 662730, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 37, "end_date": "2021-03-16 03:48:33.000000", "start_date": "2021-03-16 03:47:55.000000"},
{"id": 662729, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 1, "end_date": "2021-03-16 03:45:57.000000", "start_date": "2021-03-16 03:45:56.000000"},
{"id": 662728, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 177, "end_date": "2021-03-16 03:35:35.000000", "start_date": "2021-03-16 03:32:38.000000"},
{"id": 662727, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 55, "end_date": "2021-03-16 03:31:02.000000", "start_date": "2021-03-16 03:30:07.000000"},
{"id": 662726, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 223, "end_date": "2021-03-16 03:28:04.000000", "start_date": "2021-03-16 03:24:21.000000"},
{"id": 662725, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 224, "end_date": "2021-03-16 03:27:54.000000", "start_date": "2021-03-16 03:24:10.000000"},
{"id": 662724, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 74, "end_date": "2021-03-16 03:16:05.000000", "start_date": "2021-03-16 03:14:50.000000"},
{"id": 662723, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 2, "end_date": "2021-03-16 03:14:37.000000", "start_date": "2021-03-16 03:14:35.000000"},
{"id": 662722, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 26, "end_date": "2021-03-16 03:13:37.000000", "start_date": "2021-03-16 03:13:10.000000"},
{"id": 662721, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 22, "end_date": "2021-03-16 03:12:49.000000", "start_date": "2021-03-16 03:12:27.000000"},
{"id": 662720, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 236, "end_date": "2021-03-16 03:14:50.000000", "start_date": "2021-03-16 03:10:53.000000"},
{"id": 662719, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 59, "end_date": "2021-03-16 03:10:27.000000", "start_date": "2021-03-16 03:09:27.000000"},
{"id": 662718, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 65, "end_date": "2021-03-16 03:10:29.000000", "start_date": "2021-03-16 03:09:24.000000"},
{"id": 662716, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 84, "end_date": "2021-03-16 03:07:53.000000", "start_date": "2021-03-16 03:06:28.000000"},
{"id": 662715, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 3, "duration": 94, "end_date": "2021-03-16 03:02:48.000000", "start_date": "2021-03-16 03:01:14.000000"},
{"id": 662713, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 11, "end_date": "2021-03-16 02:41:24.000000", "start_date": "2021-03-16 02:41:12.000000"},
{"id": 662712, "room": "Bedroom 12", "type": "Call", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 13, "end_date": "2021-03-16 02:34:03.000000", "start_date": "2021-03-16 02:33:50.000000"},
{"id": 662711, "room": "Bedroom 12", "type": "Call", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 24, "end_date": "2021-03-16 02:33:41.000000", "start_date": "2021-03-16 02:33:16.000000"},
{"id": 662710, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 3, "duration": 324, "end_date": "2021-03-16 02:34:08.000000", "start_date": "2021-03-16 02:28:43.000000"},
{"id": 662709, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 276, "end_date": "2021-03-16 02:33:16.000000", "start_date": "2021-03-16 02:28:40.000000"},
{"id": 662708, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 3, "duration": 100, "end_date": "2021-03-16 02:29:34.000000", "start_date": "2021-03-16 02:27:53.000000"},
{"id": 662707, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 369, "end_date": "2021-03-16 02:31:29.000000", "start_date": "2021-03-16 02:25:20.000000"},
{"id": 662706, "room": "Bedroom 12", "type": "Call", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 95, "end_date": "2021-03-16 02:23:09.000000", "start_date": "2021-03-16 02:21:34.000000"},
{"id": 662704, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 66, "end_date": "2021-03-16 02:16:31.000000", "start_date": "2021-03-16 02:15:24.000000"},
{"id": 662702, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 3, "duration": 77, "end_date": "2021-03-16 02:07:10.000000", "start_date": "2021-03-16 02:05:53.000000"},
{"id": 662697, "room": "Bedroom 12", "type": "Call", "unit": null, "zone": "Grounds Floor", "siteid": 3, "duration": 46, "end_date": "2021-03-16 01:13:46.000000", "start_date": "2021-03-16 01:13:00.000000"},
{"id": 662688, "room": "Bedroom 52", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 3, "duration": 15, "end_date": "2021-03-16 00:04:06.000000", "start_date": "2021-03-16 00:03:51.000000"},
{"id": 662685, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 2, "duration": 90, "end_date": "2021-03-16 23:57:18.000000", "start_date": "2021-03-16 23:55:48.000000"},
{"id": 662684, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 2, "duration": 100, "end_date": "2021-03-16 23:38:16.000000", "start_date": "2021-03-16 23:36:35.000000"},
{"id": 662683, "room": "Bedroom 26", "type": "Call", "unit": null, "zone": "First Floor", "siteid": 2, "duration": 37, "end_date": "2021-03-16 23:29:41.000000", "start_date": "2021-03-16 23:29:03.000000"},
{"id": 662682, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 96, "end_date": "2021-03-16 23:30:24.000000", "start_date": "2021-03-16 23:28:48.000000"},
{"id": 662681, "room": "En suite 3", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 720, "end_date": "2021-03-16 23:31:15.000000", "start_date": "2021-03-16 23:19:15.000000"},
{"id": 662680, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 318, "end_date": "2021-03-16 23:24:04.000000", "start_date": "2021-03-16 23:18:46.000000"},
{"id": 662678, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 1535, "end_date": "2021-03-16 23:27:48.000000", "start_date": "2021-03-16 23:02:12.000000"},
{"id": 662677, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 77, "end_date": "2021-03-16 23:03:03.000000", "start_date": "2021-03-16 23:01:45.000000"},
{"id": 662676, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 48, "end_date": "2021-03-16 22:54:50.000000", "start_date": "2021-03-16 22:54:02.000000"},
{"id": 662675, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 224, "end_date": "2021-03-16 22:54:48.000000", "start_date": "2021-03-16 22:51:03.000000"},
{"id": 662674, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 252, "end_date": "2021-03-16 22:54:05.000000", "start_date": "2021-03-16 22:49:53.000000"},
{"id": 662673, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 205, "end_date": "2021-03-16 22:50:49.000000", "start_date": "2021-03-16 22:47:24.000000"},
{"id": 662672, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 297, "end_date": "2021-03-16 22:42:30.000000", "start_date": "2021-03-16 22:37:32.000000"},
{"id": 662671, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 481, "end_date": "2021-03-16 22:18:58.000000", "start_date": "2021-03-16 22:10:56.000000"},
{"id": 662670, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 78, "end_date": "2021-03-16 22:10:49.000000", "start_date": "2021-03-16 22:09:31.000000"},
{"id": 662669, "room": "room 3", "type": "Attendance", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 3, "end_date": "2021-03-16 22:04:15.000000", "start_date": "2021-03-16 22:04:12.000000"},
{"id": 662668, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 323, "end_date": "2021-03-16 22:04:12.000000", "start_date": "2021-03-16 21:58:48.000000"},
{"id": 662667, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 163, "end_date": "2021-03-16 21:48:54.000000", "start_date": "2021-03-16 21:46:11.000000"},
{"id": 662666, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 387, "end_date": "2021-03-16 21:29:26.000000", "start_date": "2021-03-16 21:22:59.000000"},
{"id": 662665, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 250, "end_date": "2021-03-16 21:17:45.000000", "start_date": "2021-03-16 21:13:35.000000"},
{"id": 662664, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 1822, "end_date": "2021-03-16 21:43:32.000000", "start_date": "2021-03-16 21:13:10.000000"},
{"id": 662662, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 121, "end_date": "2021-03-16 21:13:01.000000", "start_date": "2021-03-16 21:11:00.000000"},
{"id": 662661, "room": "Bedroom 7", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 155, "end_date": "2021-03-16 21:13:10.000000", "start_date": "2021-03-16 21:10:34.000000"},
{"id": 662660, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 18, "end_date": "2021-03-16 21:01:51.000000", "start_date": "2021-03-16 21:01:33.000000"},
{"id": 662659, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 18, "end_date": "2021-03-16 21:01:49.000000", "start_date": "2021-03-16 21:01:30.000000"},
{"id": 662658, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 101, "end_date": "2021-03-16 20:56:51.000000", "start_date": "2021-03-16 20:55:09.000000"},
{"id": 662657, "room": "En suite 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 183, "end_date": "2021-03-16 20:39:58.000000", "start_date": "2021-03-16 20:36:54.000000"},
{"id": 662656, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 700, "end_date": "2021-03-16 20:40:02.000000", "start_date": "2021-03-16 20:28:21.000000"},
{"id": 662655, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 236, "end_date": "2021-03-16 20:28:34.000000", "start_date": "2021-03-16 20:24:37.000000"},
{"id": 662654, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 943, "end_date": "2021-03-16 20:38:26.000000", "start_date": "2021-03-16 20:22:42.000000"},
{"id": 662653, "room": "Bedroom 15", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 1400, "end_date": "2021-03-16 20:41:35.000000", "start_date": "2021-03-16 20:18:14.000000"},
{"id": 662652, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 1306, "end_date": "2021-03-16 20:37:24.000000", "start_date": "2021-03-16 20:15:38.000000"},
{"id": 662651, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 727, "end_date": "2021-03-16 20:23:01.000000", "start_date": "2021-03-16 20:10:54.000000"},
{"id": 662650, "room": "Bedroom 43", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 219, "end_date": "2021-03-16 20:11:24.000000", "start_date": "2021-03-16 20:07:44.000000"},
{"id": 662649, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 44, "end_date": "2021-03-16 20:07:02.000000", "start_date": "2021-03-16 20:06:18.000000"},
{"id": 662648, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 428, "end_date": "2021-03-16 19:52:32.000000", "start_date": "2021-03-16 19:45:23.000000"},
{"id": 662647, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 400, "end_date": "2021-03-16 19:49:15.000000", "start_date": "2021-03-16 19:42:35.000000"},
{"id": 662646, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 636, "end_date": "2021-03-16 19:48:42.000000", "start_date": "2021-03-16 19:38:05.000000"},
{"id": 662645, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 682, "end_date": "2021-03-16 19:48:36.000000", "start_date": "2021-03-16 19:37:13.000000"},
{"id": 662644, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 132, "end_date": "2021-03-16 19:35:48.000000", "start_date": "2021-03-16 19:33:35.000000"},
{"id": 662643, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 5, "end_date": "2021-03-16 19:14:11.000000", "start_date": "2021-03-16 19:14:05.000000"},
{"id": 662642, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 601, "end_date": "2021-03-16 19:14:05.000000", "start_date": "2021-03-16 19:04:03.000000"},
{"id": 662641, "room": "Bedroom 6", "type": "Assistance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 50, "end_date": "2021-03-16 19:00:35.000000", "start_date": "2021-03-16 18:59:45.000000"},
{"id": 662640, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 196, "end_date": "2021-03-16 19:00:37.000000", "start_date": "2021-03-16 18:57:21.000000"},
{"id": 662639, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 601, "end_date": "2021-03-16 18:59:45.000000", "start_date": "2021-03-16 18:49:43.000000"},
{"id": 662638, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 655, "end_date": "2021-03-16 19:00:36.000000", "start_date": "2021-03-16 18:49:40.000000"},
{"id": 662637, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 30, "end_date": "2021-03-16 18:35:15.000000", "start_date": "2021-03-16 18:34:45.000000"},
{"id": 662636, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 8, "end_date": "2021-03-16 18:32:01.000000", "start_date": "2021-03-16 18:31:52.000000"},
{"id": 662635, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 39, "end_date": "2021-03-16 18:26:11.000000", "start_date": "2021-03-16 18:25:32.000000"},
{"id": 662634, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 1678, "end_date": "2021-03-16 18:46:58.000000", "start_date": "2021-03-16 18:19:00.000000"},
{"id": 662633, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 147, "end_date": "2021-03-16 18:16:55.000000", "start_date": "2021-03-16 18:14:28.000000"},
{"id": 662631, "room": "Bedroom 1", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 10, "end_date": "2021-03-16 18:11:58.000000", "start_date": "2021-03-16 18:11:47.000000"},
{"id": 662630, "room": "Bedroom 15", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 303, "end_date": "2021-03-16 18:14:23.000000", "start_date": "2021-03-16 18:09:20.000000"},
{"id": 662629, "room": "Bedroom 1", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 581, "end_date": "2021-03-16 18:11:47.000000", "start_date": "2021-03-16 18:02:06.000000"},
{"id": 662628, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 630, "end_date": "2021-03-16 18:09:16.000000", "start_date": "2021-03-16 17:58:46.000000"},
{"id": 662627, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 825, "end_date": "2021-03-16 18:08:24.000000", "start_date": "2021-03-16 17:54:39.000000"},
{"id": 662626, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 29, "end_date": "2021-03-16 17:46:53.000000", "start_date": "2021-03-16 17:46:24.000000"},
{"id": 662625, "room": "Bedroom 12", "type": "Attendance", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 330, "end_date": "2021-03-16 17:46:24.000000", "start_date": "2021-03-16 17:40:53.000000"},
{"id": 662624, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 477, "end_date": "2021-03-16 17:47:57.000000", "start_date": "2021-03-16 17:39:59.000000"},
{"id": 662623, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 71, "end_date": "2021-03-16 17:40:53.000000", "start_date": "2021-03-16 17:39:42.000000"},
{"id": 662622, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 4246, "end_date": "2021-03-16 18:48:13.000000", "start_date": "2021-03-16 17:37:27.000000"},
{"id": 662621, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 464, "end_date": "2021-03-16 17:29:09.000000", "start_date": "2021-03-16 17:21:24.000000"},
{"id": 662620, "room": "Dining room unit 2", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 890, "end_date": "2021-03-16 17:28:23.000000", "start_date": "2021-03-16 17:13:32.000000"},
{"id": 662619, "room": "Bedroom 2", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 82, "end_date": "2021-03-16 17:00:59.000000", "start_date": "2021-03-16 16:59:37.000000"},
{"id": 662618, "room": "Bedroom 7", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 744, "end_date": "2021-03-16 17:03:53.000000", "start_date": "2021-03-16 16:51:28.000000"},
{"id": 662617, "room": "Bedroom 6", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 1104, "end_date": "2021-03-16 17:03:37.000000", "start_date": "2021-03-16 16:45:12.000000"},
{"id": 662616, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 143, "end_date": "2021-03-16 16:44:18.000000", "start_date": "2021-03-16 16:41:54.000000"},
{"id": 662615, "room": "Bedroom 6", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 198, "end_date": "2021-03-16 16:45:12.000000", "start_date": "2021-03-16 16:41:53.000000"},
{"id": 662614, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 1056, "end_date": "2021-03-16 16:48:51.000000", "start_date": "2021-03-16 16:31:15.000000"},
{"id": 662613, "room": "Bedroom 60", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 26, "end_date": "2021-03-16 16:11:45.000000", "start_date": "2021-03-16 16:11:19.000000"},
{"id": 662612, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 7, "end_date": "2021-03-16 16:10:18.000000", "start_date": "2021-03-16 16:10:11.000000"},
{"id": 662611, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 252, "end_date": "2021-03-16 16:11:05.000000", "start_date": "2021-03-16 16:06:52.000000"},
{"id": 662610, "room": "Bedroom 42", "type": "Attendance", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 379, "end_date": "2021-03-16 16:09:30.000000", "start_date": "2021-03-16 16:03:11.000000"},
{"id": 662609, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 1, "end_date": "2021-03-16 16:02:03.000000", "start_date": "2021-03-16 16:02:01.000000"},
{"id": 662608, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 43, "end_date": "2021-03-16 16:02:27.000000", "start_date": "2021-03-16 16:01:44.000000"},
{"id": 662607, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 279, "end_date": "2021-03-16 16:05:59.000000", "start_date": "2021-03-16 16:01:19.000000"},
{"id": 662606, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 648, "end_date": "2021-03-16 16:08:11.000000", "start_date": "2021-03-16 15:57:22.000000"},
{"id": 662605, "room": "Bedroom 42", "type": "Emergency", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 5, "end_date": "2021-03-16 15:57:16.000000", "start_date": "2021-03-16 15:57:10.000000"},
{"id": 662603, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 1299, "end_date": "2021-03-16 16:10:17.000000", "start_date": "2021-03-16 15:48:38.000000"},
{"id": 662602, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 599, "end_date": "2021-03-16 15:55:33.000000", "start_date": "2021-03-16 15:45:34.000000"},
{"id": 662601, "room": "PIR room 15", "type": "Accessory", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 773, "end_date": "2021-03-16 15:54:58.000000", "start_date": "2021-03-16 15:42:04.000000"},
{"id": 662600, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 183, "end_date": "2021-03-16 15:44:40.000000", "start_date": "2021-03-16 15:41:37.000000"},
{"id": 662599, "room": "room 3", "type": "Call", "unit": null, "zone": "ground floor", "siteid": 2, "duration": 1872, "end_date": "2021-03-16 15:53:12.000000", "start_date": "2021-03-16 15:22:00.000000"},
{"id": 662598, "room": "room 60 pir", "type": "Accessory", "unit": null, "zone": "second floor", "siteid": 2, "duration": 439, "end_date": "2021-03-16 15:18:36.000000", "start_date": "2021-03-16 15:11:16.000000"},
{"id": 662597, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 3140, "end_date": "2021-03-16 15:42:27.000000", "start_date": "2021-03-16 14:50:07.000000"},
{"id": 662596, "room": "Bedroom 2", "type": "Attendance", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 523, "end_date": "2021-03-16 14:56:06.000000", "start_date": "2021-03-16 14:47:22.000000"},
{"id": 662595, "room": "Bedroom 8", "type": "Call", "unit": null, "zone": "Ground Floor", "siteid": 2, "duration": 151, "end_date": "2021-03-16 14:47:21.000000", "start_date": "2021-03-16 14:44:50.000000"},
{"id": 662594, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 10, "end_date": "2021-03-16 14:43:30.000000", "start_date": "2021-03-16 14:43:19.000000"},
{"id": 662593, "room": "Bedroom 12", "type": "Accessory", "unit": null, "zone": "Grounds Floor", "siteid": 2, "duration": 34, "end_date": "2021-03-16 14:43:14.000000", "start_date": "2021-03-16 14:42:39.000000"},
{"id": 662592, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 0, "end_date": "2021-03-16 14:42:11.000000", "start_date": "2021-03-16 14:42:10.000000"},
{"id": 662591, "room": "room 42 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 139, "end_date": "2021-03-16 14:43:17.000000", "start_date": "2021-03-16 14:40:57.000000"},
{"id": 662590, "room": "RM 52 pir", "type": "Accessory", "unit": null, "zone": "Second Floor", "siteid": 2, "duration": 10, "end_date": "2021-03-16 14:38:58.000000", "start_date": "2021-03-16 14:38:48.000000"},
{"id": 662589, "room": "room.  12 pir", "type": "Accessory", "unit": null, "zone": " ground. floor", "siteid": 2, "duration": 5, "end_date": "2021-03-16 14:37:57.000000", "start_date": "2021-03-16 14:37:52.000000"},



];


