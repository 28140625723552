import React, { Component, useEffect, useRef } from "react";
import * as d3 from "d3";
//import {chart as chart1} from "@d3/histogram";
import demoOne from './demodata1';
import { text } from "d3";
import './graphs.css';

const PieChart = () => {

  const gRef = useRef(null);
  //let values = demoOne;
  var values = [10, 5, 12, 15];
         
  console.log("gRef");

  useEffect(()=>{
   
    
    var width = 300;
    var scaleFactor = 20; 
    var barHeight = 30;
 
 var graph = d3.select("gRef.current")
    .append("svg")
    .attr("width", width)
    .attr("height", barHeight * values.length);
 
 var bar = graph.selectAll("g")
    .data(values)
    .enter()
    .append("g")
    .attr("transform", function(d, i) {
       return "translate(0," + i * barHeight + ")";
    });
 bar.append("rect").attr("width", function(d) {
    return d * scaleFactor;
 })
 
 .attr("height", barHeight - 1);
 
 bar.append("text")
    .attr("x", function(d) { return (d*scaleFactor); })
    .attr("y", barHeight / 2)
    .attr("dy", ".35em")
    .text(function(d) { return d; });

  }, [gRef]);

  return (
    <div>
      <div ref={gRef} className="gBarChart">
        {//<div className="bar"> </div>
        }
      </div>
    </div>
  );

}



const getColourFromIndex = (index) => {

  if (index > 5) {
    const inNum = Math.round((index/6),0);
    index = index - (inNum*5);
  }
  
  if (index==0) {return 'red';}
  if (index==1) {return 'blue';}
  if (index==2) {return 'green';}
  if (index==3) {return 'white';}
  if (index==4) {return 'orange';}
  if (index==5) {return 'yellow';}
  


  return 'purple';
}

export default PieChart;