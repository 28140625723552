import React, {useContext} from 'react';
import './avatar.css';


const Avatar = (props) => {

    let initials = "";

    initials = (props.firstName.length>0) ? props.firstName.substring(0, 1) : "";
    initials += (props.surname.length>0) ? props.surname.substring(0, 1) : "";

    let strClass = (props.card==true) ? "user_avatar" : "user_avatar_header";

    return <div className={strClass}>{initials}</div>;
}


export default Avatar;