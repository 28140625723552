import React, { Component, useEffect, useRef } from "react";
import CallReportContainer from '../../CallReportContainer/CallReportContainer';
import FadeIn from '../../FadeIn/FadeIn';

export default class JourneyCell extends React.Component {

    state = {
        showInfo: false
    };

    _onMouseMove(e) {
/*
        this.setState({ 
            x: e.screenX, 
            y: e.screenY,
            showInfo:!this.state.showInfo
        });
*/
    }

    _onMouseOver(e) {
        this.setState({ 
            x: e.screenX, 
            y: e.screenY,
            showInfo:!this.state.showInfo
        });
    }

    _onMouseOut(e) {
        this.setState({ 
            x: e.screenX, 
            y: e.screenY,
            showInfo:!this.state.showInfo
        });
    }

    _onMouseClick(e) {
        this.setState({ 
            x: e.screenX, 
            y: e.screenY,
            showInfo:!this.state.showInfo
        });
    }
    

    render() {
        const tdStyle = {
            width: this.props.width,
            backgroundColor: this.props.color,
        }

        return (

                <td 
                    onMouseOver={this._onMouseOver.bind(this)}
                    onMouseOut={this._onMouseOut.bind(this)}
                    onClick={this._onMouseClick.bind(this)}
                    onMouseMove={this._onMouseMove.bind(this)}
                    style={tdStyle}>
                    {this.state.showInfo ? <ShowDisplayInfo record={this.props.record} type={this.props.type} /> : null}
                </td>
        );
    }
}




export const ShowDisplayInfo=(props)=>{

    const record = props.record;
    const cellInfoStyle = {
        position: "absolute",
        left: "1",//this.state.x,
        top: "1",//this.state.y,
        zIndex:"2"
    }

    const cellDivStyle = {
        color: "darkgrey",
        margin: "0.5em"
    }

    return(
        <FadeIn>
            <div style={cellInfoStyle}>
                <CallReportContainer callType={record.type}>
                    <div style={cellDivStyle} >Start {record.start}</div>
                    <div style={cellDivStyle} >Duration {record.start}</div>
                    <div style={cellDivStyle} >End {record.end}</div>
                </CallReportContainer>
            </div>
        </FadeIn>
    );
}




