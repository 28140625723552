import React, 
{  useState, useRef, useEffect} from 'react';
import axios from 'axios';
import DropSelect from '../components/common/DropSelect/DropSelect';
import DropSelectMulti from '../components/common/DropSelectMulti/DropSelectMulti';
import DataTable from '../components/common/DataTable/DataTable';
import IMG_Print from '../images/icons/print_white.png';
import IMG_Save from '../images/icons/save_white.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import './Assist.css';
import ReactToPrint from "react-to-print";
import {CSVLink} from "react-csv";
import CallPointFilter from '../components/SelectFilters/CallPointFilter';

const AssistDataTable = () => {


  const [zoneSelections, setZoneSelections] = useState({});

  const [reportCollection, setReportCollection] = useState({status:""});

  const [filterOptions, setFilterOptions] = useState({
    firstRun: true,
    data: Array.isArray(localStorage.getItem("reportData")) ? localStorage.getItem("reportData") : [],
    homeOptions: JSON.parse(localStorage.getItem('sites')),
    homeSelection: JSON.parse(localStorage.getItem('sites'))[0].label,
    zoneOptions: JSON.parse(localStorage.getItem('zones')),
    zoneSelections: JSON.parse(localStorage.getItem('zones')),
    callOptions: window.repCallTypes,
    callSelections: window.repCallTypes,
    carerOptions: localStorage.getItem('carers') == "" ? [] : JSON.parse(localStorage.getItem('carers')),
    carerSelections: localStorage.getItem('carers') == "" ? [] : JSON.parse(localStorage.getItem('carers')),
    callPointOptions:localStorage.getItem('callpoints') == "" ? [] : JSON.parse(localStorage.getItem('callpoints')),
    callPointSelections:localStorage.getItem('callpoints') == "" ? [] : JSON.parse(localStorage.getItem('callpoints')),
    start: moment().subtract(7, 'days'),
    end: moment(),  
  });

  const handleEvent = (event, picker)=> {}

  const handleCallback = (start, end) => {
    
    setFilterOptions({ 
    
      firstRun: true,
      data: Array.isArray(localStorage.getItem("reportData")) ? localStorage.getItem("reportData") : [],
      homeOptions: filterOptions.homeOptions,
      homeSelection: filterOptions.homeSelection,
      zoneOptions: filterOptions.zoneOptions,
      zoneSelections: filterOptions.zoneSelections,
      callOptions: filterOptions.callOptions,
      callSelections: filterOptions.callSelections,
      carerOptions: filterOptions.carerOptions,
      carerSelections: filterOptions.carerSelections,
      callPointOptions:filterOptions.callPointOptions,
      callPointSelections:filterOptions.callPointSelections,
      
      start: start,
      end: end,  
    });

  };

  const label = filterOptions.start.format('DD/MM/YYYY') + ' - ' + filterOptions.end.format('DD/MM/YYYY');

  const getDBData = (firstRun) => {
    setReportCollection({status:"collecting"});      
    const homes = firstRun ? filterOptions.homeSelection : filterOptions.homeSelection;
    const zones = firstRun ? filterOptions.zoneOptions.map((v) => v.value) :  filterOptions.zoneSelections.map((v) => v.value);
    const calltypes = firstRun ? filterOptions.callSelections.map((v) => v.value) : filterOptions.callSelections.map((v) => v.value);
    const carers = filterOptions.carerSelections ? filterOptions.carerSelections.map((v) => v.value) : [""];
    const callPoints = filterOptions.callPointSelections ? filterOptions.callPointSelections.map((v) => v.value) : [""];
    let sData = {};
    
    if (carers.length==0) {carers.push("");}

    if (firstRun) {
      sData = {
        "user": localStorage.getItem("userEmail"),
        "userKey": localStorage.getItem("userEmail"),
        "homes": homes,
        "zones" : zones,
        "calltypes" : calltypes,
        "carers" : carers,
        "callpoints" : callPoints,
        "startDate" : moment(filterOptions.start).format('YYYY/MM/DD'),
        "endDate" : moment(filterOptions.end).format('YYYY/MM/DD')
      };
    }
    else {
      sData = {
        "user": localStorage.getItem("userEmail"),
        "userKey": localStorage.getItem("userEmail"),
        "homes": homes,
        "zones" : zones,
        "calltypes" : calltypes,
        "carers" : carers,
        "callpoints" : callPoints,
        "startDate" : moment(filterOptions.start).format('YYYY/MM/DD'),
        "endDate" : moment(filterOptions.end).format('YYYY/MM/DD')
      };
    } 
    
  
    axios.post("/reports/read", sData)
    .then(res => {
      localStorage.setItem("reportData", JSON.stringify(res.data));

      setFilterOptions({ 

        data: res.data,
        homeOptions: filterOptions.homeOptions,
        homeSelection: filterOptions.homeSelection,
        zoneOptions: filterOptions.zoneOptions,
        zoneSelections: filterOptions.zoneSelections,
        callOptions: filterOptions.callOptions,
        callSelections: filterOptions.callSelections,
        carerOptions: filterOptions.carerOptions,
        carerSelections: filterOptions.carerSelections, 
        callPointOptions:filterOptions.callPointOptions,
        callPointSelections:filterOptions.callPointSelections,
        start: filterOptions.start,
        end: filterOptions.end,      

      });
      setReportCollection({status:""});
    });
  
}


const changeSite = (site) => {

  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: site,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,

    start: filterOptions.start,
    end: filterOptions.end,
  });
}

const changeZone = (zones) => {
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: zones,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,

    start: filterOptions.start,
    end: filterOptions.end,
  });

}

const ChangeCarer = (carer) => {
  
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:filterOptions.callPointSelections,

    carerSelections: carer,
    start: filterOptions.start,
    end: filterOptions.end,

  });

}


const ChangeCallPoint = (points) => {
  
  setFilterOptions({
    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: filterOptions.callSelections,
    carerOptions: filterOptions.carerOptions,
    callPointOptions:filterOptions.callPointOptions,
    callPointSelections:points,
    carerSelections: filterOptions.carerSelections,
    start: filterOptions.start,
    end: filterOptions.end,

  });

}


const ZoneSelection = (props) => {
  return <DropSelectMulti options={filterOptions.zoneOptions} selections={filterOptions.zoneSelections} filterCall={changeZone} />     
}

const ChangeCallTypes = (calltypes) =>{
  
  setFilterOptions({

    firstRun: false,
    data: filterOptions.data,
    homeOptions: filterOptions.homeOptions,
    homeSelection: filterOptions.homeSelection,
    zoneOptions: filterOptions.zoneOptions,
    zoneSelections: filterOptions.zoneSelections,
    callOptions: filterOptions.callOptions,
    callSelections: calltypes,
    carerOptions: filterOptions.carerOptions,
    carerSelections: filterOptions.carerSelections,
    
    callPointOptions: filterOptions.callPointOptions,
    callPointSelections: filterOptions.callPointSelections,
     
    start: filterOptions.start,
    end: filterOptions.end,

  });
}

const CallTypeSelection = (props) => {  
  return <DropSelectMulti options={window.repCallTypes} selections={filterOptions.callSelections} filterCall={ChangeCallTypes} />
}

const CarerSelection = (props) => {
  return <DropSelectMulti options={filterOptions.carerOptions} selections={filterOptions.carerSelections} filterCall={ChangeCarer} />
}


const HomeSelection = (props) =>{   
  return <DropSelect options={filterOptions.homeOptions} selectedVal={filterOptions.homeSelection} changeHandler={changeSite} />
}


useEffect(() => {
  console.log("Getting DB data");
  getDBData(true);    

}, []);


  const componentRef = useRef();

  const strDate = Date().toString();
  const ids = [1];

  return (
    <div id="AIDA_NAV_MAIN">
      <div id="AIDA_NAV" className="AIDA_NAV">
 
        <p>Home</p>
        <HomeSelection />
        <p>Zone</p>
        <ZoneSelection />
  
        <br />
        <p>Call Type</p>
        <CallTypeSelection />
  
        <br />
        <p>Call Point</p>
        <CallPointFilter options={filterOptions.callPointOptions} selectedVal={filterOptions.callPointSelections} callback={ChangeCallPoint} />
  
        <br />
        <p>Carer</p>
        <CarerSelection />

        <br />
        <DateRangePicker
        onEvent={handleEvent} 
        onCallback={handleCallback}
        initialSettings={{
          timePicker: true,
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Year To Date': [
              moment().subtract(1, 'year').toDate(),
              moment().toDate(),
            ],
          },
        }}
>

        <div>
          <p>Dates</p>
          <div className="inputTimeDisplay">{label}</div>
        </div>

        </DateRangePicker>

        <div className="navbuttons">
          <br />
          <button className="submit" onClick={(e)=>getDBData(false)}>Submit</button>
        
          <div className="save_print">

              <CSVLink data={filterOptions.data}><button className="save"><img alt="Card image" className="save" src={IMG_Save} /></button></CSVLink>

              <ReactToPrint
                trigger={() => <button onClick={(e) => window.print()} className="print"><img alt="Card image" className="print" src={IMG_Print} /></button>}
                content={() => componentRef.current}
              />

          </div>
        </div>

      </div>  
    
        <div className="AIDAMAINTCONTENT">
          <div className="data_table_holder">
          
            <GetDataStatus 
              status={reportCollection.status} 
              componentRef={componentRef} 
              strDate={strDate} 
              data={filterOptions.data} 
              homeSelection={filterOptions.homeSelection}
              />

            
          </div>
        </div>
    </div>
  );
}

const tickerStyle = {
  marginLeft:"40%",
  marginRight:"40%",
  marginTop:"5%",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"

}

const GetDataStatus=({status, data, componentRef, strDate, homeSelection})=>{
  if (status=="collecting") return <div style={tickerStyle}><div className="loader"></div></div>;
  if (Array.isArray(data)){
    if (data.length==0) {
      return <div style={tickerStyle}>The report selections have not returned any data.</div>;
    }
    else {
      return <DataTable 
                ref={componentRef} 
                key={strDate} 
                data={data} 
                house={homeSelection}
              />;
    }
  }
  return <div></div>;
//  return <div>An unexpected outcome has occurred please report this and quote code AIDA200</div>;
}

export default AssistDataTable;

