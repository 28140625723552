import React, {Component, useState, useContext } from "react";
import ResidentHeader from './header';

import ResidentCarePlan from '../ResidentCarePlan/ResidentCarePlan'
import CareCalendar from '../../carecalendar/carecalendar';
import CareCalendarMob from '../../carecalendarmob/carecalendarmob';
import CareCalendarWeb from '../../carecalendarweb/carecalendar';
import Scroller from '../../common/scroller/scroller';
import ResidentForm from '../ResidentForm/ResidentFormEdit';


const Resident = (props) => {
  
    const [residentView, setResidentView] = useState('Resident Form');
    const initialState = {
  
      id:  props.selection ? props.selection.res_id : "",
      name: props.selection ? props.selection.res_first_name : "",
      surname: props.selection ? props.selection.res_surname: "",
      preferredName: props.selection ? props.selection.res_preferred_name : "",
      dob: props.selection ? props.selection.res_dob : "",
      age: props.selection ? props.selection.res_age : "",
      room: props.selection ? props.selection.rm_name : "",
      nameError: "",
      surnameError: "",
      preferredNameError: "",
      dobError: "",
      ageError: "",
      roomError: "",
      onCancelHandler: props.cancelHandler
  
    }
  
    const repList = [
      { name: 'Careplan' },
      { name: 'Resident Form' },
    ];
    
    const updateSelected = (selectedVal) => {
      setResidentView(selectedVal);
      console.log("NEW SELECTION:" + selectedVal);
    }
  
    return(
        
      <div className="resident_summary_container">
        <ResidentHeader selection={props.selection} />
        {
          //<div style={{width:"900px"}}>
  }
        <Scroller 
          updateFunction={updateSelected} 
          list={repList}
          selected={residentView}
        />
  
        <br />
  
        {residentView=="Calendar" ? <CareCalendar /> : null}
        {residentView=="Careplan" ? <ResidentCarePlan /> : null}
        {residentView=="Resident Form" ? <ResidentForm initialState={initialState} cancelHandler={props.cancelHandler} /> : null}
        {residentView=="Calendar Mob" ? <CareCalendarMob /> : null}        
        {residentView=="Calendar Web" ? <CareCalendarWeb /> : null}
        
      </div>
  
    );
          
  }

  export default Resident;