import React, {useContext, Component, useState, useRef} from 'react';


const ArquellaNotifier = () => {

  Notification.requestPermission(function(status) {
    console.log('Notification permission status:', status);
  });

//  displayNotifications();
  
  return <p>Test Notifier</p>;
}


async function displayNotifications() {
  if (Notification.permission === "granted") {
    await navigator.serviceWorker.register.showNotification("test");
  }
}




async function displayNotification() {
  if (Notification.permission == 'granted') {
    
    await navigator.serviceWorker.getRegistration().then(function(reg) {

      var options = {
        body: 'Here is a notification body!',
        icon: 'images/example.png',
        vibrate: [100, 50, 100],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1
        }
      };
      reg.showNotification('Hello world!', options);
    });
  }
}



export default ArquellaNotifier;

