//npm install --save react-horizontal-scrolling-menu
import { select } from 'd3-selection';
import React, { Component, useState } from 'react';
import './scroller.css';
 
const Scroller = (props) =>  {

  // list of items
  const list = props.list;
  
  // One item component
  // selected prop will be passed
  const MenuItem = ({text, selected}) => {

    let cls = selected ? "menu-item_selected" :"menu-item";
    
    return <div
      className={cls}
      onClick={()=>{onSelect(text);}}
      >{text}</div>;
  };
 
  // All items component
  // Important! add unique key
  const Menu = (list, selected) =>
    list.map(el => {
      const {name} = el;
      
      return <MenuItem 
                      text={name} 
                      key={name} 
                      selected={(name == selected) ? selected : ""}
              />;
  });
 
 
  const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
  };
 
  
  const [state, setState] = useState({
    selected: props.selected ? props.selected : list[0],
  });

 
  const onSelect = (key) => {
    setState({ selected: key });
    props.updateFunction(key); 
  }
 

    // Create menu from items
    const menu = Menu(list, state.selected);
 
    return (
      
      <div style={{display:"flex", flexDirection:"row", alignSelf:"center"}}>
        {menu}
      </div>
    );

}

export default Scroller;