import React, {useContext} from 'react';
import './formval.css';

const initialState = {

    name: "",
    email: "",
    password: "",
    nameError: "",
    emailError: "",
    passwordError: ""
    
}


export default class FormVal extends React.Component {


    state = initialState;

    handleChange = event => {
        console.log(event.target.name);
        const isCheckBox = event.target.type === "checkbox";
        this.setState({
            [event.target.name]:isCheckBox
                ? event.target.checked
                : event.target.value
        });

    }

    validate = () => {

        let nameError = "";
        let emailError = "";
        let passwordError = "";
        
        if (!this.state.email.includes("@")) {
            emailError = "invalid email";
        }

        if (emailError) {
            this.setState({ emailError });
            return false;
        }

        return true;
/*
        if (!this.state.email.includes("@")) {
            emailError = "invalid email";
        }

        if (!this.state.email.includes("@")) {
            passwordError = "invalid email";
        }
*/  
    }


    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            console.log(this.state);
        }
        
    }


    render() {

        return(
            <div>

                <p>FormVal</p>

                <form onSubmit={this.handleSubmit}>

                    <div>
                        <input 
                            name="name"
                            placeholder="Name"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        {
                        this.state.nameError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.nameError}</div>)
                        : null
                        }
                        
                    </div>

                    <div>
                        <input 
                            name="email"
                            placeholder="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        {
                        this.state.emailError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.emailError}</div>)
                        : null
                        }
                        
                    </div>

                    <div>
                        <input 
                            name="password"
                            placeholder="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                        {
                        this.state.emailError ? (<div style={{ fontSize: 12, color: "red" }}>{this.state.passwordError}</div>)
                        : null
                        }
                    </div>

                    <button type="submit" >Submit</button>

                </form>


            </div>

        );

    }

}
