import React, {useContext, Component, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './firebase/provider/AuthProvider';
import {BrowserRouter} from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import './globals';
import './arquella.css';
import './arquella copy.css';
import {getCLS, getFID, getLCP} from 'web-vitals';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const boolDemo = true;
const localDemo = false;

if (boolDemo) {
  if (localDemo) {
    axios.defaults.baseURL = "http://localhost:8180/aida/";  //php
//    axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:8180/';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
  }
  else {
    axios.defaults.baseURL = "https://addatabridge.co.uk/aida_demo/";
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://adcaidatest.addatabridge.co.uk';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://demo.arquella.care/';
  }
}
else {
  axios.defaults.baseURL = "https://addatabridge.co.uk/aida/";
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://arquella.care';
}

axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

axios.interceptors.request.use(request => {
  if (boolDemo) console.log(request);
  return request;
}, error => {
  //console.log("API REQUEST ERROR:::");
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  //console.log("API RESPONSE ERROR:::");
  //console.log(error);
  return Promise.reject(error);
});

ReactDOM.render(
<BrowserRouter>
<AuthProvider>
  <App />
</AuthProvider>
</BrowserRouter>,
  document.getElementById('root')
);

console.log("App version :: " + global.appVersion)
//serviceWorker.unregister();
serviceWorker.register();
getCLS(console.log);
getFID(console.log);
getLCP(console.log);
