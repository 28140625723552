import {Bar, Chart} from 'react-chartjs-2';
import React, {useState} from "react";

const BarChart = (props) => {

    let yLabel = props.yLabel;
    let xLabel = props.xLabel;
    let showLegend = props.showLegend ? props.showLegend : false;
    let barThickness = props.barThickness ? props.barThickness : 8;
    let categorySpacing = props.categorySpacing ? props.categorySpacing : 1;

    return(
        <div key="2" className="report_card">
            
            <p className="chartTitle">{props.title}</p>


            <Bar
                data={props.data}
                width={700}
                height={400}
                options={{
                    responsive: true,
                    legend: {
                        display: showLegend,
                      },
                    maintainAspectRatio: true,
                    scales: {
                    yAxes: [{
                        scaleLabel: {
                        display: true,
                        labelString: yLabel
                        },
                    }],
                    xAxes: [{
                        scaleLabel: {
                            //barThickness : 10,
                            //categorySpacing: categorySpacing,
                      //      barPercentage: 0.5, 
                            display: true,
                            labelString: xLabel
                        },
                    }]

                }
                }}

            />

        </div>    
  );

}


export default BarChart;