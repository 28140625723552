import React, {useContext} from 'react';
import '../../arquella.css';
import '../../css/mainmenu.css';
import '../../css/dashboard.css';
import '../reporting.css';
import IMG_ASSIST from '../../images/icons/aida_assist.png';
import IMG_CARE from '../../images/icons/aida_care.png';
import IMG_MEDS from '../../images/icons/aida_meds.png';
import IMG_TEAM from '../../images/icons/aida_team.png';
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';
import IMG_MAINTENANCE from '../../images/icons/aida_settings.png';
import IMG_SUPPORT from '../../images/icons/aida_support.png';
import IMG_LOGO from '../../images/whiteLogo.png';
import Care from '../care/Care';
import AssistDataTable from '../../assist/AssistTable';
import AIDATeam from '../../teams/TeamMembers';
import {CareNavOptions, AssistNavOptions} from './MainNavBar/MainNavBar';
import {firebaseAuth} from '../../firebase/provider/AuthProvider';
import ResidentReport from '../../pages/ResidentReport/Resident_Report';
import settings from '../settings/settings';
import GeneralSummaryReport from '../reports/Report_General_Summary';
import CPResidentGraphs from '../reports/careplan_resident_graphs';
import CallSupport from '../CallSupport';
import SupportScreen from '../support/support';
import ShowPieChart from '../../samples/PieChart/piesample';
import FormVal from '../../samples/Form/form';
import DataTable from '../common/DataTable/DataTable';
import {DropSelectTest} from '../common/DropSelect/DropSelect';
import UserManagement from '../../UserManagement/UserManagement';
import axios from 'axios';
import CareCalendar from '../carecalendar/carecalendar';
import CareCalendarMob from '../carecalendarmob/carecalendarmob';
import CareCalendarWeb from '../carecalendarweb/carecalendar';
import CareCalendarTimeline from '../carecalendartimeline/carecalendar';
import GetCareplan from '../mob/careplan/careplan';
import MobCare from '../mob/mobResidents';
import ArquellaNotifier from '../notifications/notifications';
import AGraphs from '../graphs/graphs';
import DemoViewer from '../../widget_library/demoviewer';
import CareDataTable from '../care/reports/datatablereport/CareTable';
import CareGraphs from '../care/reports/CareGraphs/CareGraphs';
import {  BrowserRouter, Route, Link  } from "react-router-dom";
import ArqDashboard from '../../pages/Dashboard/Dashboard';
import SearchNav from '../SearchNav/SearchNav';
import NodeTest from '../../nodetesting/nodetest';
import CareDashboard from '../care/dashboard/caredashboard';
import CareCallController from '../../pages/CareCallController/CareCallController';
import './MenuItems.css';

const AIDAHeader = (props) => {

return (

<div className="AIDA_HEADER" id="AIDA_HEADER">

      <div className="header_logo_menu">

        <Link to="/"><img  alt="Arquella Logo" className="header_logo" src={IMG_LOGO} /></Link>
    
        <AIDAHeaderMenu>

          <Route path={[
              "/assist", 
              "/dashboard", 
             ]}>
              <AssistNavOptions selection="Dashboard" />
            </Route>
            <Route path={[
              "/repgraphs", 
              "/rep_res_call_data", 
              "/report"
            ]}>
              <AssistNavOptions selection="Reports" />
            </Route>
{            //component={AssistNavOptions} />
}
          <Route path={["/care"]} component={CareNavOptions} />
           
        </AIDAHeaderMenu>

      </div>
      
      <UserOptions />
    
    </div>  
  
  );
}


const AIDAHeaderMenu = (props) => {
  return (
    <div className="HEADER_MENU" id="HEADER_MENU">
      {props.children}
    </div>
  );
}


function MainMenu() {

  return (

    <BrowserRouter>

      <Route exact path="/">
        <MainSetup>
          <DisplayMenuItems />
        </MainSetup>
      </Route>


      <Route exact path="/node" component={NodeTest} />        
      <Route exact path="/sample/pie" component={ShowPieChart} />
      <Route exact path="/sample/dropdown" component={DropSelectTest} />
      <Route exact path="/sample/form" component={FormVal} />
      <Route exact path="/sample/datatable" component={DataTable} />
      <Route exact path="/sample/login" component={UserManagement} />
      <Route exact path="/adc" component={CallSupport} />
      <Route path="/dev/graphs" component={AGraphs} />
      <Route path="/dev/notifier" component={ArquellaNotifier} />
      <Route path="/dev/carecalls" component={CareCallController} />    
      
      <Route path={[
        "/mob/careplan", 
        "/dev/carecalendar",
        "/dev/carecalendarmob",
        "/dev/carecalendartimeline",
        "/dev/carecalendarweb",
        "/widgets/demo",
        "/widgets/demo2",
        "/mob/mobcare"
        ]}>

        <MobileSetup>
          
          <Route path="/mob/mobcare" component={MobCare} />          
          <Route path="/mob/careplan" component={GetCareplan} />
          <Route path="/dev/carecalendar" component={CareCalendar} />
          <Route path="/dev/carecalendarmob" component={CareCalendarMob} />
          <Route path="/dev/carecalendartimeline" component={CareCalendarTimeline} />
          <Route path="/dev/carecalendarweb" component={CareCalendarWeb} />
          <Route path="/widgets/demo" component={DemoViewer} />
          <Route path="/widgets/demo2" component={CPResidentGraphs} />
          
        </MobileSetup>
        
      </Route>

      <Route path="/care">
        <MainSetup>
          
          <Route exact path="/care" component={Care} />
          <Route path="/care/reports/table" component={CareDataTable} />
          <Route path="/care/reports/chart" component={CareGraphs} />
          <Route path="/care/reports/residentcaresummary" component={CPResidentGraphs} />
          <Route path="/care/dashboard" component={CareDashboard} />
          

        {
          //ResidentCareSummary
        }

        </MainSetup>
      </Route>
      
      <Route path={[
        "/report", 
        "/rep_res_call_data", 
        "/maintenance", 
        "/about",
        "/team",
        "/assist",
        "/dashboard",
        "/repgraphs",
        "/dev/searchnav"
        ]}>
        
          <MainSetup>
          
            <Route exact path="/repgraphs" component={GeneralSummaryReport} />
            <Route path={["/report"]} component={AssistDataTable} />
            <Route path="/rep_res_call_data" component={ResidentReport} />
            <Route path="/maintenance" component={settings} />
            <Route path="/care" component={Care} />
            <Route path="/about" component={SupportScreen} />
            <Route path="/team" component={AIDATeam} />
            <Route path={["/assist","/dashboard"]} component={ArqDashboard} />
            <Route path="/dev/searchnav" component={SearchNav} />  

          </MainSetup>
        
      </Route>

    </BrowserRouter>
      
);

}


const MainSetup = (props) => {
  return(
        <div>  
            <AIDAHeader />
            <div id="AIDA_CONTENT">
              {props.children}  
            </div>     
        </div>
  );
}


const MobileSetup = (props) => {
  return(
        <div>  
{
              //<AIDAHeader />
}
            <div id="AIDA_MOB_CARE_HEADER">
              {props.children}  
            </div>     
        </div>
  );
}

//<Link to="/care"><MenuItem menuname="Care" imgPath={IMG_CARE} /></Link>
//<Link to="/team"><MenuItem menuname="Team" imgPath={IMG_TEAM} /></Link>


function SiteItems () {
  localStorage.setItem("userKey", localStorage.getItem("userEmail"));
  let sData = {"userkey": localStorage.getItem("userEmail")};
  axios.post("/user/lists", sData  
  )
  .then(response => {localStorage.setItem('sites', JSON.stringify(response.data.homes));});
}

function setPermissions(path){
    let sData = {
      "user": localStorage.getItem("userEmail"),
      "userkey": localStorage.getItem("userEmail")
    };
    axios.post(path, sData)
    .then(response => {
      let r = response.data;
      if ((r == "")||(r == null)) {r = "[]"}
      if (Array.isArray(r)) {
        r.map(pm=>{
          localStorage.setItem(pm.pm_name, true)
        });
      }
    }).catch(response=>{
      console.log(response);
    });
}


function updateDBValues(path, updateItem) {
  localStorage.setItem("userKey", localStorage.getItem("userEmail"));
  let sData = {
    "user": localStorage.getItem("userEmail"),
    "userkey": localStorage.getItem("userEmail")
  };
  axios.post(path, sData  
  )
  .then(response => {
    let r = JSON.stringify(response.data);
    if ((r == "")||(r == null)) {r = "[]"}
    localStorage.setItem(updateItem, r);
  });
}

function updateLocalStorage (sites, zones, carers, permissions, callpoints, careResidents, value, selectedDate) {

  localStorage.setItem('singleSelectedDate', selectedDate);
  localStorage.setItem('sites', sites);
  localStorage.setItem('repSelectSite', sites);
  localStorage.setItem('zones', zones);
  localStorage.setItem('carers', carers);
  localStorage.setItem('callpoints', callpoints);
  localStorage.setItem('careResidents', careResidents);
  
  let aqp = JSON.parse(permissions);
  
    aqp.map(pm=>{
      localStorage.setItem(pm.pm_name, true)
    });

}


function DisplayMenuItems () {
  return (
    <div>
        
      <div className="main_menu_cards">
          
        <Link to="/assist"><MenuItem menuname="Assist" imgPath={IMG_ASSIST} /></Link> 
        {
          //<Link to="/care"><MenuItem menuname="Care" imgPath={IMG_CARE} /></Link>
          //<MenuItem menuname="Care" imgPath={IMG_CARE} />
        }
        <MenuItem menuname="Care" imgPath={IMG_CARE} />
        <MenuItem menuname="Meds" imgPath={IMG_MEDS} />
      </div>

      <img className="aida_logo_main" alt="Card image" src={IMG_AIDA_LOGO} />
      
      <div className="main_menu_cards">
      
        <Link to="/team"><MenuItem menuname="Team" imgPath={IMG_TEAM} /></Link>
        <MenuItem menuname="Maintenance" imgPath={IMG_MAINTENANCE} />
        <Link to="/about"><MenuItem menuname="Support" imgPath={IMG_SUPPORT} /></Link>
      </div>
    </div>
  ); 
}



function MenuItem(props) {


  let cName = "menu_item_card";
  if ((props.menuname == "Maintenance") || 
          (props.menuname == "Meds") 
          || (props.menuname == "Care") 
//          || (props.menuname == "Assist")
  
  ) 
  {
    cName = cName + " disabled"; 
  }
  //onClick="napge('mnuCALL');"
  return(
  <div className={cName} >
    <span>{props.menuname}</span>
    <img alt="Card image" src={props.imgPath} />
  </div>
  );
}


function UserOptions() {

  const {handleSignout,} = useContext(firebaseAuth)
////user login - signout options
  return (
    <div className="signout">
      <button onClick={h=>{
        /*
        localStorage.setItem("userEmail", "");
        localStorage.setItem('sites', "");
        localStorage.setItem('repSelectSite', "");
        localStorage.setItem('zones', "");
        localStorage.setItem('carers', "");
        */
        handleSignout();
      }}>  Sign out </button>
    </div>
  );
}


export default MainMenu;