import React, {useContext, useState} from 'react';
import './TextEntry.css';
import axios from 'axios';


export default class TextEntry extends React.Component {

    state = {
        originalValue: this.props.value,
        value: this.props.value,
        id: this.props.id
    }

    dbUpdate() {
        let sData = {
            "userkey": localStorage.getItem("userEmail"),
            "action" : "",
            "formJSON" : {
                "entry_id" : this.state.id,
                "entry_value" : this.state.value,
                "entry_date" : Date()
            }
          };
          axios.post("/care/active/entryvalue.php", sData)
          .then(response => {

          })
          .then(response => {
          });
    }

    render() {

        return(
            <div className="careplan_text_entry_container">
            <input 
                className="careplan_text_entry"
                onChange={e =>{
                    this.setState({value: e.target.value});
                }}
                onBlur={e=>{
                    let v = e.target.value;
                    if (v!=this.state.originalValue){
                        this.setState({value: e.target.value});
                        this.dbUpdate();
                    }
                }}
                value={this.state.value}
            />
            </div>
        );

    }

}