
import React, {Component, useState, useContext } from "react";
import './avatar.css';

const GetAvatar = (props) => {
    let init1 = "";
    let init2 = "";
    
    try {
      init1 = props.firstname.substring(0, 1);
    } catch(err){console.log("Error getting values1: " + err);}
    
    try {
      init2 = props.surname.substring(0, 1);
    } catch(err){console.log("Error getting values2: " + err);}
    
    let init = init1 + init2;
    
    return (
      <div className="avatar">
        <label className="avatar">{init}</label>
      </div>
    );
  }

export default GetAvatar;