//https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html#position
//tooltip example
import React, { Component, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import './graph.css';
import ArquellaBarChart from '../ArquellaBarChart/ArquellaBarChart';
import ArquellaBarChartShiftSplit from '../ArquellaBarChartShiftSplit/ArquellaBarChartShiftSplit';
import CallReportContainer from '../CallReportContainer/CallReportContainer';
import WeekMonthDayToggle from '../WeekMonthDayToggle/WeekMonthDayToggle';
import CallTypeToggle from '../CallTypeToggle/CallTypeToggle';
import SetKPIValue from '../SetKPIValue/SetKPIValue';
import moment from "moment";
import { filter } from "d3";

const mList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const dList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const callGraphColours = {
  Call: "rgb(245, 129, 78)",
  Emergency : "red",
  Assistance : "rgb(248, 218, 60)",
  Attendance : ['rgb(148, 202, 102)'],
  Accessory : ["purple"] 
}


const BarChartAboveAverageResponse=(props)=> {

  const [data, setData] = useState(props.data ? props.data : []);
  const [filters, setFilter] = useState({
    callType :  "Emergency",
    timePeriod: "Today",
    kpi: "1:30",
    startDate : moment(),
    endDate : moment()
  });
  
  function kpiCallBack(value){
    let pos = 0;
    console.log("VVALU:" +value);
    try {
      pos = value.indexOf(":");
    } catch(e){}
    
    if (pos > 0) {
      let dArr = value.split(":");
      const mins = (parseInt(dArr[0])*60);
      let secs = dArr[1];
      if (secs.length == 1) {
        secs = secs + "0";
      }
      value = mins + parseInt(secs);
    }

    setFilter({
      callType: filters.callType,
      timePeriod: filters.timePeriod,
      kpi: value,
      startDate: filters.startDate,
      endDate: filters.endDate
    });
  }
  
  
  const style = {
      width: "100%",
      display:"flex",
      flexDirection:"row",
      justifyContent: "space-between"
  }
  
  function callTypeToggleCallBack (value) {
    setFilter({
      callType: value,
      timePeriod: filters.timePeriod,
      kpi: filters.kpi,
      startDate: filters.startDate,
      endDate: filters.endDate
    });
  }

  function callBackPeriod (value) {
    let sDate;
    let eDate;
    if (value=="Today") {
      sDate = moment().set('hour', "0").set("minute", "0").set("second", "0");
      eDate = moment().set('hour', "23").set("minute", "59").set("second", "59");
    }
    if (value=="Week") {
      sDate = moment().subtract(7, "d").set('hour', "0").set("minute", "0").set("second", "0");
      eDate = moment().endOf("d").set('hour', "23").set("minute", "59").set("second", "59");
    }
    if (value=="Month") {
      sDate = moment().startOf("year");
      eDate = moment().endOf("year");
    }

    setFilter({
      callType: filters.callType,
      timePeriod: value,
      kpi: filters.kpi,
      startDate: sDate,
      endDate: eDate
    });
  }

  function calculateMinutes(strDate) {
      const hrs = moment(strDate, "HH:mm:ss").hours();
      let mins = moment(strDate, "HH:mm:ss").minutes();
      const seconds = moment(strDate, "HH:mm:ss").seconds();
      
      mins = mins + (hrs*60);
      if (seconds > 30) mins = mins + 1;
      
      return mins;
  }

  function calculateAverage (topVal, bottomVal) {
    return (topVal/bottomVal);
  }

  function addToAverage(avrValue, valueToAdd, numberOfValues) {

    return avrValue + ((valueToAdd - avrValue) / numberOfValues);

  }


  function getShiftFromTime (timeVal) {

    let dtHour = parseInt(moment(timeVal, "HH:mm:ss").hours());

    if ((dtHour == 22) || (dtHour == 23) || (dtHour == 0) || (dtHour == 1) || (dtHour == 2) || (dtHour == 3) || (dtHour == 4) || (dtHour == 5)) {
      return "Night"
    }

    if ((dtHour == 6) || (dtHour == 7) || (dtHour == 8) || (dtHour == 9) || (dtHour == 10) || (dtHour == 11) || (dtHour == 12) || (dtHour == 13)) {
      return "Day"
    }

    if ((dtHour == 14) || (dtHour == 15) || (dtHour == 16) || (dtHour == 17) || (dtHour == 18) || (dtHour == 19) || (dtHour == 20) || (dtHour == 21)) {
      return "Afternoon"
    }

    //night 10-6am
    //day   6-2pm
    //afternoon 2-10pm

  }


  function previous () {
    
    let sDate = filters.startDate;
    let eDate = filters.endDate;
    

    if (filters.timePeriod=="Today") {
      
      sDate = moment(sDate).subtract(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
      
    }

    if (filters.timePeriod=="Week") {
      sDate = moment(sDate).subtract(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }

    if (filters.timePeriod=="Month") {
      sDate = moment(sDate).subtract(1, "month").startOf("month").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).endOf("month").set("hour", "23").set("minute", "59").set("second", "59");
    }

    setFilter({
      timePeriod: filters.timePeriod,
      callType :  filters.callType,
      kpi: filters.kpi,
      startDate : sDate,
      endDate : eDate
    });
    

  }

  function next () {
    
    let sDate = filters.startDate;
    let eDate = filters.endDate;
    
    if (filters.timePeriod=="Today") {
      
      sDate = moment(sDate).add(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
      
    }
    
    if (filters.timePeriod=="Week") {
      sDate = moment(sDate).add(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }

    if (filters.timePeriod=="Month") {
      sDate = moment(sDate).add(1, "month").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }


    setFilter({
      callType: filters.callType,
      timePeriod: filters.timePeriod,
      kpi: filters.kpi,
      startDate : sDate,
      endDate : eDate
    });
    
  }

  const GetDateDisplay = () => {

    if (filters.timePeriod=="Today") {
      return (<span>{filters.startDate.format("ddd DD MMM YYYY")}</span>);
    }

    if (filters.timePeriod=="Week") {
      return (<span>{filters.startDate.format("DD/MM/YYYY")} - {filters.endDate.format("DD/MM/YYYY")}</span>);
    }

    if (filters.timePeriod=="Month") {
      return (<span>{filters.startDate.format("MMMM YYYY")} - {filters.endDate.format("MMMM YYYY")}</span>);
    }


  }

  function getAverage(top, bottom) {
    let tmp = top/bottom;
    if (tmp.toString() =="NaN"){
      tmp = 0;
    };
    return tmp;
  }

  
  function filterDay(allData, start, end) {
    let rData = [];
    let hours = [];
      
      hours.length = 24;

      for (let i=0; i<24; i++) {
        hours[i] = {
          counter: 0,
          total: 0
        };
      }

      allData.map((d,i)=>{
        
        const dDate = moment(d["Date"] + " " + d["Start Time"], "DD MMMM YYYY HH:mm");
        
        if (filters.callType == d["Call Type"]) {
          
          if (dDate.isBetween(start, end)) {
            
            let callSecs = parseInt(d["Call Duration Mins"]);

            if (callSecs > filters.kpi) {
              rData.push({
                zone: d["Call Point"],
                calls: parseInt(d["Call Duration Mins"]) //getAverage(parseInt(h.total), parseInt(h.counter)),
              });
            }
            
          }
          
        }        
      });
   
    return rData;
  }

  function filterWeek(allData, start, end) {
    let rData = [];

    startDate = moment(start).set('hour', "0").set("minute", "0").set("second", "0").subtract(7, "d");
    endDate = moment(end).set('hour', "23").set("minute", "59").set("second", "59");

    let days = [];
    let dayLabel = [];
    let dayCounter = [];

    let s = startDate.format("DD/MM/YYYY");

    for (let i=0; i<8; i++) {
      let lbl = moment(s, "DD/MM/YYYY").add(i, "days").format("ddd DD MMMM YY");
      days[i] = 0;
      dayLabel[i] = lbl;
      dayCounter[i] = 0;
    }
  
    allData.map((d)=>{

      let dt; 
      
      try {
        dt = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm");
      } catch (e){console.log("Error getting date")}

      if (filters.callType == d["Call Type"]) {
        
        if (dt.isBetween(startDate, endDate)) {

          let callSecs = parseInt(d["Call Duration Mins"]);

          if (callSecs > filters.kpi) {
            let dayIndex = (dt.diff(endDate, "days") + 7);
            let count = parseInt(days[dayIndex]);
            days[dayIndex] = (parseInt(days[dayIndex]) + parseInt(d["Call Duration Mins"]));
            dayCounter[dayIndex] = count + 1;
          }
        }
      }

    });

    days.map((h,i)=>{
      rData.push({
          zone: dayLabel[i],
          calls: getAverage(days[i], dayCounter[i]) 
       });
    });
  
    return rData;
  }

  function filterMonth(allData, start, end) {
   
    let rData = [];

    startDate = moment(filters.startDate).startOf("day");
    endDate = moment(filters.startDate).endOf("day");

    let dayDiff = moment().diff(moment().startOf("month"), "days");

    endDate = moment().set('hour', "23").set("minute", "59").set("second", "59");

    let days = [];
    let dayLabel = [];
    let dayCounter = [];

    let s = startDate.format("DD/MM/YYYY");

    for (let i=0; i<13; i++) {
      let lbl = moment(s, "DD/MM/YYYY").add(i, "months").format("MMM YYYY");
      days[i] = 0;
      dayLabel[i] = lbl;
      dayCounter[i] = 0;
    }
  
  data.map((d)=>{
    if (filters.callType == d["Call Type"]) {    
    let dt; 
    
    try {
      dt = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm");
    } catch (e){console.log("Error getting date")}

    if (dt.isBetween(startDate, endDate)) {
      
      let monthDisplay = moment(dt, "DD/MM/YYYY").format("MMM YYYY");

      dayLabel.map((l, i)=>{
        if (l==monthDisplay) {
          let callSecs = parseInt(d["Call Duration Mins"]);

          if (callSecs > filters.kpi) {
            let count = parseInt(dayCounter[i]);
            days[i] = (parseInt(days[i])) + parseInt(d["Call Duration Mins"]);
            dayCounter[i] = count + 1;
          }
        }
      });
      
    }
    }
  });

  days.map((h,i)=>{
      
    rData.push({
      zone: dayLabel[i],
      calls: getAverage(days[i], dayCounter[i]) 
    });
    
  });

  
    return rData;
  }

  let dta  = [];
  let startDate;
  let endDate;

  if (filters.timePeriod == "Today") {
    startDate = moment(filters.startDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "0").set("minute", "0").set("second", "0");
    endDate = moment(filters.endDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "23").set("minute", "59").set("second", "59");

    dta = filterDay(data, startDate, endDate);
  }

  if (filters.timePeriod == "Week") {
    startDate = moment(filters.startDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "0").set("minute", "0").set("second", "0");
    endDate = moment(filters.endDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "23").set("minute", "59").set("second", "59");

    dta = filterWeek(data, startDate, endDate);
  }

  if (filters.timePeriod == "Month") {
    startDate = moment(filters.startDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "0").set("minute", "0").set("second", "0");
    endDate = moment(filters.endDate.format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").set('hour', "23").set("minute", "59").set("second", "59");

    dta = filterMonth(data, startDate, endDate);
  }


    return(
      
    <CallReportContainer
          backgroundColor="rgb(220, 245, 243)"
          callType={filters.callType}
          colour={filters.color}
          title={
          
            <div style={style} >
              {filters.callType + " Above KPI Average Response Times"}
              <CallTypeToggle 
                              value={filters.callType}
                              callBack={callTypeToggleCallBack} />
            </div>
       
          }
        >
        
        <div style={{
          display:"flex", 
          flexDirection:"row", 
          padding: "2px", 
          backgroundColor: "#dcf5f3",
          justifyContent: "space-between"}}>
          
          <WeekMonthDayToggle 
                              today={true}
                              value={filters.timePeriod} 
                              callBack={callBackPeriod} />

          <SetKPIValue 
                      value={filters.kpi} 
                      caption="mins" 
                      callBack={kpiCallBack}
                      context="response_time_kpi"
                      />

        </div>

        {
        (dta.length == 0) ? <div style={{height:"300px", width:"600px", textAlign:"center", color:"darkgray"}}><br /><br /><br />No Calls</div> :
        <ArquellaBarChart 
          data={dta}
          kpi={filters.kpi}//{kpi}
          colours={callGraphColours[filters.callType]}
          xAxisLabel={filters.timePeriod} 
          yAxisLabel="Average Time (mins)" 
          yScaleValues="mins"
        />
        }

  
        <div className="report_navigation_container">
        <div onClick={()=>{previous();}}>prev</div>
        <div><GetDateDisplay /></div>
        <div onClick={()=>{next();}}>next</div>
      </div>

      </CallReportContainer>


    );



}


export default BarChartAboveAverageResponse;
