import React, { useTable, useSortBy, useGlobalFilter, useEffect , useState} from 'react';
import axios from 'axios';
import DTable from './ReactTable';
import './DataTable.css'

export default class DataTable extends React.Component {
  
  state = {
    data: [],
  }
  
  componentDidMount() {
    this.setState({data:this.props.data});
  }

  render() {

    if (this.props.data && this.props.data.length > 0) {
       
      return (<DrawTable tableData={this.props.data} house={this.props.house} />);
    
    }
    
    return (<div>{this.props.message ? this.props.message : ""}</div>);
    
  }
}


const DrawTable = (props) =>{
 
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState([]);
  
  useEffect(() => {
    setData(props.tableData);    
  }, []);

  

  function search(rows) {
    
    const colums = rows[0] && Object.keys(rows[0]);
    return rows;
    
  }

 

  if (data.length > 0) {
  
    return (<div><DTable data={search(data)} house={props.house}></DTable></div>);
  
  }
  else {

    return (<div>Test</div>);
        
  }

  
}


       