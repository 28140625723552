import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import {Pie} from 'react-chartjs-2';


export default class Resident_calls_pie extends Component {
  
  state = {
    data: []
  }  

   getData() {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate
    };
    
    axios.post("/resident/rpt/resident_call_grouped_type.php", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.residents.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data.residents})
      }
      else {
        this.setState({data: []})
      }
   
    });
  }

  componentDidMount () {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate
    };
 
    axios.post("/resident/rpt/resident_call_grouped_type.php", sData)
    .then(response => {
      this.setState({data: response.data.residents})
      

    });
  }

  componentDidUpdate(prevProps) {

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
    console.log(prevProps);

    let sData = {
      "house": prevProps.house,
      "startDate": prevProps.startDate,
      "endDate": prevProps.endDate,
      "zones": prevProps.zones
    };
    console.log(sData);
    axios.post("/resident/rpt/resident_call_grouped_type.php", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.residents.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data.residents})
      }
      else {
        this.setState({data: []})
      }

    });
    }
  }



  

  render() {

    let ct = 0;

    try {ct = this.state.data.length;}
    catch(err){}


    if (ct>0) {

      return (
        <div>
            
            <Pie_call data={this.state.data} /> 
          
        </div>
      );
      }else {
        return(
        <div>
           <div className="report_card">
      <p className="chartTitle">Call Type Splits</p>
      </div>
        </div>
        );
      }
  }
}



function Pie_Chart_Call(props) {

  const data = {
    labels: new Array(props.data.length).fill(""),
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets:props.data,
    backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    colors: ["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
    /*
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
    */
  };
  
  const legendOpts = {
    display: true,
    position: 'top',
    fullWidth: true,
    reverse: false,
    labels: {
      fontColor: 'rgb(255, 99, 132)'
    }
  };


    return(
  
  <div className="report_card">
      <p className="chartTitle">Call Type Splits</p>
      <Pie data={data} legend={this.state.legend} redraw />
    
    </div>
  );


}

function getColors(data) {
  return data.slice(1).map((ent)=>titleColor(ent[0]));
}


function titleColor(title) {
  if (title=="Assistance") {return "#fadb3d";}
  if (title=="Accessory") {return "#914499";}
  if (title=="Call") {return "#f5814e";}
  if (title=="Emergency") {return "#ed184a";}
  if (title=="Attendance") {return "#95ca66";}
  
  return "";
}


function Pie_call(props) {
 
  return(

<div className="report_card">
<p><b>Time VS Call Type</b></p>
<Chart
  width={'700px'}
  height={'450px'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={props.data}
  options={{
    backgroundColor: '#dcf5f3',
    colors: getColors(props.data),
  }}
  rootProps={{ 'data-testid': '1' }}
/>

    </div>

    );
}
