import React, {useContext, useState} from 'react';
import './comments.css';
import axios from 'axios';


const GetComments = (props) => {


    const [popup, setShowPopup] = useState({show:false});

    const [objComments, setObjComments] = useState(props.comments.map((c) => <Comment data={c} />));

    const refreshRecord = () => {
        let sData = {
            "userkey": localStorage.getItem("userEmail"),
            "ssid": props.ssid,
            "comment": comment.comment
        };
        
        axios.post("/care/active/refresh_comments.php", sData)
        .then(response => {

            let s = response.data;
            s = s.replace("},]", "}]");
            let t = JSON.parse(s);
            console.log(t);
            setObjComments(t.map((c) => <Comment data={c} />));
            
        });
    }


    //let objComments = props.comments.map((c) => <Comment data={c} />);

    const [comment, updateComment] = useState({comment:""});

    const popupHandler = () => {
        setShowPopup({show:false});
    }


    const addRecord = () => {
        let sData = {
            "userkey": localStorage.getItem("userEmail"),
            "ssid": props.ssid,
            "comment": comment.comment
        };
        
        axios.post("/care/active/add_comment.php", sData)
        .then(response => {
            popupHandler("refresh");
            refreshRecord();
        });
    }


    return (
        <div>
            <Modal show={popup.show} modalClosed={popupHandler}>
                <div className="cp_new_comment_container">
                    <div><b>Add New Care Comment</b></div>
                    <br />
                    <input 
                        onChange={e=>{
                            updateComment({comment:e.target.value});
                        }}
                    />
                    <br />
                    <div className="cp_new_comment_buttons">
                        <button
                            onClick={e=>{
                                popupHandler();
                                updateComment({comment:""});
                            }}
                        >Cancel</button>
                        <button
                            onClick={e=>{
                                addRecord();
                                updateComment({comment:""});
                            }}
                        >Add</button>
                    </div>
                </div>
            </Modal>
        
        <div className="careplan_subsection_comments_container">

            <div className="careplan_subsection_comments_title">{props.title}</div>
            <hr className="care_comments" />
            <div>{objComments}</div>
            <div 
                onClick={e=>{
                    setShowPopup({show:true});
                }}
                className="cp_subsection_comment_new"><span className="cp_subsection_comment_plus">+</span>&nbsp;&nbsp;Add New Care Note</div>        
        </div>
        </div>
    );
}


const Comment = (props) => {
    
    const comment = props.data;

    return (
        <div>
            <input className="careplan_subsection_comment" value={comment.ss_comment} />            
        </div>
    );
}


const Modal = (props) => (
    <div>
      <Backdrop show={props.show} clicked={props.modalClosed}/>
    <div 
      className="Modal"
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
      }}
      >
      {props.children}
    </div>
    </div>
  );
  
  
  const Backdrop = (props) => (
  
    props.show ? <div className="Backdrop"
                    onClick={props.clicked}
                  ></div> : null
  
  );
  
  export default GetComments;
