import React, {useContext, useState} from 'react';
import './bottombanner.css';
import GetAvatar from '../../../care/avatar/Avatar';

const BottomBanner = () => {

    let res_details = JSON.parse(localStorage.getItem('resident_details'));
    console.log("BottomBanner");
    console.log(res_details);
    return (
        <div className="cp_bottombanner_container">
            <GetAvatar firstname={res_details.Resident_Name} surname={res_details.Resident_Surname} />
            <div className="cp_bottombanner_details">
                <b>{res_details.Resident_Name}</b>
                <br />
                <b>UIN:</b>{res_details.Resident_UID}
            </div>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <div className="cp_bottombanner_details">
                {res_details.Room_Name}
            </div>
            
        </div>
    );
}


export default BottomBanner;