//https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html#position
//tooltip example
import React, { Component, useEffect, useRef, useState } from "react";
import './graph.css';
import ArquellaBarChart from '../ArquellaBarChart/ArquellaBarChart';
import CallReportContainer from '../CallReportContainer/CallReportContainer';
import WeekMonthDayToggle from '../WeekMonthDayToggle/WeekMonthDayToggle';
import SetKPIValue from '../SetKPIValue/SetKPIValue';
import moment from "moment";

const ArquellaBarChartHoursKPI = (props) => {

  const data = props.data ? props.data : [];
  
  const [filters, setFilters] = useState({
    timePeriod: "Today",
    kpi: 0,
    startDate : moment(),
    endDate : moment()
  });

  const periodCallBack =(value)=> {
    setFilters({
      timePeriod: value,
      kpi: filters.kpi,
      startDate : moment().set('hour', "0").set("minute", "0").set("second", "0"),
      endDate : moment().set('hour', "23").set("minute", "59").set("second", "59")
    });
  }

  function kpiCallBack(value){
    setFilters({
      timePeriod: filters.timePeriod,
      kpi: value,
      startDate : filters.startDate,
      endDate : filters.startDate
    });
  }

  let kpiVal = filters.kpi;
  let dta  = [];
  let startDate = filters.startDate;
  let endDate = filters.endDate;

  let xLabel = "";
  let kpiBreached = false;

  if (filters.timePeriod == "Today") {

    let totalCount = 0;
    xLabel = "Hours";
  
    startDate = moment(filters.startDate).set('hour', "0").set("minute", "0").set("second", "0");
    endDate = moment(filters.endDate).set('hour', "23").set("minute", "59").set("second", "59");
  
    let hours = [];
      hours.length = 24;

      for (let i=0; i<24; i++) {
        hours[i] = 0;
      }

    data.map((d)=>{
    
      let dt; 
      
      try {
        dt = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm");
      } catch (e){console.log("Error getting date")}
      
      if (dt.isBetween(startDate, endDate)) {
        
        let hourVal = -1;
        let t = "";
  
        try {
          hourVal = parseInt(d["Call Hour"]);
        } catch(e){}
        
        if ((hourVal>-1) && (hourVal < 24)) {
          let count = hours[hourVal];
          count = count + 1;
          hours[hourVal] = count;
        }
  
      }
  
    });
  
    hours.map((h,i)=>{
      
      dta.push({
        zone: moment().set("hour", i).set("minute", 0).format("HH:mm") + " - " + moment().set("hour", i).set("minute", 59).format("HH:mm"),
        calls: h 
      });
       
      totalCount = totalCount + h;

    });
  
    if (totalCount > kpiVal) kpiBreached = true;

  }

  if (filters.timePeriod == "Week") {

    /*

      Call Duration
      Call Point
      Call Type
      Care Delivered
      Carer ID
      Date
      End Time
      Resident
      Start Time
      Zone
      Call Month
      Call Weekday Number
      Call Hour
      Call Day
      Call Duration Mins
      Call Shift

    */

    xLabel = "Days";

    startDate = moment(filters.startDate).set('hour', "0").set("minute", "0").set("second", "0").subtract(7, "d");
    endDate = moment(filters.endDate).set('hour', "23").set("minute", "59").set("second", "59");

    let days = [];
    let dayLabel = [];

    let s = startDate.format("DD/MM/YYYY");

    for (let i=0; i<8; i++) {
      let lbl = moment(s, "DD/MM/YYYY").add(i, "days").format("ddd DD MMMM YY");
      days[i] = 0;
      dayLabel[i] = lbl;
    }
  
    data.map((d)=>{

      let dt; 
      
      try {
        dt = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm");
      } catch (e){console.log("Error getting date")}

      if (dt.isBetween(startDate, endDate)) {
      
        let dayIndex = (dt.diff(endDate, "days") + 7);
        let count = parseInt(days[dayIndex]);
        days[dayIndex] = (count + 1);

      }

    });

    days.map((h,i)=>{
        dta.push({
          zone: dayLabel[i],
          calls: days[i] 
       });
    });

  }

  if (filters.timePeriod == "Month") {
    
    xLabel = "Months";

    startDate = moment(filters.startDate).subtract(1, "year").startOf("month");
    endDate = moment(filters.startDate).set('hour', "23").set("minute", "59").set("second", "59").endOf("month");

    let dayDiff = moment().diff(moment().startOf("month"), "days");

    endDate = moment().set('hour', "23").set("minute", "59").set("second", "59");

    let days = [];
    let dayLabel = [];
    
    let s = startDate.format("DD/MM/YYYY");

    for (let i=0; i<13; i++) {
      let lbl = moment(s, "DD/MM/YYYY").add(i, "months").format("MMM YYYY");
      days[i] = 0;
      dayLabel[i] = lbl;
    }
  
  data.map((d)=>{
    
    let dt; 
    
    try {
      dt = moment((d.Date + " " + d["Start Time"]), "DD MMMM YYYY HH:mm");
    } catch (e){console.log("Error getting date")}

    if (dt.isBetween(startDate, endDate)) {
      
      let monthDisplay = moment(dt, "DD/MM/YYYY").format("MMM YYYY");

      dayLabel.map((d, i)=>{
        if (d==monthDisplay) {
          let count = parseInt(days[i]);
          days[i] = (count + 1);
        }
      });
      
    }

  });

  days.map((h,i)=>{
      
    dta.push({
      zone: dayLabel[i],
      calls: days[i] 
    });
    
  });

    kpiVal = (kpiVal * 31);

  }

  function previous () {
    
    let sDate = filters.startDate;
    let eDate = filters.endDate;
    

    if (filters.timePeriod=="Today") {
      
      sDate = moment(sDate).subtract(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
      
    }

    if (filters.timePeriod=="Week") {
      sDate = moment(sDate).subtract(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }

    if (filters.timePeriod=="Month") {
      sDate = moment(sDate).subtract(1, "month").startOf("month").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).endOf("month").set("hour", "23").set("minute", "59").set("second", "59");
    }

    setFilters({
      timePeriod: filters.timePeriod,
      kpi: filters.kpi,
      startDate : sDate,
      endDate : eDate
    });
    

  }

  function next () {
    
    let sDate = filters.startDate;
    let eDate = filters.endDate;
    
    if (filters.timePeriod=="Today") {
      
      sDate = moment(sDate).add(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
      
    }
    
    if (filters.timePeriod=="Week") {
      sDate = moment(sDate).add(1, "d").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }

    if (filters.timePeriod=="Month") {
      sDate = moment(sDate).add(1, "month").set("hour", "23").set("minute", "59").set("second", "59");
      eDate = moment(sDate).set("hour", "23").set("minute", "59").set("second", "59");
    }


    setFilters({
      timePeriod: filters.timePeriod,
      kpi: filters.kpi,
      startDate : sDate,
      endDate : eDate
    });
    
  }

  const GetDateDisplay = () => {

    if (filters.timePeriod=="Today") {
      return (<span>{startDate.format("ddd DD MMM YYYY")}</span>);
    }

    if (filters.timePeriod=="Week") {
      return (<span>{startDate.format("DD/MM/YYYY")} - {endDate.format("DD/MM/YYYY")}</span>);
    }

    if (filters.timePeriod=="Month") {
      return (<span>{startDate.format("MMMM YYYY")} - {endDate.format("MMMM YYYY")}</span>);
    }


  }


  return (
    <CallReportContainer
      backgroundColor="rgb(220, 245, 243)"
      callType="Call"
      color="#4cc1bd"
      title="Total Calls Per Hour"
    >

      <div style={{
        display:"flex", 
        flexDirection:"row", 
        padding: "2px", 
        backgroundColor: "#dcf5f3",
        justifyContent: "space-between"}}>
        
        <WeekMonthDayToggle today={true} value={filters.timePeriod} callBack={periodCallBack} />
        
        <SetKPIValue value="37" caption="per day" callBack={kpiCallBack} context="response_kpi" />

      </div>
      
      <ArquellaBarChart 
        data={dta}
        key="abc"
        kpi={kpiVal}
        xAxisLabel={xLabel}  
        yAxisLabel="No. of calls"
        totalKPI={(filters.timePeriod=="Today") ? true : false}
        kpiBreached={kpiBreached}
        dailyTotal={(filters.timePeriod == "Today") ? true : false}
        //t={hours[20]}
      />
{
//filters.timePeriod == "Today"
}

      <div className="report_navigation_container">
        <div onClick={()=>{previous();}}>prev</div>
        <div>
            <GetDateDisplay />
        </div>
        <div onClick={()=>{next();}}>next</div>
      </div>

    </CallReportContainer>

);

}


export default ArquellaBarChartHoursKPI;