import React from 'react';

const DashBoardCallContainer=(props)=>{

    const dashboardColStyle = {
        display: "flex",
        flexDirection: "column",
        backgroundColor: props.backgroundColor,
        borderRadius: "15px",
        color: props.color,
        alignItems: "center",
        width: "45%",
        overflow: "hidden",
        alignItems: "stretch",
        textAlign: "-webkit-center",
        overflowY: "auto"
    }   

    const titleStyle = {
        fontSize: "1.5em"
    }

    return (

        <div id="div_cardWallet" style={dashboardColStyle} >
            <br />
            <div style={titleStyle}>{props.title}</div>
            <br />
            {props.children}
            <br />  
        </div>

    );
}

export default DashBoardCallContainer;