import React, {useState} from "react";
import Resident_calls_pie from '../charts/resident_calls_pie';
import Residents_Response_Average from '../assist/charts/resident_response_average';
import Calls_By_Resident from '../assist/charts/calls_by_resident';
import Attendance_By_Resident from '../assist/charts/attendance_by_resident';
import Emergency_Response_Times from '../assist/charts/emergency_response_times';
import Calls_Per_Hour from '../assist/charts/calls_per_hour';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Scroller from '../common/scroller/scroller';
import HomeFilter from '../SelectFilters/HomeFilter';
import ZoneFilter from '../SelectFilters/ZoneFilter';
import Report, {ReportsContainerFour} from '../reports/report';


const GeneralSummaryReport = (props) => {
  
  const handleEvent = (event, picker)=> {}

  const [state, setState] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
    selectedChart: "",
    house: "Nyton House",
    zones: []
  });

  const { start, end, selectedChart } = state;
  
  const handleCallback = (start, end) => {
    setState({ 
      start: start,
      end: end,
      selectedChart: state.selectedChart,
      house: state.house,
      zones: state.zones
    });
  }

  const label = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');

  const getDateDisplay = () => {
    let rtn = "";
    try {
      rtn = state.start.toString().substr(0,15) + " - " + state.end.toString().substr(0,15);
    }
    catch(err){
      console.log("getDateDisplay() - " + err);
    }
    return rtn; 
  }

  const updateSelected = (selectedVal) => {
    setState({ 
      start: state.start, 
      end:state.end, 
      selectedChart: selectedVal,
      house: state.house,
      zones: state.zones
    });
  }

  const numberOfPicture = [1, 2, 3, 4, 5, 6];
  
  const repList = [
    { name: 'Resident Calls' },
    { name: 'Emergency Response Times' },
    { name: 'Call Numbers Per Hour' },
    { name: 'Call Type Splits' }
  ];

  const GetSelectedChart = () => {

    let sel = state.selectedChart;
    
    if (sel == "Resident Calls") {

      return (
        <Report 
            data={[]}
            type="bar"
            name="Calls_by_resident"
            title="Calls by resident"
            noDataCaption="No records returned."
        />
      );

      return (<Calls_By_Resident 
                  house={state.house} 
                  startDate={state.start} 
                  endDate={state.end} 
                  zones={state.zones} />);
    }
    

    if (sel == "Call Type Splits") {
      return (<Resident_calls_pie 
                    house={state.house} 
                    startDate={state.start} 
                    endDate={state.end}
                    zones={state.zones} />);
    }
    
    
    if (sel == "Response Times") {
      return (<Residents_Response_Average 
                    house={state.house} 
                    startDate={state.start} 
                    endDate={state.end}
                    zones={state.zones} />);
    }
    
    if (sel == "Attendance by Resident") {
      return (<Attendance_By_Resident 
                  house={state.house} 
                  startDate={state.start} 
                  endDate={state.end}
                  zones={state.zones} />);
    }
    
    if (sel == "Emergency Response Times") {
      return (<Emergency_Response_Times 
                  house={state.house} 
                  startDate={state.start} 
                  endDate={state.end}
                  zones={state.zones} />);
    }
    
    if (sel == "Call Numbers Per Hour") {
      return (<Calls_Per_Hour 
                  house={state.house} 
                  startDate={state.start} 
                  endDate={state.end}
                  zones={state.zones} />);
    }
    
    return (<div></div>);
    
  }


  const homeCallBack = (home) => {
    setState({ 
      start: state.start, 
      end:state.end, 
      selectedChart: state.selectedChart,
      house: home,
      zones: state.zones
    });

  }

  const zoneCallBack = (zones) => {
    setState({ 
      start: state.start, 
      end:state.end, 
      selectedChart: state.selectedChart,
      house: state.house,
      zones: zones
    });
  }

  return (
    <div id="AIDA_NAV_MAIN">
      <div id="AIDA_NAV" className="AIDA_NAV">

      <p>Home</p>
      <HomeFilter callback={homeCallBack} />

      <br />
      <p>Zone</p>
      
      <ZoneFilter callback={zoneCallBack} />
      
      <br />
      <br />
      
      <DateRangePicker
        onEvent={handleEvent} 
        onCallback={handleCallback}
        initialSettings={{
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Year To Date': [
              moment().subtract(1, 'year').toDate(),
              moment().toDate(),
            ],
          },
        }}

      >
        
        <div>
          <p>Set Time Period</p>
          <div className="inputTimeDisplay">{label}</div>
        </div>
      </DateRangePicker>

      
      </div>
      <div className="AIDAMAINTCONTENT">
  
        <Scroller 
            updateFunction={updateSelected} 
            list={repList}
        />

        <div className="reports_container_four">

          <GetSelectedChart sel={state.selectedChart} />
          
        </div>
      </div>
    </div>

  );

}

export default GeneralSummaryReport;