import React
//, {useContext, Component, useState, useRef} 
from 'react';
import SimplePopup from '../common/SimplePopup/SimplePopup';
import FadeIn from '../FadeIn/FadeIn';
import LiveCall from './LiveCall';
import DashBoardCallContainer from '../DashBoardCallContainer/DashBoardCallContainer';
import './LiveCall.css';
import firebase from 'firebase'
import Moment from 'moment';


export default class LiveCallController extends React.Component {

    state = ({
        selectedIndex: 0,
        data: [],
    });

    updateSelectedIndex(index){
        this.setState({selectedIndex: index});
    }
    
    getDateRef = (ref) => Moment(ref).format('DDMMYYYY');
    
    getData(ref, dateVal) {
        
        const userList = JSON.parse(localStorage.getItem("uMails"));

        let liveCalls = {};
        console.log("REFFFF:: " + ref);
       firebase.database().ref(ref).on("value", snapshot => {
               
                snapshot.forEach(snap => {
                    const r = snap.val();
                    
                    //if (r.end=="Live") {
               
                        let carer = r.carer;
    
                            if (Array.isArray(userList)) {
    
                                userList.map(u=>{
    
                                    if (u.email == carer) {
                                        carer = u.first_name + " " + u.surname;
                                    }
                                });
                            }
                    
                            let tmpRefVal = r.zone + "_" + r.room;

                            const fld = {
                                "Date" : dateVal,
                                "Zone" : r.zone,
                                "Resident" : "",
                                "Call Point" : r.room,
                                "Call Type" : r.callType,
                                "Start Time" : r.start,
                                "End Time" : r.end,
                                "Call Duration" : r.duration,
                                "Clear Type" : (r.end=="Live") ? "Live" : "Reporting", 
                                "Carer ID" : carer,
                                "Care Delivered" : [],
                            };
    
                            Object.assign(liveCalls, {[tmpRefVal]: fld});
                            
                       // }
                    
                });
            console.log("ObjectCalls");
            console.log(liveCalls);

            let tmpCalls = [];
            
            Object.keys(liveCalls).map((key) => {
                console.log(liveCalls[key]);
                if (liveCalls[key]["Clear Type"]=="Live") {
                    tmpCalls.push(liveCalls[key]);
                }
            });
            
            console.log("TmpCalls");
            console.log(tmpCalls);

            this.setState({data: tmpCalls});

          });

    }
    
    getLiveCallsList(calls){
        const rtnVAl = calls.map((c, i)=>{
                    return(   
                    <div
                        key={i}
                        onClick={()=>this.updateSelectedIndex(i)}>
                         <LiveCall
                                    key={i}
                                    index={i}
                                    callType={c["Call Type"]}
                                    room={c["Call Point"]}
                                    zone={c.zone}
                                    className={(this.state.selectedIndex == i) ? "selected_live_call" : "live_call"}
                                    selectedIndex={this.state.selectedIndex} 
                                    startDate={c["Date"]}
                                    startTime={c["Start Time"]}
                        />
                    </div>
                    
                );
                
            })
    
    
        return rtnVAl;
    }

    componentDidMount() {
        let site = JSON.parse(localStorage.getItem("sites"));
        let ref = "test";

        if (Array.isArray(site)) {
            if (site.length > 0) {
                ref = site[0].value;
            }
        }

        if (ref=="test") {ref="testCallRecords-lloyds";}

        ref = ref + "/" + this.getDateRef(new Date());
        this.getData(ref, this.getDateRef(new Date()));    }

    

    render() {

        let calls = this.state.data ? this.state.data : [];
        let callsRender = this.getLiveCallsList(calls);

        return(
            
            <DashBoardCallContainer 
                                    color="white"
                                    backgroundColor="#4cc1bd"
                                    title="Live Calls">

                {(calls.length>0) ? callsRender : null}

           </DashBoardCallContainer>

        );

    }

}
