import React, {Component, useState, useContext } from "react";
import IMG_PLUS from '../../../../../../images/icons/plus.png';


const NewActionIncident = () => {

    return (
        <div className="cp_add_new_item_container">
            <img 
                className="cp_add_new_item_plus"
                src={IMG_PLUS} 
                alt="Add New Item" />

            <div>Add New Item</div>
            
        </div>
    );
}

export default NewActionIncident;