//https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html#position
//tooltip example
import React, { Component, useEffect, useRef } from "react";
import * as d3 from "d3";
import { transition } from 'd3-transition';
import './graph.css';
import moment from "moment";

const ArquellaBarChart = (props) => {

  const gRef = useRef(null);
  
  let kpiLine = props.kpi ? props.kpi : 0;

  useEffect(()=>{
    
    gRef.current.innerHTML = "";
    
    var margin = {
          top: 50, 
          right: 20,
          bottom: 70,
          left: 70
        },
        width = 600 - margin.left - margin.right,
        height = 300 - margin.top - margin.bottom;
    
    var barPadding = props.padding ? props.padding : 3;

    let tickValues = props.data.map(v=>v.zone);
    let maxValue = 0;
    let minValue = 0;
    let totalVal = 0;

    props.data.map(v=>{
      if (parseInt(v.calls) > maxValue) {maxValue = parseInt(v.calls)}; 
      if (parseInt(v.calls) < minValue) {minValue = parseInt(v.calls)};
      totalVal = totalVal + parseInt(v.calls);
    });

    if (kpiLine > maxValue) { 
      maxValue = parseInt(kpiLine) + 5; 
      if (totalVal > maxValue) maxValue = totalVal;
    }

    var x = d3.scaleBand()
              .domain(tickValues)
              .range([0, (width)]);
    
    var y = d3.scaleLinear()
              .domain([maxValue, 0])
              .range([0, height]);

    var barscale = d3.scaleLinear()
              .domain([minValue, maxValue])
              .range([0, height]);

    let values = props.data.map(v=>v.calls);
    
    var xAxis = d3.axisBottom()
                      .scale(x)
                      .tickSize(1.95, 0, 0);
    
    var epsilon = Math.pow(10,-7);


    function getDurationVal(val) {
      
      if (((val%60)==0)||((val%60)==30)) {
        let dur = moment.duration(val, "seconds");
        console.log(val + "::::" + (val%60));
        let secs = dur.seconds();
        return dur.minutes() + ":" + (secs<10 ? ("0" + secs) : secs);
      }
      else {
        return;
      }
    }

    var yAxis = d3.axisLeft()
                  .scale(y)
                  .tickSize(1.95, 0, 0)
                  .tickFormat(function(d) {
                    
                    if (props.yScaleValues == "mins") return getDurationVal(d);
                    
                    if (((d - Math.floor(d)) > epsilon) && ((Math.ceil(d) -d) > epsilon))
                        return;
                    return d;
                })
                  ///.ticks(maxValue/2)
                  ;

    var svg = d3.select(gRef.current)
                .append("svg")
                .attr("fill", "none")
                .attr("viewBox", "0 0 700 350")
                
    
                
    const getKPIColour = (kpiLine, value, totalKPI, kpiBreached) => {

      if (totalKPI) {
        if (kpiBreached) {
          return "#868686";
        }
        else {
          return getColour(0);
        }
      }

      let val = parseInt(value);
      if (val > kpiLine) {return "#868686";}
      
      let rtn = "";
      rtn = getColour(0);
      
      return rtn;
    };

    const teardrop = {
        maxHeight: '25px',
        maxWidth: '25px',
        position: 'relative',
        top: 0,
        left: 0
      };
    
    const getColour = (index) => {
      let rtnColour = "#7DCDCD";
      const cols = props.colours ? props.colours : [];
      if (cols.length == 1) { rtnColour = cols[0];}
      return rtnColour;
    }

    let barSpacing = 3;
    let barWidth = (((width-(barSpacing*values.length)))/values.length);

    let totalKPI = props.totalKPI ? props.totalKPI : false;
    let kpiBreached = props.kpiBreached ? props.kpiBreached : false;
    

    svg.selectAll('rect')
        .data(values)
        .enter()
        .append("rect")
        .attr('x', function(d,i){
          return (margin.left + (i*barWidth) + (i*barSpacing))+1;
        })
        .attr('y', function(d){
          return height-barscale(d) + margin.top;
        })
        .attr('width', barWidth)
        .attr('height', function(d){
          return barscale(d);
        })
        .attr('fill', function(d,i){
          return getKPIColour(kpiLine, d, totalKPI, kpiBreached);          
        });
    
        if (props.dailyTotal) {
          var xposTot = (width + margin.left) + 20
              
          svg.append("rect")
              .attr('x', function(d,i){
                return xposTot + 7;
                //return (margin.left + (25*barWidth) + (25*barSpacing))+1;
              })
              .attr('y', function(d){
                return height-barscale(totalVal) + margin.top;
              })
              .attr('width', barWidth*2)
              .attr('height', function(d){
                return barscale(totalVal);
              })
              .attr('fill', function(d,i){
                return getKPIColour(kpiLine, d, totalKPI, kpiBreached);          
              });

            var totoalXAxis = d3.axisBottom()
              .scale(x)
              .tickSize(0, 0, 0);

              svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", 2)
              .attr("x1", xposTot)
              .attr("y1", (height + margin.top))
              .attr("x2", xposTot+50)
              .attr("y2", (height + margin.top));

            svg.append("g")
                .call(yAxis)
                .attr("transform", "translate(" + xposTot + "," + margin.top + ")")
                .style("fill", "#868686")
                .selectAll("text")	
                .style("text-anchor", "end")
                .style("fill", "#868686")


          svg.append("text")             
              .attr("transform",
                    "translate(" + (xposTot + 25) + " ," + (height + margin.top + 15) + ")")
              .attr('fill','#a1a3a6')
              .attr('font-size', 14)
              .style("text-anchor", "middle")
              .text("Total");
      }
        
          svg.append("g")
            .call(xAxis)
            .attr("class", "xaxis")
            .attr("transform", "translate(" + (margin.left) + "," + (height + margin.top) + ")")
            .style("font", "2px")
            .selectAll("text")	
            .style("text-anchor", "end")
            .style("fill", "#868686")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", function(d) {
                return "rotate(-70)" 
                });
            
          svg.append("g")
              .call(yAxis)
              .attr("transform", "translate(" + (margin.left) + "," + margin.top + ")")
              .style("fill", "#868686")
              .selectAll("text")	
              .style("text-anchor", "end")
              .style("fill", "#868686")
  
          svg.append("text")             
              .attr("transform",
                    "translate(" + ((width/2) + margin.left) + " ," + (height + margin.top + 75) + ")")
              .attr('fill','#a1a3a6')
              .attr('font-size', 14)
              .style("text-anchor", "middle")
              .text(props.xAxisLabel);

          svg.append("text")
              .attr("transform", "rotate(-90)")
              .attr("y", 18 )
              .attr("x", 0 - ((height  / 2) + margin.top))
              .attr("dy", "1em")
              .style("text-anchor", "middle")
              .attr('fill','#a1a3a6')
              .attr('font-size', 14)
              .text(props.yAxisLabel);    

          let x1 = 0;
          let x2 = 0;
          let yKPI = 0;

          let xStart = 0;
          let xEnd = 0;
          let xPlus = 92.5;                

          if (props.dailyTotal) {
            
            xStart = (margin.left + 1) + (24*barWidth)+ xPlus;
            xEnd = xStart + (barWidth*2);
            
            x1 = (margin.left + 1);
            x2 = x1 + (barWidth*2);
            yKPI = (y(kpiLine) + margin.top);
          }
          else {
            x1 = (margin.left + 1);
            x2 = (margin.left + width);
            xStart = x1;
            xEnd = x2;

            yKPI = (y(kpiLine) + margin.top); 
          }

          svg.append('line')
              .style("stroke", "#4CBCB9")
              .style("stroke-width", 2)
              .attr("x1", xStart)
              .attr("y1", yKPI)
              .attr("x2", xEnd)
              .attr("y2", yKPI);

          
          let tgtX = 0;
          let tgtY = 0;


          if (props.dailyTotal) {
            tgtX = (x2-2) + (24*barWidth)+xPlus;
            tgtY = (yKPI - 13);
          }
          else {
            tgtX = (x2-2);
            tgtY = (yKPI - 13);
          }

          svg.append('path')
              .attr("d", "M38.38,13.42A13.42,13.42,0,0,0,25,0C14,0,0,13.42,0,13.42S14,26.84,25,26.84A13.42,13.42,0,0,0,38.38,13.42Z")  
              .attr("stroke-width", "1")
              .attr("stroke", "red")
              .attr("class", "targetLine")
              .attr("fill", "#4CBCB9")
              .attr("transform", "translate(" + tgtX + " ," + tgtY + ")")

          if (props.dailyTotal) {
                tgtX = (x2+18.5) + (24*barWidth) + xPlus;
                tgtY = (yKPI - 5.5);
          }
          else {
                tgtX = (x2+20);
                tgtY = (yKPI - 5.5);
          }
          console.log("Diimesn");
          console.log(tgtX);
          console.log(tgtY);

              svg.append("text")
              .attr("y", tgtY)
              .attr("x", tgtX)
              .attr("dy", "1em")
              .style("text-anchor", "middle")
              .attr('fill','white')
              .attr('font-size', 3)
              .attr("class", "kpiText")
              .text("Target");    
              
              
  }, [props]);


  return (
      
      <div ref={gRef} className="gBarChart">
     
      </div>
    
  );

}


export default ArquellaBarChart;