import React, {Component, useState, useContext} from 'react';
import './SimplePopup.css';

const SimplePopup = (props) => {

  const CancelButton = () => {

    return (
      <div className="closeX">
            <button
              onClick={props.cancelHandler}
            >&nbsp;x&nbsp;</button>
      </div>
    );
  }
  
    return (
        
      <div>
          {props.show ? <div className="Backdrop" onClick={props.cancelHandler}></div> : null}
        <div 
          className="Modal"
          style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0',
            display: props.show ? 'block' : 'none',
          }}
          >
          {(props.hideCloseButton) ? null : <CancelButton />} 
          {props.children}

        </div>
      </div>

    );

}

export default SimplePopup;