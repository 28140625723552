import React from 'react';
import './toggle.css';
import IMG_EMERGENCY from '../../images/call_type_toggle/emergency.png';
import IMG_EMERGENCY_WHITE from '../../images/call_type_toggle/emergency_white.png';
import IMG_CALL from '../../images/call_type_toggle/call.png';
import IMG_CALL_WHITE from '../../images/call_type_toggle/call_white.png';
import IMG_ACCESSORY from '../../images/call_type_toggle/accessory.png';
import IMG_ACCESSORY_WHITE from '../../images/call_type_toggle/accessory_white.png';
import IMG_ASSISTANCE from '../../images/call_type_toggle/assistance.png';
import IMG_ASSISTANCE_WHITE from '../../images/call_type_toggle/assistance_white.png';


export default class CallTypeToggle extends React.Component {

    state = {
        selectedValue: this.props.value ? this.props.value : "Today"
    }

    valueSelected=(value)=>{
        this.setState({selectedValue:value});
        this.props.callBack(value);
    }
    
    GetSelection = ({value, img, img_selected, selectedColour})=>{
        return ( 
            <div 
                style={{backgroundColor: (this.state.selectedValue==value) ? selectedColour : "white"}}
                onClick={()=>this.valueSelected(value)}>
                    <img src={(this.state.selectedValue==value) ? img_selected : img} width="25px" />
            </div>
        );
    }

    render() {
        
        return(
            <div className="cal_twt_container">
                <this.GetSelection value="Emergency" img={IMG_EMERGENCY} img_selected={IMG_EMERGENCY_WHITE} selectedColour={callGraphColours["Emergency"]} />
                <this.GetSelection value="Call" img={IMG_CALL} img_selected={IMG_CALL_WHITE} selectedColour={callGraphColours["Call"]} />
                <this.GetSelection value="Accessory" img={IMG_ACCESSORY} img_selected={IMG_ACCESSORY_WHITE} selectedColour={callGraphColours["Accessory"]} />
                <this.GetSelection value="Assistance" img={IMG_ASSISTANCE} img_selected={IMG_ASSISTANCE_WHITE} selectedColour={callGraphColours["Assistance"]} />
            </div>
        );
    }
}

const callGraphColours = {
    Call: "rgb(245, 129, 78)",
    Emergency : "red",
    Assistance : "rgb(248, 218, 60)",
    Attendance : ['rgb(148, 202, 102)'],
    Accessory : ["purple"] 
}