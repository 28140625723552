import React, {useContext, Component, useState, useRef} from 'react';
import DropSelectMulti from '../../components/common/DropSelectMulti/DropSelectMulti';

const CareStatusFilter = (props) => {

    const o = [
            {"label":"Complete","value":"Complete"},
            {"label":"Incomplete","value":"Incomplete"},
            {"label":"Declined","value":"Declined"},
        ];

    const [filterOptions, setFilterOptions] = useState({
        zoneOptions: o,
        zoneSelections: o,
    });
    
    const changeZone = (zones) => {
      setFilterOptions({
        zoneOptions: filterOptions.zoneOptions,
        zoneSelections: zones,
      });
      props.callback(zones);
    }

    const options = filterOptions.zoneOptions;
    const selections = filterOptions.zoneSelections;
    if (filterOptions.zoneSelections.length == 0) {changeZone(options);}
    return <DropSelectMulti 
                options={options} 
                selections={selections} 
                filterCall={changeZone} />     

}

export default CareStatusFilter;
