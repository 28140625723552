//https://www.d3-graph-gallery.com/graph/interactivity_tooltip.html#position
//tooltip example
import React, { Component, useEffect, useRef, useState } from "react";
import { style } from "d3-selection";
import * as d3 from "d3";
import { text } from "d3";
import './cj.css';

export default class CareJourney extends React.Component {

    state = {
        journeyList: this.props.data ? this.props.data : [],
    }


    render() {

        let total = 0;
        console.log(this.state.journeyList);
        this.state.journeyList.map(j=>{
            console.log(j);
            if (isNaN(j.duration)==false) {
                total += parseInt(j.duration);
            }
        });


        return(
            <table className="rpt_carejourney_graph">
            <tr>
            {
                this.state.journeyList.map(j=>{
                    let d = ((j.duration/total)*100) + "%";
                    return (

                            <td width={d} className={j.call_type}>t</td>       
                    
                    );
                })
            }
            </tr>
            </table>
        );
    }

}
