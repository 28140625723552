import React, {useState, useRef} from 'react';
import {Pie} from 'react-chartjs-2';


export default class PieChart extends React.Component {

  options = {
    legend: {
      display: false,
      position: "right"
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };
  
  pieOptions = {
    legend: {
      display: true,
      backgroundColor: '#dcf5f3',
      position: "right",
      legendCallback: function(chart) {
        // Return the HTML string here.
        console.log(chart);
        return [
          <ul>
            <li>z</li>
            <li>zzzz</li>
            <li>ppp</li>
            <li>adasda</li>
          </ul>
        ];
      }
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };
  
  data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: [
      "Continence", 
      "Personal Care", 
      "Nutrition and Hydration", 
      "Physical Health", 
      "Tissue Viability",
      "Environment",
      "Social Life",
      "Sleeping and Rest", 
      "Moving and Handling"
    ],
    datasets: [
      {
        data: [300, 50, 100, 50,300, 50, 100, 50,300, 50, 100],
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ],

  };
  
  pieData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["usa", "europe", "africa"],
    datasets: [
      {
        data: [200, 150, 20, 10],
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ]
  };

  render(){
    return(

      <Pie
        data={this.data}
        options={this.pieOptions}
//            ref={input => {
//              chartInstance = input;
//            }}
      />

    );
  }
}


const chartColors = [
    "#336699",
    "#99CCFF",
    "#999933",
    "#666699",
    "#CC9933",
    "#006666",
    "#3399FF",
    "#993300",
    "#CCCC99",
    "#666666",
    "#FFCC66",
    "#6699CC",
    "#663366",
    "#9999CC",
    "#CCCCCC",
    "#669999",
    "#CCCC66",
    "#CC6600",
    "#9999FF",
    "#0066CC",
    "#99CCCC",
    "#999999",
    "#FFCC00",
    "#009999",
    "#99CC33",
    "#FF9900",
    "#999966",
    "#66CCCC",
    "#339966",
    "#CCCC33",
    "#003f5c",
    "#665191",
    "#a05195",
    "#d45087",
    "#2f4b7c",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#EF6F6C",
    "#465775",
    "#56E39F",
    "#59C9A5",
    "#5B6C5D",
    "#0A2342",
    "#2CA58D",
    "#84BC9C",
    "#CBA328",
    "#F46197",
    "#DBCFB0",
    "#545775"
  ];
  