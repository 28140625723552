import React, {useContext} from 'react';
import './arquella.css';
import './css/mainmenu.css';
import './css/dashboard.css';
import {firebaseAuth} from './firebase/provider/AuthProvider';
import Signup from './components/SignUp';
import Signin from './components/SignIn';
import MainMenu from './components/MainMenu/MainMenu';
import SplashCollect from './components/MainMenu/SplashCollect';
import {Switch,Route, Redirect} from "react-router-dom";

function App() {
  
  const { token } = useContext(firebaseAuth)

  return (
    <>
      <Switch>
        <Route exact path='/' render={rProps => token === null ? <Signin /> : <SplashCollect />} />
        <Route path='/' render={rProps => token === null ? <Redirect to="/" /> : <MainMenu />} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/signup' component={Signup} />        
      </Switch>
    
    </>
  );
}

export default App;



