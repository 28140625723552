import React, {useContext, Component, useState} from 'react';
import firebase from 'firebase'
import { render } from 'react-dom';
//images
import IMG_ACC from '../images/dashboard/accIcon.png';
import IMG_ALL from '../images/dashboard/allIcon.png';
import IMG_ASS from '../images/dashboard/assisIcon.png';
import IMG_ATT from '../images/dashboard/attenIcon.png';
import IMG_CALL from '../images/dashboard/callIcon.png';
import IMG_EMG from '../images/dashboard/emergency.png';
import HomeSelection from './HomeSelection';

const CallSupport = props => {
    
    const [dashState, setDashState] = useState({
        calls: [],
        selectedVal : ""
      });


    
      const [membersState, setMemberState] = useState( { 
          adding: false,
          callType: ""
        });
   

    const getDBData = (ref) => {
        firebase.database().ref(ref).on("value", snapshot => {
            let tcalls = [];
            snapshot.forEach(snap => {
                tcalls.push(snap.val());
            }); 
            setDashState(
                {
                    calls: tcalls, 
                    selectedVal : dashState.selectedVal
                
                });
          });
    }
    
    
    const callsMap = dashState.calls.map((data, counter) => {
           
            return <ShowCall key={counter} callData={data} />;
    
        });
    


        if (dashState.calls.length <= 0) {getDBData("officeCallRecords-office/17122020");}
        
            return (
                
                <div id="AIDA_NAV_MAIN">
        
                <div id="AIDA_NAV" className="AIDA_NAV"></div>
                    
                    <div className="alert_call_container">
                    {callsMap}
                    </div>
                    
                
                </div>
            );
    

            

    }

    const ShowCall = (props)=> {

      //      <div>{props.callData.beaconId}</div>
      let cl = "alert_call " + props.callData.callType;
      return ( 
        <div className={cl}>
          <table>
            <tr>
              <td>
                {props.callData.room}
                <br />
                <small>{props.callData.zone}</small>  
              </td>
     
              <td>
                <table>
                  <tr><td><small><b>Clear type</b><br/>{props.callData.clearType}</small></td></tr>
                  <tr><td><small><b>Panel Ref</b><br/>{props.callData.panelRef}</small></td></tr>
                  <tr><td><small><b>UnitID</b><br/>{props.callData.unitId}</small></td></tr>
                </table>
              </td>
            </tr>
            <tr><td><small><b>{props.callData.start} - {props.callData.end}  ({props.callData.duration})</b></small></td></tr>
          </table>
        </div>

      );
      
  }


export default CallSupport;


/*

      const Backdrop = (props) => (

        props.show ? <div className="Backdrop"
                        onClick={props.clicked}
                      ></div> : null      
      );
    
    
    const Modal = (props) => (
        <div>
          <Backdrop show={props.show} clicked={props.modalClosed}/>
        <div 
          className="Modal"
          style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0',
          }}
          >
          {props.children}
        </div>
        </div>
      );
      
    
      const cancelHandler = () => {
        setMemberState({
          adding: false,
          callType: membersState.callType
        });
      }


*/

    
                
              