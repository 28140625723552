import React, {Component, useState, useContext} from 'react';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';

  function HomeSelection(props) {
    const options = [{Site_Name:'Arquella Demo', value:'Arquella Demo'},{Site_Name:'Tranby Park', value:'Tranby Park'}, {Site_Name:'Lindsey Hall', value:'Lindsey Hall'}, {Site_Name:'Live Demo', value:'Live Demo'}];
    //return <ItemRenderer options={props.sites} title="test" selectedVal="" />
    let selection = props.selectedVal;
    if (selection == ""){
      if (options.length > 0) {selection = options[0].Site_Name;}
    }

    return <ItemRenderer options={options} title="test" selectedVal={selection} onChangeRun={props.onChangeRun} />
  }
  
  const ItemRenderer = ({ options, title, selectedVal, onChangeRun }) => (

  <React.Fragment>
  
  <Select
    options={options}
    values={[]}
    itemRenderer={({ item, methods }) => (
      
      <StyledItem>
        {item.disabled ? (
          <div aria-disabled>{item.Site_Name}</div>
        ) : (
          <div onClick={() => onChangeRun(item.Site_Name)}>
            <input 
              onChange={() => {
                selectedVal = item;
                methods.addItem(item);
              }} 
              type="checkbox" checked={(item.Site_Name == selectedVal)} /> {item.Site_Name}
          </div>
        )}
      </StyledItem>
    )}
    onChange={(value) => {}
    
  }
  />
  </React.Fragment>

  );
  
  ItemRenderer.propTypes = {};
  
  const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
  display: flex;
  align-items: center;
  }
  input {
  margin-right: 10px;
  }
  :hover {
  background: #f2f2f2;
  }
  `;

  export default HomeSelection;