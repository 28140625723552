import React, {useContext,useState, useEffect} from 'react';
import './splashcollect.css';
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';
import axios from 'axios';
import MainMenu from './MainMenu';

const SplashCollect = () => {

    const [requiredFields, setRequiredFields] = useState({
        fields:["sites", "repSelectSite", "zones", "carers", "CallPoints", "careResidents", "permissions"]
    });

    function updateCompleteFields(updatedField){
        let tmpArr = requiredFields.fields;
        let fieldIndex = tmpArr.indexOf(updatedField);
        if (fieldIndex > -1) {tmpArr.splice(fieldIndex, 1);}       //remove from required array if updated

        setRequiredFields({fields:tmpArr});
    }

    function setPermissions(path){
        let sData = {
          "user": localStorage.getItem("userEmail"),
          "userkey": localStorage.getItem("userEmail")
        };
        axios.post(path, sData)
        .then(response => {
          let r = response.data;

          if ((r == "")||(r == null)) {r = "[]"}
          if (Array.isArray(r)) {
            r.map(pm=>{
              localStorage.setItem(pm.pm_name, true)
            });
          }
          updateCompleteFields("permissions");
        }).catch(response=>{

        });
    }
    

    function updateDBValues(path, updateItem) {
        localStorage.setItem("userKey", localStorage.getItem("userEmail"));
        let sData = {
          "user": localStorage.getItem("userEmail"),
          "userkey": localStorage.getItem("userEmail")
        };
        axios.post(path, sData  
        )
        .then(response => {
          let r = JSON.stringify(response.data);
          if ((r == "")||(r == null)) {r = "[]"}
          localStorage.setItem(updateItem, r);
          updateCompleteFields(updateItem);
        });
      }


      useEffect(() => {

        updateDBValues("/user/sites", "sites");
        updateDBValues("/user/sites", "repSelectSite"); 
        updateDBValues("/user/zones", "zones");
        updateDBValues("/user/carers", "carers");
        updateDBValues("/user/callpoints", "CallPoints");
        updateDBValues("/user/residents", "careResidents");
        updateDBValues("/team/userlevels", "userlevels");

        localStorage.setItem('singleSelectedDate', "Today");
        
        setPermissions("/user/permissions");
        checkValidServiceWorker();

//        getData();

        },[]);
        
        const logoStyle = {
            margin: "auto",
            width: "100%",
            paddingTop: "10%",
        };

        if (requiredFields.fields.length > 0) {
            return (
                <div style={logoStyle}>
                    <img className="splashFading" src={IMG_AIDA_LOGO} />
                </div>
            );
        }
        
        return <MainMenu />;

} 

function checkValidServiceWorker() {
    let workerEnabled = false;
    if (navigator.serviceWorker) {workerEnabled = true;}
    sendServiceWorkerStatus(workerEnabled);
}


function sendServiceWorkerStatus(status){
    try {
        console.log("sendServiceWorkerStatus");
        let sData = {
            "user": localStorage.getItem("userEmail"),
            "userkey": localStorage.getItem("userEmail"),
            "workerservicestatus": status
        };
        axios.post("/user/workerservicestatus", sData)
        .then(response => {})
        .catch(response=>{
            console.log(response);
        });
    } catch (e){console.log(e);}
}


export default SplashCollect;

