import React, {Component, useState, useEffect} from "react";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ResidentReportContent from './content';
import Filter from '../../components/SelectFilters/Filter';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const ref = React.createRef();



const ResidentReport = () => {
  

  const [residents, setResidents] = useState([]);
  const [selectedResident, setSelectedResident] = useState();
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment().subtract(7, "days"));
  
  const onLoad = useEffect(()=>{

    let res = [  
      {
        first_name: "Bedroom 28",
        surname: "",
        uin: "93243",
        room: "Bedroom 28",
      }
  ]; //demoResidents;
  
    /*
    let sData = {
      "userkey": localStorage.getItem("userEmail")
    };
    axios.post("http://localhost:8004/AIDA/api/user/lists.php", sData)
        .then(response => {
  
    });
  */
  
    setResidents(res);
    setSelectedResident(res[0]);


  },[]);


  
  const label = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');

  const residentCallBack = (selectedVal)=> 
  {
    
    residents.map(r=> {
      let uName = r.first_name + " " + r.surname;
      console.log(uName + "::" + selectedVal);
      if (uName == selectedVal) {
        setSelectedResident(r);
      }
    });
  }


      let residentOptions = [];

      residents.map(r=>{
        residentOptions.push(
          {
            label: r.first_name + " " + r.surname,
            value: r.uin
          }
        );
    })

      return (
 
      <div id="AIDA_NAV_MAIN">
        
        <div id="AIDA_NAV" className="AIDA_NAV">

        Resident
        {(residentOptions.length >0) ?
        <Filter 
          options={residentOptions}
          selected={(selectedResident!=null) ? selectedResident.first_name + " " + selectedResident.surname : ""}
          callback={residentCallBack}
        />
        : null
      }


<br />
        <DateRangePicker
//        onEvent={handleEvent} 
  //      onCallback={handleCallback}
        initialSettings={{
          timePicker: true,
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'Year To Date': [
              moment().subtract(1, 'year').toDate(),
              moment().toDate(),
            ],
          },
        }}
>

        <div>
          <p>Dates</p>
          <div className="inputTimeDisplay">{label}</div>
        </div>

        </DateRangePicker>



        </div>

        {console.log(selectedResident )}

        {
          (selectedResident!=null) ? 
        
        <ResidentReportContent 
             //data={this.state.data.calls ? this.state.data.calls : []}
             //summary={this.state.summary}
             resDetails={selectedResident}
             //start={this.state.start}
             //end={this.state.end}
             //showData={this.state.showData}
        />
        : null
      }
      </div>

    );
  
  

}

export default ResidentReport;

const demoResidents = [

  {
    first_name: "David",
    surname: "Chapman",
    uin: "93243",
    room: "Bedroom 28",
  },
  {
    first_name: "Thomas",
    surname: "Hand",
    uin: "93842",
    room: "Bedroom 32",
  },
  {
    first_name: "Constance",
    surname: "Calvert",
    uin: "93212",
    room: "",
  },
  {
    first_name: "Beatrice",
    surname: "Senior",
    uin: "73341",
    room: "Bedroom 3",
  },
  {
    first_name: "Peggy",
    surname: "Sentance",
    uin: "23846",
    room: "Bedroom 40",
  },
  {
    first_name: "Cephas",
    surname: "Raymond",
    uin: "83352",
    room: "Bedroom 18",
  },
];


/*
  state = {
    data: [],
    summary: [],
    resDetails: [],
    start: moment().subtract(29, 'days'),
    end: moment(),
    showData: false,
    residents: [],
    selectedResident: {}
  }
*/
  /*
  componentDidMount() {
    
    this.setState({ 
      data: {
        resident_details: {
          first_name: "David",
          surname: "Chapman",
        },

        calls: [
          {"id": 572485, "room": "LOUNGE", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 09:44:51.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:44:48.000000"},
          {"id": 572487, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 16, "end_date": "2020-12-15 09:45:48.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:45:32.000000"},
          {"id": 572488, "room": "BEDROOM 3", "site": 5, "zone": "GROUND FLOOR", "unit_id": null, "carer_id": null, "duration": 5, "end_date": "2020-12-15 09:46:44.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 09:46:38.000000"},
          {"id": 572510, "room": "SHOWER ROOM", "site": 5, "zone": "FIRST FLOOR", "unit_id": null, "carer_id": null, "duration": 3, "end_date": "2020-12-15 10:38:00.000000", "call_type": "Call", "carer_name": null, "start_date": "2020-12-15 10:37:56.000000"}
        ]
      
      },

      residents: demoResidents,
       
    });
*/
    
    /*
      axios.get("/resident/rpt/resident_call_report.php")
      .then(res => {
        console.log("WE have data");
        console.log(res);
        this.setState({ 
          data: res.data.charts,
          summary: res.data.Summary[0],
          resDetails: res.data.resident_details[0] 
        });
      })
      .catch(err => {
        console.log("we have an error");
        console.log(err);
      });
      */
//  }
