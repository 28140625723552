import React, {useContext} from 'react';
import './header.css';
import Avatar from '../../../components/Avatar/Avatar';

const TeamMemberHeader = (props) => {


    const CancelButton = () => {

        return (
          <div className="closeX_team">
                <button
                  onClick={props.cancelHandler}
                >&nbsp;x&nbsp;</button>
          </div>
        );
      }
    


    return(
        <div className="team_member_header">
            <Avatar firstName={props.firstName} surname={props.surname} card={false}/>
            <div style={{
                width:"100%",
                alignSelf:"center",
                color:"white"
            }}>{props.firstName} {props.surname}</div>
            <CancelButton></CancelButton>
        </div>
    );

}





export default TeamMemberHeader;