import React, {useContext, useState} from 'react';
import {Collapse} from 'react-collapse';
import Expander, {ExpanderPopup} from '../Expander/Expander';
import './Actions.css';
import axios from 'axios';


const Actions = (props) => {
    console.log("ActionsScreen");
    console.log(props);
    
    let actionList = [];

    if (props.data) {
        actionList = props.data.map(action => <ActionScreen data={action} />);
    }
    
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Expander header="Actions and Incidents" headerIMG="imgActions">
            <CareplanContent>
            <CareplanSubSections>
        
            {actionList}
        
        
            <AddNewAction />

            </CareplanSubSections>
        </CareplanContent>
        </Expander>
    );

}


const AddNewAction = () => {

    return (

        <ExpanderPopup toggleKey="Add New Item">
            <AddActionScreen />      
        </ExpanderPopup>

    );

}


const AddActionScreen = ()=> {
/*
    ac_id int(11) AI PK 
    ac_cp_id int(11) 
    ac_action varchar(256) 
    ac_date_time datetime 
    ac_source varchar(100) 
    ac_information varchar(256) 
    ac_follow_up tinyint(1) 
    ac_comments
*/

    return (
        <div className="cp_NewActionScreen">
            <h3>Add new action</h3>
            <div>Adding new Action</div>

            <div className="cp_ActionForm">
                <div>Action</div>
                <div><ActionFormInput /></div>
                <div>DateTime</div>
                <div><ActionFormInput /></div>
                <div>Source</div>
                <div><ActionFormInput /></div>
                <div>Information</div>
                <div><ActionFormInput /></div>
                <div>Follow up</div>
                <div><ActionFormInput /></div>
                <div>Comments</div>
                <div><ActionFormInput /></div>

            </div>
            <button>Submit</button>
        </div>
        
    );
}

const ActionFormInput = (props) => <input />;


const ActionScreen = (props) => {

    
    return (
                <CareplanSubSectionActionGrey>
                <CareplanEntriesContainer>

                    <ActionEntry />
        
                </CareplanEntriesContainer>
                </CareplanSubSectionActionGrey>

        );


}


const CareplanContent = (props) =><div className="cp_content">{props.children}</div>; 
const CareplanSubSections = (props) =><div className="cp_subsections">{props.children}</div>
const CareplanSubSectionActionGrey = (props) =><div className="grey" sectype="actions">{props.children}</div>
const CareplanEntriesContainer = (props) =><div className="entries">{props.children}</div>

const ActionEntry = () => {
    return (

        <div className="action_entry">
            <div className="action_top">
                <span>Call GP</span>
                    <div className="action_options">
                        <div className="action_checkbox">
                            <span>Urgent</span>
                            <input className="largeCheck" type="checkbox" />
                        </div>
                        <div className="action_checkbox">
                            <span>Complete</span>
                            <input className="largeCheck" type="checkbox" />
                        </div>
                    </div>
            </div>
            <ActionComments />                         
        </div>

    );
}



const ActionComments = () => {


    return (
        <div class="action_bottom">
            <span>Comments</span>
            <textarea id="Action_Comments_1" 
            //onchange="checkValChange('Action_Comments_1')"
            ></textarea>
        </div>

    );
}


export default Actions;
