import React, {useContext, useState} from 'react';
import './entries.css';
import Toggle from './Toggle/toggle';
import Expander from '../Expander/Expander';
import MealFilter from '../../../../../SelectFilters/MealFilter';
import EntryComment from './Comments/EntryComments';
import TextEntry from './TextEntry/TextEntry';

const Entries = (props) => {
    
    const entries = props.entryList;

    let contextList = [];
        entries.map(entry => {
        
        if (contextList.indexOf(entry.Entry_Context)==-1) {
            contextList.push(entry.Entry_Context);
        }    

    });
    
    let data = contextList.map(e=> <FilterEntries 
                                        value={e} 
                                        entries={entries} />);

    return (
        <div className="cp_subsection_container">{data}</div>
    );
}

const FilterEntries = (props) => {
    return (
        <div>
            <p>{props.value}</p>
            {props.entries.map((e)=> 
                (props.value==e.Entry_Context)
                ? <Entry eData={e} />
                : null
            )}
        </div>
    );
}

const GetMealInputVal = (props) =>{
    return (
        <input
            id={props.id}
            className="cp_entry_meal_input"
            value={props.value}
        />
    );
}

const Entry = (props) => {
    const entry = props.eData;
    let widget;
    let header;

    if (entry.Entry_Type == "toggle") {
    
        header = <div>{entry.Entry_Name}</div>;    

        widget = <Toggle 
                        id={entry.Entry_ID}                                            
                        value={entry.Entry_Value}
                        />;
        
    }
    else {
        header = <div>{entry.Entry_Name}</div>;    

        widget = <TextEntry 
                        id={entry.Entry_ID}                                            
                        value={entry.Entry_Value}
                        />;
        
    } 

    const callback=()=>{

    }

    if (entry.Entry_Type == "meal") {

        return (
            <div className="liquid__field_container">
                <GetMealInputVal 
                                id={entry.Entry_ID}                                            
                                value={entry.Entry_Name}
                                />
                <MealFilter 
                    callback={callback}
                    homeOptions={[
                        {label: "1/4", value: "1/4"},
                        {label: "1/2", value: "1/2"},
                        {label: "3/4", value: "3/4"},
                        {label: "All", value: "All"}
                    ]}
                    homeSelection="1/4"
                />
            </div>
        );
     
    }

    return (
        <Expander header={header} widget={widget} headerClass="cp_subsection_entry_expander">

            <EntryComment 
                comments={entry.Entry_Comments_List} 
                entryID={entry.Entry_ID}
            />            

        </Expander>
    );
}


const HeaderText = (props) => {

    return (            
        <div className="cp_question_entry">
            <div>{props.name}</div>
            <div>{props.widget}</div>
        </div>
    );
}

export default Entries;