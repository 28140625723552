import React, {useContext, useState} from 'react';
import './wellbeing.css';
import IMG_0_DESELECT from '../../../../../../images/icons/wellbeing/0_deselect.png';
import IMG_0_SELECT from '../../../../../../images/icons/wellbeing/0_select.png';
import IMG_1_DESELECT from '../../../../../../images/icons/wellbeing/1_deselect.png';
import IMG_1_SELECT from '../../../../../../images/icons/wellbeing/1_select.png';
import IMG_2_DESELECT from '../../../../../../images/icons/wellbeing/2_deselect.png';
import IMG_2_SELECT from '../../../../../../images/icons/wellbeing/2_select.png';
import IMG_3_DESELECT from '../../../../../../images/icons/wellbeing/3_deselect.png';
import IMG_3_SELECT from '../../../../../../images/icons/wellbeing/3_select.png';
import IMG_4_DESELECT from '../../../../../../images/icons/wellbeing/4_deselect.png';
import IMG_4_SELECT from '../../../../../../images/icons/wellbeing/4_select.png';
import './wellbeing.css';



const WellBeing = (props) => {
    
    const [selectedIndex, setSelectedIndex] = useState(props.value);
    
    const updater = (val) =>{
        
        setSelectedIndex(val);

    }
    
    return(

        <div>
            <center>
            <img 
                className="wellbeing" 
                src={selectedIndex==0 ? IMG_0_SELECT : IMG_0_DESELECT} 
                onClick={c=>{
                    updater(0);       
                }}
            />
            <img 
                className="wellbeing" 
                src={selectedIndex==1 ? IMG_1_SELECT : IMG_1_DESELECT} 
                onClick={c=>{
                    updater(1);       
                }}
            />
            <img 
                className="wellbeing" 
                src={selectedIndex==2 ? IMG_2_SELECT : IMG_2_DESELECT} 
                onClick={c=>{
                    updater(2);       
                }}
            />
            <img 
                className="wellbeing" 
                src={selectedIndex==3 ? IMG_3_SELECT : IMG_3_DESELECT} 
                onClick={c=>{
                    updater(3);       
                }}
            />
            <img 
                className="wellbeing" 
                src={selectedIndex==4 ? IMG_4_SELECT : IMG_4_DESELECT} 
                onClick={c=>{
                    updater(4);       
                }}
            />
            </center>
            <Comments />
        </div>  
    );
}


const Comments=()=>{
    return(<div>Comments</div>)
} 

export default WellBeing;