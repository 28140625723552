import React, {useContext, useState} from 'react';
import './comments.css';

const GetComments = (props) => {

    let objComments = props.comments.map((c) => <Comment data={c} />);

    return (
        <div className="careplan_subsection_comments_container">
             
            <div className="careplan_subsection_comments_title">{props.title}</div>
            <hr className="care_comments" />
            <div>{objComments}</div>
        
        </div>
    );
}


const Comment = (props) => {
    
    const comment = props.data;

    return (
        <div>
            <input className="careplan_subsection_comment" value={comment.ss_comment} />            
        </div>
    );
}

export default GetComments;

/*
ss_comment: "Likes to read the paper in the morning"
ss_comment_datetime: "2020-11-02 12:36:09"
ss_comment_id: "63"
ss_subsection_id: "48"
*/