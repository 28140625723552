import React, {Component, useState, useContext } from "react";
import NewActionIncident from './addNew';
import './actionsincidents.css';
import NewActionForm from './addNewActionForm';


const ActionsIncidents = (props) => {

    const cpActions = props.actions;
    const cpIncidents = props.incidents;

    const [popup, setShowPopup] = useState({
        show: false,
      });

    const cancelHandler=()=>{
        setShowPopup({show:false});
    }

    let ACTIONS = [];
    
    if (Array.isArray(cpActions)) {
        ACTIONS = cpActions.map((a, i) =>{
            return <NewActionForm key={i} data={a} cancelHandler={cancelHandler} />
        });
    }
    

    return (
        <div>
            <span onClick={e=> {
                console.log("Popupval" + popup.show);
                setShowPopup({show:true});
                }}>
                    <NewActionIncident />
            </span>
            {popup.show ? <NewActionForm
                                 data={{
                                    action_id: -1,
                                    action_title : "",
                                    action_comments : "",
                                    action_urgent : "",
                                    action_complete: ""    
                                }}
                                /> : null}
            {ACTIONS}
        </div>
    );
}

export default ActionsIncidents;