import React from 'react';
import './FilterMenu.css';
import { useTable, useFilters, useSortBy, useColumnOrder } from 'react-table';
import ColumnFilter from './ColumnFilter';
import moment from 'moment';
import CareClicksField from './CareClicksField/careclicksfield';

export default function DTable(props) {

const convertSeconds = (nVal) => {
  let rtnVal = "";

  let av = parseInt(nVal);

        if (av.toString != "NaN") {
            try {
                let dt = new Date(null);
                dt.setSeconds(av);
                //rtnVal = "00" + dt.toString().substr(16, 8);
                //below put in as temp fix - needs proper calculation working out.
                rtnVal = "00" + dt.toString().substr(18, 6);
            } catch(err){}
        }

  return rtnVal;

}


const data = React.useMemo(
  () => props.data , []
);


const cls = Object.keys(props.data[0]);
const mp = [];
const excludeList = ["beaconId", "clearType", "panelRef", "unitId"];


const colMap = cls.map((col, index)=> 
{

  if (excludeList.indexOf(col) == -1) {
    
    if (
      (col == "Start Date") 
      || (col == "End Date")
      || (col == "Date")
      
      ) {
      
      mp.push({
        ID: col,
        Header: col,
        accessor: col,

        Cell: ({value}) => {return moment.utc(value).format('MMMM Do YYYY')},

          Filter: ColumnFilter,
        
      });
      
    }
    else if (
      (col == "Start Time") 
      || (col == "End Time")
      ) {
      
      mp.push({
        
        ID: col,
        Header: col,
        accessor: col,

        Cell: ({value}) => {return moment.utc(("1970/01/01 " + value)).format('HH:mm:ss')},
        Filter: ColumnFilter,

      });
      
    } 
    else {
      mp.push({
        ID: col,
        Header: col,
        accessor: col,
        Filter: ColumnFilter
      });
    }
  }
}
);



const columns = React.useMemo(
  () => 
  mp    
  ,
  []
)



const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  setColumnOrder,
} = useTable(
  { columns, data },
  useColumnOrder,
  useFilters,
  useSortBy,
  );


  const getColumnName = (colName)=> {
    
    if (colName == "callType") {return "Call Type";}
    if (colName == "clearType") {return "Clear Type";}
    if (colName == "duration") {return "Duration";}
    if (colName == "end") {return "End Time";}
    if (colName == "panelRef") {return "Panel Reference";}
    if (colName == "room") {return "Call Point";}
    if (colName == "start") {return "Start Date";}
    if (colName == "unitId") {return "UnitID";}
    if (colName == "zone") {return "Zone";}
    if (colName == "carer") {return "Carer ID";}
    
    return colName;
  }

  const getCareVals = (arrCare, rCount) => <CareClicksField care={arrCare} rowCount={rCount} house={props.house} />;

  const getCell = (cellVal, rCount) => {

    let arrVal = cellVal.props.cell.value;
    
    if (cellVal.props.column.Header == "Care Delivered") {
      return getCareVals(arrVal, rCount);
    }


    if (
        (cellVal.props.column.Header == "Duration of call")
        || (cellVal.props.column.Header == "Call Duration")
      ) {
      return convertSeconds(cellVal.props.cell.value);
    }

    return cellVal;
  } 


return (

  <>
  <table {...getTableProps()} className="rpt">
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >{getColumnName(column.render('Header'))}
            <span className="sortingspan">
              {column.isSorted ? (column.isSortedDesc ? '▲': '▼') : <span className="updown"><span>▲</span><span>▼</span></span>}
            </span>
          </th>
        ))}        
      </tr>
    ))}
      {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th
            {...column.getHeaderProps()}
          ><div>{column.canFilter ? column.render('Filter') : null}</div></th>
        ))}

</tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {rows.map((row, i) => {
      prepareRow(row)
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell,index) => {
            return (
              <td
                {...cell.getCellProps()}
              >
                {
                getCell(cell.render('Cell'), i)
                }
              </td>
            )
          })}
        </tr>
      )
    })}
  </tbody>
</table>
</>
);

}