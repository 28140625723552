import React, {useState} from 'react';
import './widgets.css';
import ContinenceReport from './reports/continence/continencereport';
import SummaryHeaderContainer from './reports/layout/summary_header';
import WidgetContainer from './reports/layout/summary_widget_container';
import CallReportContainer from './reports/layout/summary_report_container';

import IMG_ACTION from '../images/icons/careplan/action.png';
import IMG_CONTINENCE from '../images/icons/careplan/continence.png';
import IMG_WELL from '../images/icons/careplan/wellbeing.png';
//import IMG_ACTION from '../images/icons/careplan/action.png';


const containerStyle = {
    paddingTop: "50px",
    paddingLeft: "200px",
    paddingRight: "200px", 
};

export default class DemoViewer extends React.Component {

    componentDidMount () {
        
    }

    

    render() {

        return (
            <div>


            <div style={containerStyle}>
                <SummaryHeaderContainer
                    title="Careplan summary"
                    >
                    
                    <WidgetContainer
                        title="Continence"
                        img={IMG_CONTINENCE}
                        content="23" />
                    
                    <WidgetContainer
                        title="Falls"
                        img={IMG_WELL}
                        content="23" />
                    
                    <WidgetContainer
                        title="Well-being"
                        img={IMG_WELL}
                        content="50" />
                
                </SummaryHeaderContainer>

                <div className="reports_resident_charts_container">
                    
                    <CallReportContainer
                        color="#4cc1bd"
                        title="Continence"
                        textColor="white"
                        //img=""
                    >

                        <ContinenceReport

                        />

                    </CallReportContainer>
 
 
                    <CallReportContainer
                        color="#4cc1bd"
                        title="Personal"
                        textColor="white"
                        //img=""
                    >

                        <ContinenceReport

                        />

                    </CallReportContainer>
 
                    <CallReportContainer
                        color="#4cc1bd"
                        title="Moved"
                        textColor="white"
                        //img=""
                    >

                        <ContinenceReport

                        />

                    </CallReportContainer>
 
                    <CallReportContainer
                        color="#4cc1bd"
                        title="Social"
                        textColor="white"
                        //img=""
                    >

                        <ContinenceReport

                        />

                    </CallReportContainer>
 

                </div>
            </div>

            </div>
        );

    }

}