import React, {
    //useContext, Component, 
    useState, useRef
} from 'react';
import IMG_Print from '../../../images/icons/print_white.png';
import DropSelect from '../../common/DropSelect/DropSelect';
import DropSelectMulti from '../../common/DropSelectMulti/DropSelectMulti';
import Moment from 'moment';
import ReactToPrint, {ComponentToPrint, useReactToPrint } from "react-to-print";
import {ResidentHeaderManual} from '../Resident/header';
import WidgetContainer from '../../../widget_library/reports/layout/summary_widget_container';
import SummaryHeaderContainer from '../../../widget_library/reports/layout/summary_header';
import ContinenceDetailsSummary from '../../charts/care_dash_report_summary_continence';


export default class CareResidentDash extends React.Component {

    render() {

        return (
            <div style={{width:"90%", margin:"40px"}}>
          
                    <ResidentHeaderManual
                        firstName="Mr"
                        surname="Test"
                    />
                    <br />
                    <br />
                    <br />
                    <div className="reports_resident_charts_container">
                        
                        <SummaryHeaderContainer title="Continence">
                            <ContinenceDetailsSummary />
                        </SummaryHeaderContainer>
                          
                        <SummaryHeaderContainer title="Continence">
                            <ContinenceDetailsSummary />
                        </SummaryHeaderContainer>
                        

                    </div>
            </div>
        );
    }
}