import React, {Component, useState, useContext} from 'react';
import AuthProvider from '../firebase/provider/AuthProvider';
const UserManagement = () => {

    return(
        
    <AuthProvider>

        <div>Logged in</div>





    </AuthProvider>
   

    );

} 

export default UserManagement;
