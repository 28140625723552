import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import { Label } from "semantic-ui-react";

export default class Attendance_By_Resident extends Component {
  
  state = {
    data: []

  }  

  componentDidMount () {
    let sData = {
      "house": this.props.house,
      "startDate": this.props.startDate,
      "endDate": this.props.endDate,
      "zones": this.props.zones
    };

    axios.post("/resident/rpt/calls_attendance_by_resident.php", sData)
    .then(response => {
      let resCount = 0;
      try {
        resCount = response.data.residents.length;
      } catch(err) {}
      if (resCount>0) {
        this.setState({data: response.data.residents})
      }
      else {
        this.setState({data: [{
          backgroundColor: "#26dcdc",
          data: "",
          label: ""
          }]})
      }
      
    });
  }

  
  componentDidUpdate(prevProps) {

    if ((prevProps.startDate != this.props.startDate)||(prevProps.endDate != this.props.endDate)) {
      let sData = {
        "house": prevProps.house,
        "startDate": prevProps.startDate,
        "endDate": prevProps.endDate,
        "zones": prevProps.zones
      };

      axios.post("/resident/rpt/calls_attendance_by_resident.php", sData)
      .then(response => {
        let resCount = 0;
        try {
          resCount = response.data.residents.length;
        } catch(err) {}
        if (resCount>0) {
          this.setState({data: response.data.residents})
        }
        else {
          this.setState({data: [{
            backgroundColor: "#26dcdc",
            data: "",
            label: ""
            }]});
        }
        
      });

    }
  }


  render() {

    let ct = 0;

    try {
      ct = this.state.data.length;
    }
    catch(err){}


    if (ct>0) {
      return (
        <div>
          <Third_call data={this.state.data} /> 
        </div>
      );
    }
    else {
      
      return (
        <div>
          <div className="report_card">
  <p className="chartTitle">Attendance by Resident</p>
  </div>
        </div>
      );
    }

  }
}



function Third_call(props) {



const data = {
  labels: new Array(props.data.length).fill(""),
  datasets: props.data.length>0 ? props.data : null,
  backgroundColor:["#26dcdc", "#d2da63", "#ead536", "#f5bc31", "#ff6d7c"],
};


  return(

<div className="report_card">
  <p className="chartTitle">Attendance by Resident</p>

<Bar

  data={data}
  width={450}
  height={250}
  options={{
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Time Spent (mins)'
        },
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Residents'
        },
      }]

  }
  }}

/>

    </div>

    );
}

