import React, {useContext, Component, useState, useRef} from 'react';
import DropSelect from '../../components/common/DropSelect/DropSelect';

const HomeFilter = (props) => {

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: props.homeOptions ? props.homeOptions : JSON.parse(localStorage.getItem('sites')),
        homeSelection: props.homeSelection ? props.homeSelection : JSON.parse(localStorage.getItem('sites'))[0].label,
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }
    
    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default HomeFilter;