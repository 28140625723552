import React from 'react';
import firebase from 'firebase'
import Moment from 'moment';


const getConfig=(ref)=> {

    ref = ref + "config";
    
        firebase.database().ref(ref).once("value", snapshot => {
            
        let config = [];
        
        snapshot.forEach(snap => {
            
            const r = snap.val();
            config.push(r);
            
        });

        localStorage.setItem("panel_config", JSON.stringify(config));
        
      });
      
}

const stripRef = (ref) => {     
    return ref.substring(0, ref.indexOf("CallRecords"));    
}

function getConfigData(house) {
    
    let pnl = localStorage.getItem("panel_config");

    getConfig(stripRef(house));
    
}

export default getConfigData;


              