import React
//, {useState} 
from 'react';
import './careclicksfield.css';
import IMG_PCARE from '../../../../images/icons/personal_care.png';
import IMG_CONT from '../../../../images/icons/continence.png';
import IMG_NUT from '../../../../images/icons/nutrition_hydration.png';
import IMG_SLP from '../../../../images/icons/sleep_rest.png';
import IMG_SOC from '../../../../images/icons/social.png';
import IMG_MOV from '../../../../images/icons/moving.png';
import IMG_TOGGLE_NEG from '../../../../images/icons/toggle_negative.png';


const CareClicksField = (props) => {

    let arrCare = props.care ? props.care : [];

    let house = props.house ? props.house : "";

    let rowCount = props.rowCount ? props.rowCount : -1;

    if (house=="Arquella Demo") {

        arrCare = getTestArrCare(house, rowCount);

    }


    let comment = "";
    
    arrCare.map(c=>{
        if(typeof(c)=="object") {
            comment = c.comment;
        }
    });


    return (

            <div className="careicons">
            
                {(arrCare.includes("personal")) ? <BuildCareIcon tooltip="Personal Care" alt="Personal Care" src={IMG_PCARE} /> : null}
                {(arrCare.includes("continence")) ? <BuildCareIcon tooltip="Continence" alt="Continence" src={IMG_CONT} /> : null}
                {(arrCare.includes("nutandhyd")) ? <BuildCareIcon tooltip="Nutrition and Hydration" alt="Nutrition and Hydration" src={IMG_NUT} /> : null}
                {(arrCare.includes("sleep")) ? <BuildCareIcon tooltip="Sleep" alt="Sleep" src={IMG_SLP} /> : null}
                {(arrCare.includes("social")) ? <BuildCareIcon tooltip="Social" alt="Social" src={IMG_SOC} /> : null}
                {(arrCare.includes("moving")) ? <BuildCareIcon tooltip="Moving" alt="Moving" src={IMG_MOV} /> : null}
                {(comment!="") ? <CareComment 
                                                        tooltip="Care Comment" 
                                                        alt="Care Comment" 
                                                        src={IMG_TOGGLE_NEG} 
                                                        comment={comment} /> : null}
                  
            </div>
          
    );
} 


const BuildCareIcon = (props) => {
    
    
    return (
        <div class="caretooltip">
            <img className="care_selected" height="25px" alt={props.tooltip} src={props.src} />
            <span class="caretooltiptext">{props.tooltip}</span>
        </div>
    );
}

const CareComment = (props) => {
    let comment = props;

    return (
        <div class="caretooltip_comment">
            <div className="dashboard_careclick_comment_icon">i</div>
            {//<img className="dashboard_careclick_comment_icon" height="25px" alt={props.tooltip} src={props.src} />
}
            <div class="caretooltiptext_comment">
                <div className="caretooltiptext_comment_title">Comment</div>
                <div className="caretooltiptext_comment_text">{props.comment}</div>
            </div>
        </div>
    );
}


const getTestArrCare = (house, count) => {
    let careCalls = [];

    if (count == 1) {
        careCalls.push("continence");
        careCalls.push("moving");

        let comment = {
            comment: "Wanted someone to talk to."
        }

        careCalls.push(comment);

        }

    if (count == 2) {
        careCalls.push("personal");
        careCalls.push("social");
        careCalls.push("sleep");
    }
    
    if (count == 3) {
        careCalls.push("continence");
        careCalls.push("moving");
    }

    if (count == 4) {
        careCalls.push("continence");
        careCalls.push("moving");
        
        let comment = {
            comment: "Wanted to use the toilet."
        }

        careCalls.push(comment);
    }

    if (count == 5) {
        careCalls.push("nutandhyd");
        careCalls.push("sleep");
    }

    if (count == 6) {
        careCalls.push("moving");
        careCalls.push("sleep");
    }

    if (count == 7) {
        careCalls.push("personal");
        careCalls.push("continence");
        careCalls.push("moving");
    }

    if (count == 8) {
        careCalls.push("personal");
        careCalls.push("nutandhyd");
        careCalls.push("social");
        careCalls.push("sleep");
        
        let comment = {
            comment: "Wanted a cup of tea."
        }

        careCalls.push(comment);
    }

    if (count == 9) {
        careCalls.push("personal");
        careCalls.push("continence");
        careCalls.push("nutandhyd");
        careCalls.push("moving");
        careCalls.push("social");
        careCalls.push("sleep");
    }

    if (count == 10) {
        careCalls.push("personal");
        careCalls.push("continence");
        careCalls.push("nutandhyd");
        careCalls.push("moving");
        careCalls.push("sleep");
    }

    if (count == 11) {
        careCalls.push("personal");
        careCalls.push("continence");
        careCalls.push("nutandhyd");
        careCalls.push("moving");
        careCalls.push("sleep");
    }


    if (count == 12) {
        careCalls.push("nutandhyd");
        careCalls.push("sleep");
    }

    if (count == 13) {
        careCalls.push("nutandhyd");
    }

    if (count == 14) {
        careCalls.push("personal");
    }

    if (count == 15) {
        careCalls.push("continence");
        careCalls.push("sleep");
    }

    if (count == 16) {
        careCalls.push("continence");
    }

    if (count == 17) {
        careCalls.push("sleep");
    }

    if (count == 18) {
        careCalls.push("sleep");
    }

    if (count == 19) {
        careCalls.push("moving");
    }

    if (count == 20) {
        careCalls.push("personal");
        careCalls.push("nutandhyd");
        careCalls.push("social");
    }
    
    if (count == 21) {
        careCalls.push("social");
    }
    
    if (count == 22) {
        careCalls.push("sleep");
    }

    
    if (count == 23) {
        careCalls.push("continence");
        careCalls.push("moving");
        careCalls.push("social");
    }
    
    if (count == 24) {
        careCalls.push("continence");
        careCalls.push("nutandhyd");
    }
    return careCalls;
}

export default CareClicksField;
