import React from "react";
import PieChart from './charts/Pie';
import BarChart from './charts/BarChart';

/*
<Report 
    data={[]}
    type=""
    name=""
    title=""
    noDataCaption=""
    options={options}
    xTitle=""
    yTitle=""

/>
*/

export default class Report extends React.Component {

    state = {
        data: this.props.data ? this.props.data : [],
        type: this.props.type ? this.props.type : "",
        name: this.props.name ? this.props.name : "",
        title: this.props.title ? this.props.title : "",
        noDataCaption: this.props.noDataCaption ? this.props.noDataCaption : "",
        options: this.props.options ? this.props.options : "",
        xTitle: this.props.xTitle ? this.props.xTitle : [],
        yTitle: this.props.yTitle ? this.props.yTitle : ""
    }

    GetChartContainer = (props)=> {return (<div className="aq_chart_container">{props.children}</div>);}

    GetChart = ()=> {

        if (this.state.data.length > 0) {
            
            if (this.state.type=="pie") {
                return(<PieChart
                            data={this.state.data}
                            options={this.state.options} />);}
            
            if (this.state.type=="bar") {
                return(<BarChart
                                data={this.state.data}
                                options={this.state.options} />);}
            
            
            return null;
        }
        else {return(<p>{this.state.noDataCaption}</p>);}
        
    }

    render() {

        return (

            <div className="report_card">
                <span className="chartTitle">{this.state.title}</span>
                <this.GetChartContainer>
                    <this.GetChart />
                </this.GetChartContainer>
            </div>

        );

    }

}



export const ReportsContainerFour = (props) => {
    return(<div className="reports_container_four">{props.children}</div>);
}